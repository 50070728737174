import { useState } from "react";
import { ImagesPopUp } from "./styles";

import downArrow from "../../../../assets/IconComponents/multipleEditionIcons/downArrow.svg";
import upArrow from "../../../../assets/IconComponents/multipleEditionIcons/upArrow.svg";

export const ImagesPopup = (props) => {
  const [optionSelected, setOptionSelected] = useState(
    props.defaultValue
      ? props.packageOptions.filter((f) => f.value === props.defaultValue)[0]
      : props.packageOptions[0]
  );
  const [showOptions, setShowOptons] = useState(false);

  return (
    <ImagesPopUp isActive={showOptions} color={props.color}>
      <div
        className="option-selected"
        onClick={() => {
          setShowOptons(!showOptions);
        }}
      >
        <h2>{props.title}</h2>
        <p>
          {optionSelected.name}{" "}
          <img src={showOptions ? upArrow : downArrow} alt="" />
        </p>
      </div>
      {showOptions && (
        <ul>
          {props.packageOptions?.map((option, index) => (
            <li
              key={index}
              onClick={() => {
                setOptionSelected(option);
                props.setValuesSelected &&
                  props.setValuesSelected(option.value);
                setShowOptons(!showOptions);
              }}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </ImagesPopUp>
  );
};
