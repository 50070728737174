import { useState, useEffect } from "react";
import { RetailerCell } from "./styles";
import PopUpEdit from "../../productDetail/popUpEdit";
export const RetailersCell = (props) => {
  const [showRetailers, setShowRetailers] = useState(false);

  const closePopUp = (e) => {
    if (!e.target.closest(`#cell-${props.index}`) && showRetailers) {
      document.removeEventListener("click", closePopUp, false);
      setShowRetailers(false);
    }
  };

  useEffect(() => {
    if (showRetailers) {
      document.addEventListener("click", closePopUp, false);
    }
  }, [showRetailers]);

  return (
    <RetailerCell
      key={"cell" + props.index}
      id={`cell-${props.index}`}
      textAlign="center"
      onClick={() => setShowRetailers(true)}
    >
      <div className="cell-container">
        <p>{props.value}</p>
        {showRetailers && (
          <PopUpEdit className="chk-inputs" retailers={props.retailers[0]} />
        )}
      </div>
    </RetailerCell>
  );
};
