import { ContainerT } from "./styles";
import { CheckboxCustom } from "../../../../../../components/customInputs/checkboxCustom";
import { icons } from "../../../utils";

export const TableRow = ({
  item,
  retailerO,
  productsToBilling,
  setProductsToBilling,
  id,
}) => {
  const { articleId, orderId, name, status, upc, services, retailer } = item;

  return (
    <ContainerT>
      <div>{retailerO?.name ?? " - "}</div>
      <div>{upc ?? " - "}</div>
      <div>{name ?? " - "}</div>
      <div className="services-list">
        {Object.keys(services).map((service) =>
          icons(service, services[service])
        )}
      </div>
      <div>{orderId ?? " - "}</div>
      <div>{status ?? " - "}</div>
      <div className="chk-container-g">
        <CheckboxCustom
          labelText=""
          name={`chk-fact-r-${articleId}-${orderId}-${retailer}`}
          id={`chk-fact-r-${articleId}-${orderId}-${retailer}`}
          htmlFor={`chk-fact-r-${articleId}-${orderId}-${retailer}`}
          onChange={(e) => {
            let temp = productsToBilling.slice();
            if (e.target.checked) {
              const productToBilling = {
                articleId,
                orderId,
                retailerId: retailer,
              };
              temp.push(productToBilling);
            } else {
              temp = temp.filter((item) => {
                return (
                  item.articleId +
                    "-" +
                    item.orderId +
                    "-" +
                    item.retailerId !==
                  articleId + "-" + orderId + "-" + retailer
                );
              });
            }
            setProductsToBilling(temp);
          }}
        />
      </div>
    </ContainerT>
  );
};
