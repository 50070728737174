export const getAssignationComponentIndexes = (target, concept) => {
  let assignationComponentIndexes = {};
  const auditorAssignation = target === "auditor";
  const especialistAssignation = target === "especialist";
  const facilitatorAssignation = target === "facilitator";
  const dataSheetAssignation = concept === "datasheet";
  const descriptionAssignations = concept === "description";
  const imagesAssignations = concept === "images";

  if (auditorAssignation) {
    assignationComponentIndexes.columnIndex = 12;
    assignationComponentIndexes.userGroupIndex = 4;
  }
  if (especialistAssignation && dataSheetAssignation) {
    assignationComponentIndexes.columnIndex = 1;
    assignationComponentIndexes.userGroupIndex = 0;
  }
  if (especialistAssignation && descriptionAssignations) {
    assignationComponentIndexes.columnIndex = 5;
    assignationComponentIndexes.userGroupIndex = 0;
  }
  if (especialistAssignation && imagesAssignations) {
    assignationComponentIndexes.columnIndex = 9;
    assignationComponentIndexes.userGroupIndex = 2;
  }

  if (facilitatorAssignation && dataSheetAssignation) {
    assignationComponentIndexes.columnIndex = 3;
    assignationComponentIndexes.userGroupIndex = 1;
  }
  if (facilitatorAssignation && descriptionAssignations) {
    assignationComponentIndexes.columnIndex = 7;
    assignationComponentIndexes.userGroupIndex = 1;
  }
  if (facilitatorAssignation && imagesAssignations) {
    assignationComponentIndexes.columnIndex = 11;
    assignationComponentIndexes.userGroupIndex = 3;
  }
  return assignationComponentIndexes;
};

export const getProductIndexInList = (product, list) => {
  let productIndex;
  const LookupProduct = list.find(
    (item) =>
      item.orderId === product.orderId &&
      item.article.id_article === product.article.id_article
  );
  productIndex = list.indexOf(LookupProduct);
  return productIndex;
};

export const runAssignations = (
  item,
  listToModify,
  serviceStatus,
  assignationTarget,
  assigneeID,
  assignationForAuditor
) => {
  const itemIndex = getProductIndexInList(item, listToModify);

  if (itemIndex !== -1) {
    const productIsNotAssigned = listToModify[itemIndex].status === "PA";

    const serviceFirstAssignation =
      listToModify[itemIndex][serviceStatus] === "PA";

    if (assignationForAuditor) {
      listToModify[itemIndex].article[assignationTarget] = assigneeID;
    } else {
      if (serviceFirstAssignation) {
        listToModify[itemIndex][serviceStatus] = "AS";
      }
      listToModify[itemIndex].article[assignationTarget] = assigneeID;
    }
    const services = ["datasheet", "description", "images"];
    const allIsAssigned = services.every((service) =>
      ["AS", "NS"].includes(listToModify[itemIndex][`${service}_status`])
    );
    if (productIsNotAssigned && allIsAssigned)
      listToModify[itemIndex].status = "AS";
  }

  return { updatedList: listToModify, itemIndex };
};
