import axios from "axios";

const objToQueryString = (obj) => {
  if (!obj && !Object.values(obj).length) return null;
  return Object.entries(obj)
    .map(
      ([key, value]) => `${key}=${encodeURIComponent(JSON.stringify(value))}`
    )
    .join("&");
};

export const fetchUsers = async () => {
  const usersPromises = [];
  let usersGroups = [];
  const jwt = sessionStorage.getItem("jwt");
  const header = {
    headers: {
      Authorization: jwt,
    },
  };
  /**
   * 7 will fetch all text specialists
   * 8 will fetch all img specialists
   * 4 will fetch all text facilitators
   * 5 will fetch all img facilitators
   * 6 will fetch all auditors
   */
  usersPromises.push(
    axios.get(`${process.env.REACT_APP_USER_ENDPOINT}?fetchRole=7`, header),
    axios.get(`${process.env.REACT_APP_USER_ENDPOINT}?fetchRole=8`, header),
    axios.get(`${process.env.REACT_APP_USER_ENDPOINT}?fetchRole=4`, header),
    axios.get(`${process.env.REACT_APP_USER_ENDPOINT}?fetchRole=5`, header),
    axios.get(`${process.env.REACT_APP_USER_ENDPOINT}?fetchRole=6`, header)
  );
  const usersResponse = await Promise.all(usersPromises);
  usersResponse.forEach((item, i) => {
    usersGroups.push(item.data.data);
  });
  return usersGroups;
};

export const getOrders = async () => {
  try {
    const ordersResponse = await axios.get(
      `${process.env.REACT_APP_NEW_ORDERS_ENDPOINT}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    const ordersArray = JSON.parse(ordersResponse.data.body).data ?? [];
    return ordersArray;
  } catch (err) {
    console.log(
      "Unable to retrieve orders from database, please report this to TI"
    );
    return [];
  }
};

export const getAllRetailers = async () => {
  try {
    const retailersResponse = await axios.get(
      `${process.env.REACT_APP_RETAILER_ENDPOINT}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    const retailers =
      Object.values(JSON.parse(retailersResponse.data.body).data) ?? [];
    return retailers.map((ret) => ({
      name: ret.name,
      value: ret.id,
      active: false,
    }));
  } catch (err) {
    console.log(err, "Unable to retrieve retailers from DB");
    return [];
  }
};

export const getComments = async (params = {}) => {
  const func = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_COMMENTS_ENDPOINT}?articleId=${params.articleId}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    return JSON.parse(response.data.body);
  };

  return await func();
};

export const postComments = async (params = {}) => {
  const func = async () => {
    if (params.message === "") {
      console.error("Mensaje vacío");
      return false;
    }
    await axios
      .post(
        `${process.env.REACT_APP_COMMENTS_ENDPOINT}?message=${params.message}&articleId=${params.articleId}&orderId=${params.orderId}`,
        {
          message: params.message,
          articleId: params.articleId,
          orderId: params.orderId,
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      )
      .then((response) => {
        return true;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  return await func();
};

export const getPercentage = async (params = {}) => {
  const func = async () => {
    /* params.data = {
     * id_article: product.id_article,
     * id_category: product.id_category,
     * version: product.version,
     * id_retailer_array: product.retailersAvailable,
     * }*/

    return await axios
      .post(
        process.env.REACT_APP_VALID_EXPORT_ENDPOINT,
        { data: params.data },
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => console.log(err, "Errror"));
  };

  return await func();
};

export const getTasks = async () => {
  try {
    const userTasks = await axios.get(
      `${process.env.REACT_APP_USER_TASKS_ENDPOINT}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    return Object.values(JSON.parse(userTasks.data.body).data);
  } catch (err) {
    console.log(
      err,
      "Unable to retrieve user tasks from database, please report this to TI"
    );
    return [];
  }
};

export const getOrderedServices = async (params = {}) => {
  const func = async () => {
    const { productsList, indexFront, filter, value } = params.query;
    let query = "";
    if (productsList) query = `?productsList=${productsList}`;
    if (indexFront > -1) {
      query.length > 0
        ? (query += `&indexFront=${indexFront}`)
        : (query += `?indexFront=${indexFront}`);
    }
    if (filter && value) {
      let values = "";
      if (filter === "upc") values = `'${value?.join("','")}'`;
      else values = `${value?.join(",")}`;
      query.length > 0
        ? (query += `&filter=${filter}&values=${encodeURIComponent(values)}`)
        : (query += `?filter=${filter}&values=${encodeURIComponent(values)}`);
    }

    return await axios
      .get(`${process.env.REACT_APP_ORDERED_SERVICES_ENDPOINT}${query}`, {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err, "Errror"));
  };

  return await func();
};

export const getCategoryRetailer = async (params = {}) => {
  const func = async () => {
    const { category } = params.query;
    let query = `?category=${encodeURIComponent(category)}`;
    return axios.get(`${process.env.REACT_APP_RETAILER_ENDPOINT}${query}`, {
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });
  };

  return await func();
};

export const getGeneralMetrics = async (params = {}) => {
  const func = async () => {
    const query = objToQueryString(params.query);
    const res = await axios.get(
      `${process.env.REACT_APP_READ_METRICS}?${query}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    return JSON.parse(res.data.body).metrics;
  };

  return await func();
};

export const getUserMetrics = async (params = {}) => {
  const func = async () => {
    const query = objToQueryString(params.query);
    const res = await axios.get(
      `${process.env.REACT_APP_READ_METRICS_USER}?${query}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    return JSON.parse(res.data.body).logs;
  };

  return await func();
};

export const getOrderDetail = async (params = {}) => {
  const func = async () => {
    const query = objToQueryString(params.query);
    const res = await axios.get(
      `${process.env.REACT_APP_ORDER_DETAIL}?${query}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    return JSON.parse(res.data.body);
  };

  return await func();
};

export const updateBilling = async (params = {}) => {
  const func = async () => {
    const res = await axios.put(
      process.env.REACT_APP_UPDATE_BILLING,
      params.body,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    return res.data.statusCode;
  };

  return await func();
};

export const getAcquiredProducts = async (params = {}) => {
  const func = async () => {
    const queryStringParameters = objToQueryString(params.query);
    return await axios.get(
      `${process.env.REACT_APP_DROPSHIP_ACQUIRED_ENDPOINT}?${queryStringParameters}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
  };

  return await func();
};

export const getSabanaDir = async (params = {}) => {
  const func = async () => {
    const query = objToQueryString(params.query);
    const res = await axios.get(
      `${process.env.REACT_APP_SABANA_DIR}?${query}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    return JSON.parse(res.data.body);
  };

  return await func();
};
