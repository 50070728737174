import { Container } from "./styles";
import { CheckboxCustom } from "../../../../../../components/customInputs/checkboxCustom";
import { createProductsToBillingArray } from "../../../utils";

export const TableHeader = ({
  filters,
  articlesList,
  setProductsToBilling,
}) => {
  return (
    <Container>
      <div>Cadena</div>
      <div>UPC</div>
      <div>Nombre</div>
      <div>Servicios</div>
      <div>OT</div>
      <div>Estatus</div>
      <div className="chk-container-g">
        <CheckboxCustom
          labelText="Fact."
          name="chk-fact-g"
          id="chk-fact-g"
          htmlFor="chk-fact-g"
          onChange={(e) => {
            e.target.checked
              ? createProductsToBillingArray(
                  articlesList,
                  setProductsToBilling,
                  filters
                )
              : createProductsToBillingArray([], setProductsToBilling);
            const chks = document.querySelectorAll('[id^="chk-fact-r"]');
            chks.forEach((chk) => (chk.checked = e.target.checked));
          }}
        />
      </div>
    </Container>
  );
};
