import React from "react";
import { useParams, Link } from "react-router-dom";
import { VideoDetailContainer } from "./styles";
import arrow from "../../assets/IconComponents/arrow_back_purple.svg"

const VideoDetail = ({ tutorials }) => {
  const { title } = useParams();
  const tutorial = tutorials.find((tutorial) => tutorial.title === title);
  if (!tutorial || !tutorial.title) {
    return <div>No se encontró el tutorial</div>;
  }
  const { image, platform, description, shortUrl, pathVideo, largeTitle } =
    tutorial;

  return (
    <VideoDetailContainer>
      <div className="title-division">
        <div>
          <h1 className="title-video">{tutorial.largeTitle}</h1>
          <p className="details-video">{tutorial.platform}</p>
        </div>
        <div className="container-button">
          <Link to="/tutorials">
            <button className="button-return"><img src={arrow} alt="Arrow" className="arrow-icon" />Regresar a los tutoriales</button>
          </Link>
        </div>
      </div>
      <div className="title-container">
        <h1>Descripción de la funcionalidad</h1>
        <p className="description-video">{tutorial.description}</p>
      </div>
      <div className="description-container">
        <iframe
          width="560"
          height="315"
          src={tutorial.pathVideo}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
    </VideoDetailContainer>
  );
};

export default VideoDetail;
