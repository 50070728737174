import React, { useState, useEffect } from "react";
import {
  RequestModal,
  ModalContainer,
  CloseButton,
  ModalImage,
  Title,
  CheckboxContainer,
  ButtonContainer,
  FormActiveContainer,
} from "./styles";

import { CheckboxCustom } from "../../components/customInputs/checkboxCustom/index";

import { Dropdown } from "./versionDropdown/index";

import checked from "../../assets/IconComponents/modalCheckboxActive.svg";
import unchecked from "../../assets/IconComponents/modalCheckboxDisable.svg";
import loop from "../../assets/IconComponents/loop.gif";

export default function ProductosElegidosModal(props) {
  const [listRetailers, setListRetailers] = useState([]);
  const products = JSON.parse(sessionStorage.getItem("productsToExport"));
  const [retailersSelected, setRetailersSelected] = useState(products);
  const [actives, setActives] = useState([true, true, true]);

  useEffect(() => {
    setListRetailers(props.retailersGeneral);
  }, [props.retailersGeneral]);

  useEffect(() => {
    const temp = [];
    products.forEach((article) => {
      article.retailers.forEach((retailer) => temp.push(retailer.id));
    });
    setRetailersSelected(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRetailersId = () => {
    let arrayResult = [];
    products.map((retailer) =>
      retailer.retailers.map(
        (available) => (arrayResult[available.name] = available.id)
      )
    );
    return arrayResult;
  };

  const changeActive = (index) => {
    const temp = actives.slice();
    temp[index] = !temp[index];
    props.setActivesSelected(temp);
    setActives(temp);
  };

  const checkAll = (e) => {
    let tempArray = [];
    listRetailers.forEach((retailer) => {
      tempArray.push(retailer.id.toString());
    });
    props.setRetailersSelected(e ? tempArray : []);
    setRetailersSelected(e ? tempArray : []);
    setActives([e, e, e]);
    const chks = document.querySelectorAll(".chk-retailer");
    const chksServices = document.querySelectorAll(".check-item input");
    chksServices.forEach((chk) => (chk.checked = e));
    chks.forEach((chk) => (chk.checked = e));
  };

  const changeRetailer = (id) => {
    let temp = retailersSelected.slice();
    if (temp.find((e) => e === id)) {
      temp = temp.filter((e) => e !== id);
    } else {
      temp.push(id);
    }
    props.setRetailersSelected(temp);
    setRetailersSelected(temp);
  };

  return (
    <RequestModal className="modal-number">
      <div className="transparent-background">
        <ModalContainer>
          <CloseButton>
            <button
              className="close"
              onClick={() => props.close(false)}
            ></button>
          </CloseButton>

          <ModalImage>
            <div className="image-container">
              {props.totalProducts < 69 ? (
                <p className="min-products">{props.totalProducts}</p>
              ) : (
                <p className="max-products">{props.totalProducts}</p>
              )}
            </div>
          </ModalImage>

          <Title>
            <h2>Productos Elegidos</h2>

            <p>Selecciona entregables</p>
          </Title>

          <FormActiveContainer>
            <form>
              <p>Selecciona los activos</p>

              <CheckboxContainer
                className="active-options"
                checked={checked}
                unchecked={unchecked}
              >
                <div className="check-item">
                  <input
                    type="checkbox"
                    name="datasheet"
                    id="datasheet"
                    onChange={() => changeActive(0)}
                    defaultChecked={actives[0]}
                  />
                  <label htmlFor="datasheet">Fichas Técnicas</label>
                </div>

                <div className="check-item">
                  <input
                    type="checkbox"
                    name="description"
                    id="description"
                    onChange={() => changeActive(1)}
                    defaultChecked={actives[1]}
                  />

                  <label htmlFor="description">Descripciones</label>
                </div>

                <div className="check-item">
                  <input
                    type="checkbox"
                    name="image"
                    id="image"
                    onChange={() => changeActive(2)}
                    defaultChecked={actives[2]}
                  />

                  <label htmlFor="image">Imágenes</label>
                </div>
              </CheckboxContainer>

              <div className="header-text-container">
                <p className="brands">Selecciona las Cadenas</p>

                <CheckboxCustom
                  labelText="Seleccionar todo"
                  id="chk-check-all"
                  name="chk-check-all"
                  htmlFor="chk-check-all"
                  checked={checked}
                  unchecked={unchecked}
                  size={17}
                  onChange={(e) => checkAll(e.target.checked)}
                />
              </div>

              <CheckboxContainer
                className="linked-companies"
                checked={checked}
                unchecked={unchecked}
              >
                {listRetailers.map((e) => (
                  <div className="check-item" key={`retailer${e.id}`}>
                    <input
                      type="checkbox"
                      name={`retailer${e.id}`}
                      id={`retailer${e.id}`}
                      onClick={() => changeRetailer(e.id)}
                      defaultChecked={getRetailersId()[e.name] ? true : false}
                    />
                    <label htmlFor={`retailer${e.id}`}>{e.name}</label>
                  </div>
                ))}
              </CheckboxContainer>

              {props.totalProducts === 1 && <Dropdown products={products} />}

              <ButtonContainer>
                <button
                  className="back-button"
                  onClick={() => props.close(false)}
                  type="button"
                >
                  Regresar
                </button>
                <button
                  onClick={() => {
                    props.setStep(2);
                    let intersection = listRetailers.filter((e) => {
                      return (
                        retailersSelected.findIndex((arr2) => e.id === arr2) >
                        -1
                      );
                    });
                    setRetailersSelected(intersection);
                  }}
                  className="add-button"
                  type="button"
                >
                  Exportar
                </button>
              </ButtonContainer>
            </form>
          </FormActiveContainer>
        </ModalContainer>
      </div>
    </RequestModal>
  );
}
