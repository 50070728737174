import styled from 'styled-components'

export const Container = styled.div`
  width: 95%;
  margin: auto;

  &.addres-container {
    margin: initial;
    width: initial;
    flex: 0%;

    .description {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #817393;
    }
  }

  &.header-container {
    & + * {
      margin-top: 35px;
    }
  }
`

export const Containers = styled.div`
  display: flex;
`;

export const ContainerMain = styled.div`
  overflow-y: scroll;
  padding: 10px;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
`
export const Text18 = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-size: 18px;
  line-height: 21px;
  color: #503D66;
`

export const ContainerMap = styled.div`
  width: ${props => props.width ? props.width : 'calc(100% - 300px)'};
  margin-bottom: 10px;
`
export const ContainerDatos = styled.div`
  width: calc(100% - 461px);
  padding: 15px;
`

export const ContainerDetalles = styled.div`
    width: 100%;
    padding: 10px;
    padding-right: 0;
`

export const RowSpaceBetwen = styled.div`
  display: flex;
  justify-content: space-between;
  label{
    font-family: Avenir Text;
    color: #817393;
    font-size: 12px;
  }
`

export const Hr = styled.hr`
    margin-top: 80px;
    width: 100%;
    height: 1px;
    background-color: #ccc;
    border: none;
`

export const Block1 = styled.div`
    background: #F0EEF2;
    border-radius: 7px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
`
export const Text14 = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #817393;

  span {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
  }

  &.date-container {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
  }

  & + * {
    margin-top: 5px;
  }
`

export const InputCupon = styled.input`
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    border: 1px solid #F0EEF2;
    box-sizing: border-box;
    border-radius: 7px;
    width: 100%;
    height: 48px;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    &::placeholder{
        color: #817393;
    }
`

export const Title = styled.p`
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: #D43594;

  &.releases {
    & + * {
      margin-top: 10px;
    }
  }

`
export const MainData = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  
  & + *{
    margin-top: 33px;
  }
`
export const ContainerInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const ContainerSelect = styled.div`
  display: flex;
  align-items:center;
  width: 100%;
  padding-bottom:0.5rem;
`

export const CustomSelect = styled.select`
  border: 1px solid #E33AA9;
  border-radius: 15px;
  color: #E33AA9;
  margin-left: 7px;
  height: 20px;
  width: 105px;

  &:focus{
    background: #817393;
    border-radius: 15px;
    color: white;
    border: none;
  };
  & > option {
    background: #D4D1D7;
    border-radius: 10px;
    color: black;
  };
  & > option:hover {
    background: #817393;
    border-radius: 10px;
    color: white;
  };
`

export const ODTText = styled.p`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.015em;
  color: #817393;

  span {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #503D66;
    margin-right: 12px;
  }
`

export const ContainerCalendar = styled.div`
  display: flex;

  .calendarContainer {
    width: 180px;
    box-sizing: border-box;
    padding: 0;
    margin-right: 0;

    .heading {
      .title {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 10.2271px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 0.0730505px;
        color: #817393;
      }

      .prev,
      .next {
        width: 10px;
        height: 26px;
        padding: 0;
        
        svg {
          line-height: 26px;
        }
      }
    }

    .daysOfWeek {
      div {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 8.76606px;
        line-height: 11px;
      }
    }
  }
`

export const ContainerDate = styled.div`
  width: 133px;

  & + * {
    margin-left: 20px;
  }
`

export const InputDate = styled.input`
  background: #F0EEF2;
  border-radius: 15px;
  color: #817393;
  font-size: 12px;
  font-family: Avenir Next;
  height: 20px;
  width: 100%;
  border: none;
  text-align: center;
`
export const DateButton = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F0EEF2;
  border-radius: 15px;
  color: #817393;
  font-size: 12px;
  font-family: Avenir Next;
  height: 20px;
  width: 100%;
  border: none;
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
`

export const Text10 = styled.p`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #817393;
`

export const Text12 = styled.p`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  color: #817393;
  margin-bottom: 10px;

  &.txt-address{
    width: 180px;
  }

  &.office-contact {
    color: #503D66;
  }
`

export const Text11 = styled.p`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #817393;

  & + hr {
    margin-top: 34px;
  }
`

export const ButtonRecibir = styled.button`
    border: 1px solid #E33AA9;
    box-sizing: border-box;
    border-radius: 30px;
    color: #E33AA9;
    font-family: Lato;
    font-size: 12px;
    width: 173.49px;
    height: 31.07px;
    background: white;
`

export const InputCalle = styled.input`
  background: #FAFAFA;
  border-radius: 3px;
  width: 310px;
  height: 19px;
  border: none;
  font-size: 11px;
  &::placeholder{
    color: #D4D1D7;
  }
  margin: 5px;
  padding-left: 7px;
`
export const InputEstado = styled.input`
  background: #FAFAFA;
  border-radius: 3px;
  width: 110px;
  height: 19px;
  border: none;
  font-size: 11px;
  &::placeholder{
    color: #D4D1D7;
  }
  margin: 5px;
  padding-left: 7px;
`
export const InputCP = styled.input`
  background: #FAFAFA;
  border-radius: 3px;
  width: 110px;
  height: 19px;
  border: none;
  font-size: 11px;
  &::placeholder{
    color: #D4D1D7;
  }
  margin: 5px;
  padding-left: 7px;
`
export const InputNombre = styled.input`
  background: #FAFAFA;
  border-radius: 3px;
  width: 182px;
  height: 19px;
  border: none;
  font-size: 11px;
  &::placeholder{
    color: #D4D1D7;
  }
  margin: 5px;
  padding-left: 7px;
`
export const InputTelefono = styled.input`
  background: #FAFAFA;
  border-radius: 3px;
  width: 157px;
  height: 19px;
  border: none;
  font-size: 11px;
  &::placeholder{
    color: #D4D1D7;
  }
  margin: 5px;
  padding-left: 7px;
`
export const Row = styled.div`
    padding: 20px 10px;
    display: flex;
    width: 95%;
    margin: auto;
    align-items: center;

    .pNumber{
      margin-top: 10px;
      color: #969696;
      font-family: Avenir Next;
      font-size: 11px;
    }

    .leaflet-container {
      & + * {
        margin-left: 25px;
      }
    }

  &.row {
    & + .row {
      border-top: 1px solid #D4D1D7;
    }
  }
`

export const ActiveCircle = styled.div`
  width: 58px;
  height: 58px;
  background: #E33AA9;
  font-family: Avenir Next;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  color: white;
  margin-right: 5px;
  cursor: pointer;

  & + * {
    margin-left: 10px;
  }
`
export const Separator = styled.div`
  width: 36px;
  height: 2px;
  background-color: #F0EEF2;
  align-items: center;
  justify-content: center;
  font-size: 36px;

  & + * {
    margin-left: 10px;
  }
`

export const InactiveCircle = styled.div`
  width: 58px;
  height: 58px;
  border: 1px solid #F0EEF2;
  box-sizing: border-box;
  font-family: Avenir Next;
  border-radius: 50%;
  display: flex;
  color: #817393;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;

  & + * {
    margin-left: 10px;
  }
`

export const DateText = styled.p`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #503D66;
`
export const TextConfirm = styled.p`
  font-family: Avenir Next;
  color: #603888;
  font-size: 14px;
  text-align: right;
  width: 50px;
  & + * {
    margin-left: 10px;
  }
`

export const TableProduct = styled.table`
  margin-top: 30px;
  display: block;
  empty-cells: show;
`
export const TotalTable = styled.thead`
  margin-top: 10px;
  position:relative;
  display: block;
  width:100%;
  margin-bottom: 30px;
  border: 1px solid #F0EEF2;
  box-sizing: border-box;
  border-radius: 7px;
  height: 41px;
`

export const ButtonCancelar = styled.thead`
  border: 1px solid #817393;
  box-sizing: border-box;
  border-radius: 50px;
  width: 130px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Avenir Next;
  font-size: 18px;
  margin-right: 35px;
  cursor: pointer;
`

export const Line = styled.p`
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #F0EEF2;
  width: 360px;
`

export const Button = styled.button`
  width: 100px;
  height: 21px;
  border: 1px solid #817393;
  box-sizing: border-box;
  border-radius: 3px;
  color: #817393;
  background: transparent;

  & + * {
    margin-top: 13px; 
  }
`

export const TextTotal = styled.th`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #817393;
  display: block;
  text-align: center;
`

export const TextTotalBold = styled.th`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #503D66;
  display: block;
  text-align: center;
`

export const ContainerFooter = styled.div`
display:flex;
justify-content: space-between;
width:100%;
box-sizing: border-box;
padding:24px 10px 10px 20px;
border-bottom: 1px solid #C4C4C4;
`

export const HeadTableProd = styled.thead`
  position:relative;
  display: block;
  width:100%;
`

export const HeadRowTableProdEnd = styled.th`
  font-family: Avenir Next;
  text-align: center;
  font-size: 12px;
  color: #503D66;
  border-right: solid;
  border-right-width: 1px;
  border-right-color: #D4D1D7;
  display: block;
`

export const HeadRowTableProd = styled.th`
  font-family: Avenir Next;
  font-size: 12px;
  color: #503D66;
  text-align: center;
  flex-basis:100%;
  display: block;
`

export const HeadRowTableProdTitle = styled.th`
  font-family: Avenir Next;
  font-size: 15px;
  color: #503D66;
  text-align: left;
  flex-basis:100%;
  display: block;
`
export const HeadRowTableProdIconEnd = styled.th`
  font-family: Avenir Next;
  font-size: 15px;
  color: #503D66;
  text-align: right;
  flex-basis:100%;
  display: block;
  border-right: solid;
  border-right-width: 1px;
  border-right-color: #D4D1D7;
`

export const HeadRowTableProdSubitle = styled.th`
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: Avenir Next;
  font-size: 12px;
  color: #817393;
  text-align: left;
  flex-basis:100%;
  display: block;
`

export const HeadRowTableProdSubitleEnd = styled.th`
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: Avenir Next;
  font-size: 12px;
  color: #817393;
  text-align: left;
  flex-basis:100%;
  display: block;
  border-right: solid;
  border-right-width: 1px;
  border-right-color: #D4D1D7;
`

export const TablesProductRowWhite = styled.tr`
  border:none;
  background-color: #FFFFFF;
  height: 36px;
  display: flex;
  cursor: pointer;
  &:hover:nth-child(odd){
    background-color: #D4D1D7;
  };
  &:hover:nth-child(even){
    background-color: #D4D1D7;
  };
  &:nth-child(odd){
    background-color: #FAFAFA;
  };
  &:nth-child(even){
    background-color: #FFFFFF;
  };
`

export const CadenasBody = styled.tr`
  background-color: #F0EEF2;
  height: auto;
  display: flex;
`

export const CadenasHead = styled.tr`
  border-top: solid;
  border-top-width: 1px;
  border-top-color: #E33AA9;
  background-color: #F0EEF2;
  border-bottom: solid;
  border-bottom-width: 1px;
  border-bottom-color: #D4D1D7;
  height: auto;
  display: flex;
  .title {
    text-align: left;
    padding-left: 5px;
    padding-bottom: 15px;
  }
`

export const CadenasFotter = styled.tr`
  border-top: solid;
  border-top-width: 1px;
  border-top-color: #D4D1D7;
  border-bottom: solid;
  border-bottom-width: 1px;
  border-bottom-color: #E33AA9;
  background-color: #F0EEF2;
  height: auto;
  display: flex;
  .title {
    text-align: left;
    padding-left: 5px;
    padding-bottom: 15px;
  }
`
export const CadenaProductD = styled.td`
  font-family: Avenir Next;
  text-align: center;
  font-size: 12px;
  flex-basis:100%;
  display: block;
  .title {
    margin-top: 5px;
    text-align: left;
    padding-left: 5px;
  }
`

export const CadenaProductDEnd = styled.td`
  font-family: Avenir Next;
  text-align: center;
  font-size: 12px;
  border-right: solid;
  border-right-width: 1px;
  border-right-color: #D4D1D7;
  flex-basis:100%;
  display: block;
`

export const TablesProductD = styled.td`
  padding-top: 13px;
  font-family: Avenir Next;
  text-align: center;
  font-size: 12px;
  flex-basis:100%;
  display: block;
`
export const TablesProductDIcons = styled.td`
  font-family: Avenir Next;
  font-size: 12px;
  flex-basis:100%;
  display: block;
  text-align: right;
`

export const TablesProductDEnd = styled.td`
  font-family: Avenir Next;
  padding-top: 13px;
  text-align: center;
  font-size: 12px;
  border-right: solid;
  border-right-width: 1px;
  border-right-color: #D4D1D7;
  flex-basis:100%;
  display: block;
`

export const InputForm = styled.input`
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 2px;
  height: 21px;
  width: 100%;
  &::placeholder {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 21px;
    /* identical to box height, or 210% */

    letter-spacing: -0.015em;

    color: #c4c4c4;
  }
`

export const SelectForm= styled.select`
background: #FAFAFA;
border-radius: 2px;

border: none;
width:150px;
height:21px;
 & option {
    color: #817393;
    background-color: #F0EEF2;;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`

export const ContainerClose = styled.div`
  margin-left: 40px;
  height: 100%;
`
export const Header1 = styled.tr`
  display: flex;
  width: calc(100% - 20px);
`
export const Header2 = styled.tr`
  display: flex;
  width: calc(100% - 17px);
`
export const TotalRow = styled.tr`
  display: flex;
  width: calc(100% - 17px);
  margin-top: 10px;
`

export const BodyTable = styled.tbody`
  width: 100%;
  height: 400px;
  overflow-y: scroll;
  display: block;
  clear: both;
`

export const Flex = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  .total {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    color: #503D66;
    margin-right: 87px;
    padding: 10px;
    box-sizing: border-box;
    line-height: 42px;
    height: 60px;
  };
  .text36{
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    height: 60px;
    color: #503D66;
    background: #F0EEF2;
    border-radius: 9px;
    padding: 10px;
    box-sizing: border-box;
  };
  .text12{
    font-family: Lato;
    color: #969696;
    font-size: 12px;
    padding: 3px;
    padding-left: 10px
  }
`