import React, { useState } from 'react'
import { ExportAlert } from './styles'
import alertClose from "../../assets/IconComponents/AlertClose.svg";

export default function Alert(props) {

	return(
		<ExportAlert modalView={props.modalView}>
			<button className="close-button" onClick={()=>props.setModalView(false)}>
				<img src={alertClose} alt="close button" />
			</button>
			<p>Selecciona uno o varios productos para activar la acción</p>
		</ExportAlert>
	)
}