import React from "react";
import { Headers } from "./tableUtils";
import { getProfilePicture } from "../../../_utils/helper";
import Loading from "../../../components/loading";
import {
  TableHeader,
  TableContainer,
  TableRow,
  TableColumn,
} from "./tableStyles";
import ReactImageFallback from "react-image-fallback";
import defaultProfile from "../../../assets/defaultImages/defaultProfile.png";

const UsersTable = (props) => {
  return (
    <TableContainer>
      <TableHeader>
        {Headers[props.userType].map((rowheader, i) => (
          <TableColumn type={!i ? "name-column" : ""} key={rowheader}>
            {rowheader}
          </TableColumn>
        ))}
      </TableHeader>
      {props.users ? (
        props.users.map((user, i) => (
          <TableRow
            id={`table-row-${i}-${props.userType}`}
            index={i}
            onClick={(evt) => {
              if (props.rowClicked) {
                props.rowClicked(user.id);
                evt.stopPropagation();
              }
            }}
            key={user.id_user}
          >
            <TableColumn type="name-column">
              <ReactImageFallback
                src={getProfilePicture(user.id, 30, 30)}
                fallbackImage={defaultProfile}
                alt={user.name}
                className={"img"}
              />
              {user.name}
            </TableColumn>
            <TableColumn>{user.worked}</TableColumn>
            <TableColumn>
              {props.userType === "auditor" ? user.RP : user.RC}
            </TableColumn>
            <TableColumn>
              {props.userType === "auditor" ? user.RCA : user.RA}
            </TableColumn>
          </TableRow>
        ))
      ) : (
        <Loading />
      )}
    </TableContainer>
  );
};
export default UsersTable;
