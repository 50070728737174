import styled from "styled-components";

export const Container = styled.div`
  background-color: #f0f0f0;
  display: flex;
  padding: 9px 20px;
  font-size: 12px;
  font-family: Roboto;

  div {
    flex: 1;
    padding-right: 5px;

    &:nth-child(-n + 3) {
      flex: 2;
    }
    &:nth-child(3) {
      flex: 6;
    }

    &:nth-last-child(-n + 4) {
      text-align: center;
    }

    &.chk-container-g {
      label {
        display: flex;
        padding-left: 15px;

        &:before {
          margin-right: 10px;
        }
      }
    }
  }
`;
