import React, { useState, useEffect } from "react";
import { VerticalSideMenuMainPage } from "contentoh-components-library";
import { ContainerDashboard } from "./ContainerDashboard/index.js";

import dashboard from "../../assets/IconComponents/menuContentOh/iconDashboard.svg";
import products from "../../assets/IconComponents/menuContentOh/iconProducts.svg";
import proveedores from "../../assets/IconComponents/menuContentOh/iconBrand.svg";
import task from "../../assets/IconComponents/menuContentOh/iconTask.svg";
import merchants from "../../assets/IconComponents/menuContentOh/iconMerchants.svg";
import help from "../../assets/IconComponents/menuContentOh/iconHelp.svg";

export default function Main() {
  const [menuContent, setMenuContent] = useState([]);
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem("user"))[0]
      ? JSON.parse(sessionStorage.getItem("user"))[0]
      : JSON.parse(sessionStorage.getItem("user"))
  );
  const menuoptionAdmin = [
    {
      icon: dashboard,
      path: "/Dashboard",
      title: "Métricas",
      suboption: [
        { path: "/Dashboard", title: "Productos" },
        { path: "/metrics", title: "Colaboradores" },
      ],
    },
    {
      icon: products,
      path: "/Products",
      title: "Productos",
    },
    {
      icon: merchants,
      path: "/Acquired",
      title: "Dropship",
    },
    {
      icon: proveedores,
      path: "/Orders",
      title: "Proveedor",
      suboption: [
        { path: "/Orders", title: "Ordenes" },
        { path: "/Entregas", title: "Entregas" },
        { path: "/Facturacion", title: "Facturación" },
      ],
    },
    {
      icon: task,
      path: "/tasks",
      title: "Tareas",
    },
    {
      icon: help,
      path: "/tutorials",
      title: "Tutoriales",
    },
  ];

  const menuoptionRecep = [
    { icon: dashboard, path: "/Dashboard", title: "Dashboard" },
    {
      icon: products,
      path: "/reception",
      title: "Ordenes",
    },
    {
      icon: task,
      path: "/tasks",
      title: "Tareas",
    },
    {
      icon: help,
      path: "/tutorials",
      title: "Tutoriales",
    },
  ];

  const menuoption = [
    { icon: dashboard, path: "/Dashboard", title: "Dashboard" },
    {
      icon: products,
      path: "/Products",
      title: "Productos",
    },
    {
      icon: task,
      path: "/tasks",
      title: "Tareas",
    },
    {
      icon: help,
      path: "/tutorials",
      title: "Tutoriales",
    },
  ];
  const checkImage = 1000;
  useEffect(() => {
    if (user.id_role === 1 || user.id_role === 2) {
      setMenuContent(menuoptionAdmin);
    } else if (user.id_role === 3) {
      setMenuContent(menuoptionRecep);
    } else if (user.id_role > 3 || user.id_role < 10) {
      setMenuContent(menuoption);
    }
    const intervalImg = setInterval(() => {
      if (user.src !== JSON.parse(sessionStorage.getItem("user")).src) {
        setUser(JSON.parse(sessionStorage.getItem("user")));
      }
    }, checkImage);
    return () => {
      clearInterval(intervalImg);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VerticalSideMenuMainPage
      menuoption={menuContent}
      containerdash={<ContainerDashboard />}
    />
  );
}
