import { RowStatus } from "./styles";
export default function StatusOrders(props) {
  return (
    <RowStatus
      key={props.key}
      borderRadius={props.borderRadius}
      width={props.width}
      bg={props.color}
      noMargin={props.noMargin}
    >
      {props.status}
    </RowStatus>
  );
}
