import React, { useState } from "react";
import axios from "axios";
import { getProfilePicture } from "../../_utils/helper";
import AWS from "aws-sdk";

import {
  MainContainer,
  Header,
  AboutMe,
  SelectFile,
  ProfileImage,
  ImageContainer,
} from "./styles";
import ReactImageFallback from "react-image-fallback";

// SVG assets
import location from "../../assets/IconComponents/locationWhite.svg";
import edit from "../../assets/IconComponents/edit.svg";
import profile from "../../assets/IconComponents/profileWhite.svg";
import account from "../../assets/IconComponents/account_circle.svg";
import defaultProfile from "../../assets/defaultImages/defaultProfile.png";

import Loading from "../../components/loading/index";

// Styled components
import {
  Input,
  Textarea,
  BasicData,
  SubmmitButton,
} from "../companyData/styles";

const S3_BUCKET = process.env.REACT_APP_IMAGES_PROFILE_BUCKET;
const REGION = "us-east-1";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_KUTS3,
  secretAccessKey: process.env.REACT_APP_AKUTS3,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export default function PersonalData(props) {
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem("user")));
  const [userAux, setUserAux] = useState(
    JSON.parse(sessionStorage.getItem("user"))
  );
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUploaded, setImageUploaded] = useState(user.src);
  const [dataImage, setDataImage] = useState({});

  const onSubmit = async (e) => {
    e.preventDefault();
    let newValues = {};
    document.querySelector("#nameInput").value !== "" &&
      (newValues.name = document.querySelector("#nameInput").value);
    document.querySelector("#lastNameInput").value !== "" &&
      (newValues.last_name = document.querySelector("#lastNameInput").value);
    document.querySelector("#birthDateInput").value !== "" &&
      (newValues.birth_date = document.querySelector("#birthDateInput").value);
    document.querySelector("#addressInput").value !== "" &&
      (newValues.address = document.querySelector("#addressInput").value);
    document.querySelector("#zipCodeInput").value !== "" &&
      (newValues.zip_code = document.querySelector("#zipCodeInput").value);
    document.querySelector("#telephoneInput").value !== "" &&
      (newValues.telephone = document.querySelector("#telephoneInput").value);
    document.querySelector("#aboutMeInput").value !== "" &&
      (newValues.about_me = document.querySelector("#aboutMeInput").value);
    document.querySelector("#positionInput").value !== "" &&
      (newValues.position = document.querySelector("#positionInput").value);
    setUser(newValues);

    try {
      await axios.put(
        `${process.env.REACT_APP_USER_ENDPOINT}?boolUser=1`,
        newValues,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      );
      let response = await axios.get(process.env.REACT_APP_USER_ENDPOINT, {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      });
      if (dataImage.src) {
        const file = Buffer.from(
          dataImage.src.replace(/^data:image\/\w+;base64,/, ""),
          "base64"
        );
        const params = {
          Body: file,
          ContentEncoding: "base64",
          Bucket: S3_BUCKET,
          Key: `id-${userAux.id_user}/${user.id_user}.png`,
        };
        setLoading(true);
        await myBucket.putObject(params).promise();
        setLoading(false);
      }

      // const picture = getProfilePicture(user.id_user, 83, 83);
      const newUserInfo = JSON.parse(response.data.body).data;
      newUserInfo[0].src = `https://${
        process.env.REACT_APP_IMAGES_PROFILE_BUCKET
      }.s3.amazonaws.com/id-${user.id_user}/${
        user.id_user
      }.png?${new Date().getTime()}`;
      sessionStorage.setItem("user", JSON.stringify(newUserInfo[0]));
      setUser(newUserInfo[0]);
      setActive(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  const PreviewImage = () => {
    const file = document.getElementById("profile").files[0];
    if (file) {
      var oFReader = new FileReader();
      oFReader.fileName = file.name;
      oFReader.readAsDataURL(file);

      oFReader.onload = function (oFREvent) {
        setImageUploaded(oFREvent.target.result);
        setDataImage({
          src: oFREvent.target.result,
          name: oFREvent.target.fileName,
        });
      };
    }
  };

  return (
    <MainContainer>
      <Header>
        <div>
          <figure>
            <img src={profile} alt="Section icon" />
          </figure>
          <p>Información personal</p>
        </div>
        <figure onClick={() => setActive(!active)} className="edit">
          <img src={edit} alt="Edit" />
        </figure>
      </Header>
      <AboutMe>
        <p className="title">Acerca de mi.</p>
        {active ? (
          <Textarea
            id="aboutMeInput"
            placeholder="Agrega una breve descripción sobre ti"
            defaultValue={user.about_me ? user.about_me : ""}
          ></Textarea>
        ) : (
          <p>{user.about_me ? user.about_me : "---"}</p>
        )}
      </AboutMe>
      <BasicData>
        <div>
          <p className="title">Nombre</p>
          {active ? (
            <Input
              id="nameInput"
              placeholder="Nombre"
              defaultValue={user.name ? user.name : ""}
            />
          ) : (
            <p>
              {user.name ? user.name : ""}{" "}
              {user.last_name ? user.last_name : ""}
            </p>
          )}
        </div>
        {active && (
          <div>
            <p className="title">Apellidos</p>
            <Input
              id="lastNameInput"
              placeholder="Apellidos"
              defaultValue={user.last_name ? user.last_name : ""}
            />
          </div>
        )}
        <div>
          <p className="title">Fecha de nacimiento</p>
          {active ? (
            <Input
              placeholder="MM-DD-YYYY"
              type="date"
              id="birthDateInput"
              defaultValue={
                user.birth_Date ? user.birth_Date.split("T")[0] : ""
              }
            />
          ) : (
            <p>{user.birth_Date ? user.birth_Date.split("T")[0] : "---"}</p>
          )}
        </div>
        {active && (
          <div>
            <p className="item">Puesto</p>
            <Input
              id="positionInput"
              placeholder="Puesto"
              defaultValue={user.position ? `${user.position}` : ""}
            />
          </div>
        )}
      </BasicData>
      <BasicData>
        <div>
          <figure>
            <img src={location} alt="Location" />
          </figure>
          <h4>Ubicación</h4>
        </div>
        <div>
          <p className="title">Dirección</p>
          {active ? (
            <Input
              id="addressInput"
              placeholder="Agrega tu dirección"
              defaultValue={user.address ? user.address : ""}
            />
          ) : (
            <p>{user.address ? user.address : "---"}</p>
          )}
        </div>
        <div>
          <p className="title">Código Postal</p>
          {active ? (
            <Input
              id="zipCodeInput"
              placeholder="Código postal"
              defaultValue={user.zip_code ? user.zip_code : ""}
            />
          ) : (
            <p>{user.zip_code ? user.zip_code : "---"}</p>
          )}
        </div>
        <div>
          <p className="title">Teléfono</p>
          {active ? (
            <Input
              id="telephoneInput"
              placeholder="Teléfono"
              defaultValue={user.telephone ? user.telephone : ""}
            />
          ) : (
            <p>{user.telephone ? user.telephone : "---"}</p>
          )}
        </div>
        <div>
          <p className="title">Correo electrónico</p>
          <p>{JSON.parse(sessionStorage.getItem("user")).email}</p>
        </div>
      </BasicData>
      <BasicData>
        <div>
          <figure>
            <img src={account} alt="Profile" />
          </figure>
          <h4>Imágen de perfil</h4>
        </div>
        <ImageContainer>
          <div className="containerSelectFile">
            {active && (
              <SelectFile
                type="file"
                id="profile"
                name="profile"
                accept="image/png"
                onChange={PreviewImage}
              />
            )}
          </div>
          <ProfileImage>
            <ReactImageFallback
              src={imageUploaded}
              fallbackImage={defaultProfile}
              className="img"
            />
          </ProfileImage>
        </ImageContainer>
      </BasicData>
      {active && (
        <BasicData>
          {!loading && (
            <SubmmitButton onClick={(e) => onSubmit(e)}>
              <p>Guardar cambios</p>
            </SubmmitButton>
          )}
          {loading && <Loading />}
        </BasicData>
      )}
    </MainContainer>
  );
}
