import styled from "styled-components";

export const TableHeader = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px 5px 0px 0px;
  background: #f0f0f0;
  font-family: Roboto;
  font-size: 14px;
  text-align: left;
  font-weight: 600;
  color: #262626;
`;

export const TableContainer = styled.div`
  position: relative;
  width: 100%;
  max-height: 400px;
  padding: 18px;
  overflow-y: auto;
`;

export const TableRow = styled.div`
  width: 100%;
  display: flex;
  overflow: unset;
  justify-content: space-between;
  background-color: ${(props) => (props.index % 2 !== 0 ? "#fafafa" : "#fff")};
  border-radius: 5px;
  :hover {
    cursor: pointer;
    background-color: #d4d1d7;
  }
`;

export const TableColumn = styled.div`
  img {
    border-radius: 50%;
    margin-right: 18px;
  }
  & > .img {
    width: 30px;
    height: 30px;
  }
  display: flex;
  justify-content: ${(props) =>
    props.type === "name-column" ? "flex-start" : "center"};
  width: ${(props) =>
    props.type === "name-column" ? "calc(100% - 690px)" : "230px"};
  align-items: center;
  overflow: unset;
  font-family: Avenir Next;
  font-size: 14px;
  padding: 10px;
  position: relative;
  z-index: 10;
`;

export const UserReportContainer = styled.div`
  width: 75%;
  max-height: 200px;
  border-radius: 10px;
  background-color: #503d66;
  overflow-x: auto;
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 30;
  padding: 0 10px 10px;
  & > div:first-child {
    padding: 10px 10px 5px;
    background-color: #503d66;
    width: 100%;
    position: sticky;
    top: 0px;
    color: #fafafa;
    z-index: 5;
  }
`;
