import styled from "styled-components";

export const DropDownContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .choose-version,
  .option-selected p {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 21px;
    color: #d8d7e1;

    & + * {
      margin-left: 9px;
    }
  }

  .dropdown-container {
    position: relative;

    .option-selected {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2px 13px;
      border: 1px solid #b6a1cc;
      box-sizing: border-box;
      border-radius: 15px;
      background-color: transparent;
      min-width: 132px;
    }

    .options {
      position: absolute;
      background-color: #f0eef2;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 9px;
      padding: 4px 10px;
      width: 100%;
      left: 0;
      top: 100%;

      ul {
        li {
          font-family: Lato;
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 21px;
          color: #817393;
        }
      }
    }
  }

  & + * {
    margin-top: 25px;
  }
`;
