import styled from "styled-components";
import { Container } from "../TableHeader/styles";

export const ContainerT = styled(Container)`
  &:nth-child(odd) {
    background-color: #fff;
  }
  &:nth-child(even) {
    background-color: #fafafa;
  }

  .services-list {
    span {
      &.is-billed {
        color: green;
      }
    }
  }
`;
