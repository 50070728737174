import * as XLSX from "xlsx/xlsx.mjs";
import { Status, Tooltip } from "contentoh-components-library";
import ReactImageFallback from "react-image-fallback";
import { Center, ProfileContainer } from "./styles.js";
import { getProfilePicture } from "../../_utils/helper.js";
import defaultProfile from "../../assets/defaultImages/defaultProfile.png";
import spinner from "../../assets/defaultImages/Spinner.gif";

export const getStatusComponent = (status) => {
  const statusList = {
    PA: "Por Asignar",
    AS: "Asignado",
    R: "Recibido",
    CA: "Capturando",
    IE: "Información Enviada",
    RC: "Rechazado Coordinador",
    AC: "Aprobado Coordinador",
    RA: "Rechazado Auditor",
    AA: "Aprobado Auditor",
    RP: "Rechazado Proveedor",
    AP: "Aprobado Proveedor",
    ACA: "Aprobado Cadena",
    RCA: "Rechazado Cadena",
    Ex: "Exportado",
  };
  const tooltip = <p>{status ? statusList[status] : "No Solicitado"}</p>;
  return (
    <Center>
      <Tooltip
        componentTooltip={tooltip}
        children={<Status statusType={status} />}
        position="right"
        followCursor
        addArrow={false}
      />
    </Center>
  );
};

export const getUserComponent = (user) => {
  const { userId, userName } = user;
  return (
    <ProfileContainer>
      <ReactImageFallback
        src={getProfilePicture(userId ?? 0)}
        fallbackImage={defaultProfile}
        initialImage={spinner}
        className="img"
      />
      {userName}
    </ProfileContainer>
  );
};

export const getReport = (data) => {
  const rows = data.map(({ object }) => {
    const {
      sku,
      name,
      model,
      companyName,
      category,
      status,
      orderId,
      userName,
    } = object;
    return {
      SKU: sku ?? "Sin Asignar",
      Nombre: name,
      Modelo: model,
      Proveedor: companyName,
      Departamento: category,
      Precio: "-",
      Estatus: status ?? "N/S",
      OT: orderId ?? "N/A",
      "Adquirido por": userName,
    };
  });
  /* generate worksheet and workbook */
  const worksheet = XLSX.utils.json_to_sheet(rows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");

  /* fix headers */
  // XLSX.utils.sheet_add_aoa(worksheet, [["Name", "Birthday"]], { origin: "A1" });

  /* calculate column width */
  // const max_width = rows.reduce((w, r) => Math.max(w, r.Nombre.length), 10);
  // worksheet["!cols"] = [{ wch: max_width }];

  /* create an XLSX file and try to save to Presidents.xlsx */
  XLSX.writeFile(workbook, "Reporte Adquiridos Dropship.xlsx");
};
