
function IconBell() {
    return (


        <svg width="42" height="53" viewBox="0 0 24 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.490225 24.3742C0.578409 24.317 1.2421 23.852 1.90667 22.4455C3.12712 19.8625 3.38329 16.224 3.38329 13.6265C3.38329 13.6152 3.38353 13.604 3.38394 13.5927C3.39724 10.1598 5.45288 7.20043 8.39452 5.87264V3.84195C8.39452 1.99918 9.89124 0.5 11.731 0.5H12.0075C13.8472 0.5 15.3439 1.99918 15.3439 3.84195V5.87252C18.2954 7.20459 20.3551 10.179 20.3551 13.6265C20.3551 16.224 20.6113 19.8625 21.8318 22.4455C22.4963 23.852 23.16 24.3169 23.2482 24.3742C23.6198 24.5462 23.803 24.9434 23.7175 25.346C23.6312 25.7526 23.2512 26.0329 22.8362 26.0329H16.394C16.358 28.5021 14.3427 30.5 11.8692 30.5C9.39565 30.5 7.38038 28.5021 7.3444 26.0329H0.902197C0.487236 26.0329 0.107138 25.7526 0.0208302 25.346C-0.0645409 24.9434 0.118624 24.5461 0.490225 24.3742ZM13.586 3.84195C13.586 2.97002 12.8779 2.26068 12.0074 2.26068H11.7309C10.8604 2.26068 10.1523 2.97002 10.1523 3.84195V5.30193C10.707 5.18727 11.2813 5.12691 11.8694 5.12691C12.4574 5.12691 13.0315 5.18721 13.586 5.30176V3.84195H13.586ZM11.8692 28.7393C13.3735 28.7393 14.6005 27.5313 14.6362 26.033H9.10228C9.1379 27.5312 10.3649 28.7393 11.8692 28.7393ZM8.33013 24.2722C8.3303 24.2722 20.8332 24.2722 20.8332 24.2722C20.6811 24.0352 20.5264 23.7669 20.3725 23.4637C19.1946 21.1409 18.5973 17.8312 18.5973 13.6265C18.5973 9.91062 15.5792 6.8876 11.8695 6.8876C8.15979 6.8876 5.14169 9.91062 5.14169 13.6294C5.14169 13.6402 5.14151 13.651 5.1411 13.6618C5.13776 17.8496 4.54052 21.1474 3.36583 23.4637C3.21202 23.767 3.05722 24.0352 2.90523 24.2722H8.33013Z" fill="#817393" />
        </svg>
    )
}
export default IconBell;