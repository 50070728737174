import { BlurContainer, ModalContainer } from "./styles";
import { PopUpContainer } from "../dinamicTable/styles";
import { ImagesPopup } from "../dinamicTable/imagesPopup";
import Loading from "../../../../components/loading";
import GenericModal from "../../../../components/GenericModal/index";
import React, { useState } from "react";
import axios from "axios";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";

import closeWhite from "../../../../assets/IconComponents/closeWhite.svg";
import succesIcon from "../../../../assets/IconComponents/multipleEditionIcons/succesIcon.svg";
import errorModal from "../../../../assets/IconComponents/errorModal.svg";

const S3_BUCKET = process.env.REACT_APP_IMAGES_BUCKET;
const REGION = "us-east-1";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_KUTS3,
  secretAccessKey: process.env.REACT_APP_AKUTS3,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export const UploadImagemodal = (props) => {
  const [valuesSelected, setValuesSelected] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [errorData, setErrorData] = useState({});
  const [showError, setShowError] = useState(false);

  const packageOptions = JSON.parse(
    sessionStorage.getItem("packagingType")
  ).map((e) => ({
    name: `${e.name} - ${e.deliverable_value}`,
    value: e.id,
  }));

  const imagenOptions = JSON.parse(sessionStorage.getItem("imageType")).map(
    (e) => ({
      name: `${e.name}`,
      value: e.id,
    })
  );

  const isReadyToSave = () => {
    return (
      valuesSelected.packingType && valuesSelected.imageType && props.img?.src
    );
  };

  const uploadImages = async () => {
    setIsLoading(true);
    const ext = props.img.name.split(".").pop();
    const file = Buffer.from(
      props.img.src.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    const uuid = uuidv4();
    let allValid = true;
    if (props.articlesSelected.length > 0) {
      try {
        let promiseArray = [];
        props.articlesSelected.forEach((item) => {
          if (
            user.id_user === item.article.id_images_especialist ||
            user.id_user === item.article.id_images_facilitator ||
            user.id_user === item.article.id_auditor ||
            user.id_role === 1
          ) {
            const params = {
              ACL: "public-read",
              Body: file,
              Bucket: S3_BUCKET,
              Key: `id-${item.article.id_article}/${item.version}/${props.imageId}-${uuid}.${ext}`,
            };
            promiseArray.push(myBucket.putObject(params).promise());
          } else {
            allValid = false;
          }
        });
        await Promise.all(promiseArray);
        promiseArray = [];
        props.articlesSelected.forEach((item) => {
          if (
            user.id_user === item.article.id_images_especialist ||
            user.id_user === item.article.id_images_facilitator ||
            user.id_user === item.article.id_auditor ||
            user.id_role === 1
          ) {
            promiseArray.push(
              axios.put(
                `${process.env.REACT_APP_ARTICLE_DATA_ENDPOINT}?image=true&version=${item.version}`,
                {
                  articleId: item.article.id_article,
                  orderId: item.article.id_order,
                  articleData: [
                    {
                      name: props.img.name,
                      ext: ext,
                      width: props.img.width,
                      height: props.img.height,
                      imageID: props.imageId,
                      packingType: valuesSelected.packingType,
                      imageType: valuesSelected.imageType,
                      uuid: uuid,
                    },
                  ],
                },
                {
                  headers: {
                    Authorization: sessionStorage.getItem("jwt"),
                  },
                }
              )
            );
          }
        });
        await Promise.all(promiseArray);
      } catch (err) {
        console.log(err);
        alert("Ups... Algo salió mal, intentalo nuevamente");
      }
    } else {
      const item = JSON.parse(sessionStorage.getItem("multipleEditionList"))[
        props.rIndex
      ];
      const params = {
        ACL: "public-read",
        Body: file,
        Bucket: S3_BUCKET,
        Key: `id-${item.article.id_article}/${item.version}/${props.imageId}-${uuid}.${ext}`,
      };
      try {
        if (
          user.id_user === item.article.id_images_especialist ||
          user.id_user === item.article.id_images_facilitator ||
          user.id_user === item.article.id_auditor ||
          user.id_role === 1
        ) {
          await Promise.all([myBucket.putObject(params).promise()]);
          await axios.put(
            `${process.env.REACT_APP_ARTICLE_DATA_ENDPOINT}?image=true&version=${item.version}`,
            {
              articleId: item.article.id_article,
              orderId: item.article.id_order,
              articleData: [
                {
                  name: props.img.name,
                  ext: ext,
                  width: props.img.width,
                  height: props.img.height,
                  imageID: props.imageId,
                  packingType: valuesSelected.packingType,
                  imageType: valuesSelected.imageType,
                  uuid: uuid,
                },
              ],
            },
            {
              headers: {
                Authorization: sessionStorage.getItem("jwt"),
              },
            }
          );
        } else {
          allValid = false;
        }
      } catch (err) {
        console.log(err);
        alert("Ups... Algo salió mal, intentalo nuevamente");
      }
    }
    if (allValid) {
      window.location.reload();
    } else {
      setErrorData({
        message: "Parece que no fuiste asignado a todos los artículos",
        detail:
          "Solo se actualizaron aquellos articulos a los que fuiste asignado",
        icon: errorModal,
      });
      setShowError(true);
    }
  };

  return (
    <>
      <BlurContainer>
        <ModalContainer>
          <div className="close-button-container">
            <button onClick={() => props.setModalImage(false)}>
              <img src={closeWhite} alt="Cerrar" />
            </button>
          </div>
          <div className="succes-icon">
            <img src={succesIcon} alt="Guardado con Éxito" />
          </div>
          <div className="title-container">
            <h2>{isLoading && "Cargando..."}</h2>
            <p>Por favor elige el tipo de empaque e imagen</p>
          </div>
          <div className="select-container">
            <PopUpContainer backColor={"#281F33"}>
              <ImagesPopup
                title={"Tipo de empaque *"}
                packageOptions={packageOptions}
                color={"#D4D1D7"}
                setValuesSelected={(value) =>
                  setValuesSelected({ ...valuesSelected, packingType: value })
                }
              />
              <ImagesPopup
                title={"Tipo de imagen *"}
                packageOptions={imagenOptions}
                color={"#D4D1D7"}
                setValuesSelected={(value) =>
                  setValuesSelected({ ...valuesSelected, imageType: value })
                }
              />
            </PopUpContainer>
          </div>
          <div className="save-button-container">
            {isReadyToSave() && !isLoading && (
              <button
                onClick={() => {
                  if (!props.img.uuid) {
                    uploadImages();
                  }
                }}
              >
                Guardar
              </button>
            )}
            {isLoading && <Loading />}
          </div>
        </ModalContainer>
      </BlurContainer>
      {showError && (
        <GenericModal
          close={() => {
            setShowError(false);
            props.setModalImage(false);
          }}
          message={errorData.message}
          detail={errorData.detail}
          img={errorData.icon}
          textArea={false}
          button1={{
            name: "Regresar",
            action: () => window.location.reload(),
          }}
        />
      )}
    </>
  );
};
