import styled from "styled-components";

export const MainContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const Title = styled.p`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 41px;
  color: #603888;
  margin-top: 3%;
`;

export const TableWarning = styled.div`
  font-family: Avenir Next;
  box-sizing: border-box;
  font-size: 20px;
  color: #603888;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5%;
`;
