import styled from "styled-components";

export const MainContainer = styled.div`
  text-align:center
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const DeliverDate = styled.span`
  background-color: ${(props) => (props.setted ? "green" : "gray")};
  box-sizing: border-box;
  padding: 5px 15px;
  border-radius: 5px;
  color: white;
`;
