import styled from "styled-components";

export const ContainerChart = styled.td`
  padding-right: 25px;
`;

export const CheckboxContainer = styled.div`
  border-radius: 7px;
  padding: 16px 20px;
  display: flex;
  flex-wrap: wrap;
`;

export const TableColStatus = styled.div`
  box-sizing: border-box;
  vertical-align: middle;
  text-align: center;
  font-size: 13px;
  color: #817393;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #d4d1d7;
    border-radius: 3px;
  }
`;

export const LabelFlag = styled.label`
  margin-top: 10px;
  width: 105px;
`;
export const Label = styled.label`
  flex: 25%;
  text-align: left;
  font-family: Lato;
  font-size: 11px;
  line-height: 21px;
  position: relative;
`;
export const RowRetailers = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  flex: 1;
  position: relative;

  .Cadenas {
    padding-top: 10px;
  }

  .iconDrop {
    visibility: hidden;
    position: absolute;
    right: 0;
    top: 0px;
  }
  :hover .iconDrop {
    visibility: visible;
    cursor: pointer;
  }
  // :hover {
  //   background-color: #d4d1d7;
  // }

  & + * {
    border-left: 1px solid #f0eef2;
  }
`;

export const Stat = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: center;
  margin: 0 auto;
  height: 20px;
  width: 30px;
  background-color: ${(props) => {
    switch (props.color) {
      case "NULL":
        return "#D4D1D7";
      case "ACA":
      case "AA":
      case "AP":
      case "AC":
        return "#18A0FB";
      case "RCA":
      case "RA":
      case "RP":
      case "RC":
        return "#D74DED";
      case "CA":
      case "IE":
        return "#ED9A4D";
      default:
        return "#D4D1D7";
    }
  }};
  padding: 2% 7%;
  border-radius: 30px;
  color: ${(props) => (props.estatus === "Actualizar" ? "#969696" : "#FFFFFF")};
`;
export const Container = styled.div`
  display: flex;
  flex: ${(props) => props.flex};
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  justify-content: ${(props) => props.justifycontent};
  flex-direction: ${(props) => props.flexdirection};
  flex-wrap: ${(props) => props.flexwrap};
  align-self: ${(props) => props.alignself};
  .imgIcon {
    margin-right: 1rem;
  }

  &.title-container {
    flex-direction: column;
    max-width: 550px;

    & + * {
      margin-left: 100px;
    }
  }

  &.main-container {
    justify-content: space-between;
  }

  &.cards-container {
    width: auto;
    align-items: flex-end;
    margin-bottom: 30px;
    margin-top: 30px;
    margin-right: 90px;
  }

  .export-button-container,
  .update-icon-container,
  .filter-icon-container,
  .trash-icon-container {
    position: relative;
    margin-right: 20px;
  }

  .export-button-container {
    min-width: 160px;
  }

  .btn-product-options {
    & + * {
      margin-left: 10px;
    }
  }

  &#button-container {
    align-items: flex-end;

    .right-side {
      display: flex;
      align-items: center;
    }
  }
`;
Container.defaultProps = {
  width: "100%",
  padding: "0",
  flexdirection: "row",
  justifycontent: "space-around",
  flexwrap: "nowrap",
};
export const ContainerSearch = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 15px;
`;

// GENERAL COMPONENTS
export const MainContainer = styled.div`
  box-sizing: border-box;
  height: calc(100% - 100px);
  width: 100%;
  margin-right: 1%;
  padding: 0.5%;
  .img {
    height: 20px;
    width: 20px;
  }
`;
export const Title = styled.p`
  font-family: Raleway;
  font-size: 30px;
  font-weight: 400;
  color: #503d66;
  line-height: 35px;
  margin-bottom: 1.3%;
`;

export const Title2 = styled.p`
  font-family: Raleway;
  font-size: 30px;
  font-weight: 400;
  color: #b12d84;
  line-height: 35px;
  margin-bottom: 1.3%;
`;
export const Icon = styled.figure`
  display: flex;
  align-items: center;
  height: 80%;
  cursor: pointer;
  ${({ carrito }) =>
    carrito &&
    `
    border-left: 1px solid #F0EEF2;
    padding-left: 18px;
    box-sizing: border-box;
  `};
  > img {
    width: ${(props) => (props.logo ? "50px" : "30px")};
    height: ${(props) => (props.logo ? "50px" : "30px")};
    ${({ logo }) =>
      logo &&
      `
      border-radius: 50%;
      margin-right: 20px;
    `}
    ${({ carrito }) =>
      carrito &&
      `
      width: 18px;
      height: 18px;
    `}
  }

  &.clear-filters {
    position: relative;

    .modal-filter {
      display: none;
      position: absolute;
      background-color: #281f33;
      right: -172px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 5;
      color: #fbfbfb;
      height: 40px;
      align-items: center;
      padding-right: 6px;
      border-radius: 3px;

      img {
        width: 20px;
        height: 20px;

        & + * {
          margin-left: 2px;
        }
      }

      p {
        font-family: Avenir Next;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        white-space: nowrap;
      }

      &:before {
        content: "";
        position: absolute;
        display: block;
        width: 20px;
        height: 20px;
        left: -10px;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        z-index: -1;
        background-color: inherit;
      }
    }

    &:hover {
      .modal-filter {
        display: flex;
      }
    }
  }

  & + * {
    margin-left: 10px;
  }
`;
export const Button = styled.p`
  display: flex;
  align-items: center;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  height: ${(props) => (props.height ? props.height : "40px")};
  padding: 0 20px;
  box-sizing: border-box;
  background-color: ${(props) => (props.color ? props.color : "#E33AA9")};
  color: #fff;
  border-width: 0;
  border-radius: 40px;
  cursor: pointer;
  justify-content: center;

  > figure {
    margin-left: 5px;
    > img {
      width: 15px;
      height: 15px;
    }
  }
  ${({ color }) =>
    color &&
    `
    font-weight: 500;
    font-size: 14px;
  `}
`;

export const SubTitle = styled.p`
  font-family: Avenir Next;
  width: 100%;
  font-size: 18px;
  line-height: 21px;
  color: #817393;
  margin-bottom: 6.2%;
`;

export const LeftTableContainer = styled.div`
  width: 100%;
  max-width: 100%;
  height: calc(100vh - 340px);
  overflow-x: auto;
  overflow-y: auto;
  border-right: 1px solid darkgray;
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #d4d1d7;
    border-radius: 3px;
  }
`;

export const RightTableContainer = styled.div`
  height: calc(100vh - 340px);
  overflow-x: auto;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #d4d1d7;
    border-radius: 3px;
  }
`;

// FILTER UPPER SECTION COMPONENTS
export const FilterDiv = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 72px;
  border-top: 1px solid #f0eef2;
  padding-top: 1.1%;
`;

export const CustomInput = styled.div`
  display: ${(props) => props.tableInput && "flex"};
  justify-content: ${(props) => props.tableInput && "center"};
  width: ${(props) => (props.tableInput ? "100%" : "14%")};
  position: relative;
  width: ${(props) => (props.width ? props.width : "14%")};
  justify-content: space-evenly;
`;

export const CustomInputIcon = styled.div`
  position: absolute;
  top: ${(props) => (props.tableInput ? "1px" : "7px")};
  right: ${(props) => (props.tableInput ? "0" : "8px")};
  pointer-events: none;
  img {
    height: 100%;
  }
`;

export const FilterSelect = styled.select`
  border: 1px solid #ed944d;
  box-sizing: border-box;
  padding: 0 20% 0 5%;
  color: #ed944d;
  font-weight: 500;
  width: 100%;
  height: 30px;
  border-radius: 30px;
  appearance: none;
  :hover {
    cursor: pointer;
  }
  > option {
    color: #ed944d;
  }
`;
export const SearchBar = styled.input`
  border: 1px solid #f0eef2;
  box-sizing: border-box;
  padding: 0 5%;
  width: 100%;
  height: 30px;
  border-radius: 30px;
`;
export const FilterButton = styled.button`
  margin-right: 10px;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  border-radius: 60px;
  border: none;
  background-color: #fafafa;
  :hover {
    cursor: pointer;
  }
`;

// TABLE COMPONENTS
export const TablesContainer = styled.div`
  display: flex;
  align-items: flex-start;
  height: calc(100vh - 390px);
  width: 100%;
  font-family: Avenir Next;
`;

export const TaskCompleted = styled.h1`
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: Avenir Next;
  font-weight: 500;
  color: #503d66;
  font-size: 20px;
`;

export const TableRowRight = styled.tr`
  width: 910px;
  height: 40px;
  background-color: ${(props) => (props.index % 2 === 0 ? "#FAFAFA" : "white")};
  display: flex;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 4;
`;

export const TableRow = styled.div`
  box-sizing: border-box;
  height: 40px;
  background-color: ${(props) => (props.index % 2 === 0 ? "#FAFAFA" : "white")};
  display: flex;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 4;
  width: 150%;

  .iconDrop {
    visibility: hidden;
    position: absolute;
    right: 0;
  }
  :hover .iconDrop {
    visibility: visible;
    cursor: pointer;
  }
  :hover {
    background-color: ${(props) => (props.noHover ? "" : "#d4d1d7")};
  }
`;
export const TableHeader = styled.div`
  color: #503d66;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;

  &:first-child {
    max-width: 40px;
  }

  &:last-child {
    max-width: 60px;
  }

  & + * {
    border-left: 1px solid #f0eef2;
  }
`;

export const TableHeaderRight = styled.th`
  color: #503d66;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;
  width: 70px;

  &:first-child {
    width: 70px;
  }

  &:last-child {
    width: 70px;
  }

  & + * {
    border-left: 1px solid #f0eef2;
  }
`;

export const TableSelect = styled.div`
  text-align-last: center;
  width: fit-content;
  color: #503d66;
  appearance: none;
  border: none;
  font-size: 14px;
  font-family: Avenir Next;
  font-weight: 600;
`;
export const MainTableContainer = styled.div`
  width: 100%;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ButtonLoad = styled.button`
  font-family: Avenir Next;
  font-size: 18px;
  margin-bottom: 5px;
  border: none;
  background-color: #e33aa9;
  color: #fff;
  border-radius: 7px;
  box-sizing: border-box;
  padding: 0 50px;
  height: 30px;
  width: 20%;
  :hover {
    cursor: pointer;
  }
`;

export const LoadInfo = styled.p`
  font-family: Avenir Next;
`;
