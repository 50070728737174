import styled from "styled-components";

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  .circle {
    width: 30px;
    height: 30px;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }

    & + * {
      margin-left: 10px;
    }
  }

  .export-button {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    background-color: #e33aa9;
    color: #fff;
    border: none;
    border-radius: 30px;
    height: 40px;
    padding: 0 45px;
    cursor: pointer;
  }
`;
