import {
  ContainerButton,
  ContainerMap,
  TitleCompany,
  TitleUser,
  InputForm,
  Containers,
  DateText,
  ODTText,
} from "./quotesStyles.js";
import MapContainer from "../../components/mapContainer/index";
import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Moment from "react-moment";
import Loading from "../../components/loading";
import { Windows } from "styled-icons/boxicons-logos";

const Quotes = () => {
  const [appointmentSelected, setAppointmentSelected] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [reason, setReason] = useState("");
  const [company, setCompany] = useState("");
  const [name, setName] = useState("");

  const getAppointment = async () => {
    try {
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    axios({
      method: "get",
      url: process.env.REACT_APP_APPOINTMENT_ENDPOINT,
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    }).then((response) => {
      setIsLoading(false);
      const data = JSON.parse(response.data.body).data;
      const selectedId = sessionStorage.getItem("appointmentSelected");
      const redirectedId = sessionStorage.getItem("ordersAppointment")
        ? JSON.parse(sessionStorage.getItem("ordersAppointment"))
        : false;
      if (redirectedId) {
        setAppointmentSelected(data.find((e) => e.id_order === redirectedId));
        sessionStorage.removeItem("ordersAppointment");
      } else if (selectedId) {
        setAppointmentSelected(
          data.find((e) => e.id_cita === parseInt(selectedId))
        );
        sessionStorage.removeItem("appointmentSelected");
      } else {
        const appointment = data[0];
        appointment && setAppointmentSelected(appointment);
      }
    });
    setCompany(JSON.parse(sessionStorage.getItem("company")).company_name);
    const user = JSON.parse(sessionStorage.getItem("user"));
    setName(`${user.name} ${user.last_name}`);
  }, []);

  const updateAppointment = async () => {
    try {
      if (reason !== "") {
        setIsLoading(true);
        await axios({
          method: "put",
          url: process.env.REACT_APP_APPOINTMENT_ENDPOINT,
          data: {
            appointment: JSON.parse(sessionStorage.getItem("stepTwo")),
            cita: appointmentSelected.id_cita,
            reason: reason,
          },
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        });
        setIsLoading(false);
        window.location.href = "/Products";
      } else {
        alert("Debe llenar el campo de razón para reagendar.");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Containers>
      <ContainerMap>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <TitleCompany>{company}</TitleCompany>
            <TitleUser>{name}</TitleUser>
            {appointmentSelected.id_cita ? (
              <>
                <DateText>
                  <Moment format="DD/MM/YYYY">
                    {moment(appointmentSelected.date)}
                  </Moment>
                </DateText>
                <ODTText> ODT {appointmentSelected.id_cita}</ODTText>

                <MapContainer width="100%" cita={appointmentSelected} />
                {appointmentSelected.reason ? (
                  <p>Ya reagendaste una vez ({appointmentSelected.reason})</p>
                ) : (
                  <>
                    <InputForm
                      placeholder="Escribe el motivo de reagendar"
                      onChange={(e) => setReason(e.target.value)}
                    />
                    <ContainerButton>
                      <div onClick={() => updateAppointment()}>Guardar</div>
                    </ContainerButton>
                  </>
                )}
              </>
            ) : (
              <p>No tiene citas registradas</p>
            )}
          </>
        )}
      </ContainerMap>
    </Containers>
  );
};

export default Quotes;
