import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { isElementClicked } from "../../_utils/helper";
import { MainContainer, DeliverDate } from "./styles";
import "react-datepicker/dist/react-datepicker.css";
import "./calendarStyle.css";

/**
 * @param {Object} item - Order/Product/Task which needs a date update
 * @param {string} item.key - value which tells whether the datepicker or the current date must be shown
 * @param {Int} item.orderId
 * @param {'timestamp'} item.initialDate - default date from de item
 * @param {function} item.updateDate - function which updates the date of the item(s)
 */
const TableDatepicker = ({ item }) => {
  const [pickerOpen, setPickerOpen] = useState(false);

  const clickOutsideToClose = useCallback((evt) => {
    const currentDateClicked = ["dated", "pending"].includes(evt.target.id);

    if (!currentDateClicked && !isElementClicked(evt, item.key)) {
      document.removeEventListener("click", clickOutsideToClose);
      setPickerOpen(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pickerOpen) {
      document.addEventListener("click", clickOutsideToClose);
    }
  }, [pickerOpen, clickOutsideToClose]);

  return (
    <MainContainer id={item.key} className="orders">
      {pickerOpen ? (
        <DatePicker
          startOpen
          dateFormat={"dd/MM/yyyy"}
          selected={
            item.initialDate
              ? moment(item.initialDate).toDate()
              : item.initialDate
          }
          onChange={(date) => {
            item.updateDate(item.orderId, date);
          }}
          className="orders"
        />
      ) : item.initialDate ? (
        <DeliverDate
          onClick={() => setPickerOpen(true)}
          id="dated"
          setted
          className="orders"
        >
          {moment(item.initialDate).format("DD/MM/YYYY")}
        </DeliverDate>
      ) : (
        <DeliverDate
          onClick={() => setPickerOpen(true)}
          id="pending"
          className="orders"
        >
          Sin asignar
        </DeliverDate>
      )}
    </MainContainer>
  );
};

export default TableDatepicker;
