import React, { useState, useEffect, useCallback } from "react";
import {
  ODTText,
  Text10,
  ContainerMap,
  Text14,
  ContainerInfo,
  ContainerDatos,
  Text12,
  ContainerSelect,
  CustomSelect,
  ContainerCalendar,
  ContainerDate,
  DateButton,
  CustomInput,
  CheckboxContainer,
  AddressInfo,
  BotonEnviarCorreo,
  Text11,
  InputCalle,
  Row,
  InputEstado,
  InputCP,
  InputNombre,
  InputTelefono,
} from "./styles";
import { Calendar } from "react-datepicker2";
import Leaflet from "./map";
import unchecked from "../../assets/IconComponents/unchecked.svg";
import checked from "../../assets/IconComponents/checked.svg";
import IconEmailPink from "../../assets/IconComponents/IconEmailPink.js";

function Map(props) {
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const latlngOffice = { lat: 19.033333, lng: -98.183334 };
  const today = new Date();
  const [date, setDate] = useState(
    new Date(new Date().valueOf() + 1000 * 3600 * 24)
  );
  const [select, setSelect] = useState("En oficina");
  const [dateString, setDateString] = useState("");
  const [collectedName, setCollectedName] = useState("");
  const [collectedPhone, setCollectedPhone] = useState("");

  const [street, setStreet] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState(null);
  const [latlng, setLatlng] = useState(latlngOffice);

  const onChange = useCallback(() => {
    const valueStreet = document.getElementById("street").value;
    const valueCity = document.getElementById("city").value;
    const valueZipCode = document.getElementById("zipCode").value;
    const valueCollectedName = document.getElementById("collectedName").value;
    const valueCollectedPhone = document.getElementById("collectedPhone").value;
    setStreet(valueStreet);
    setCity(valueCity);
    setZipCode(valueZipCode);
    setCollectedName(valueCollectedName);
    setCollectedPhone(valueCollectedPhone);
    const data = {
      street: select === "En oficina" ? "" : valueStreet,
      city: select === "En oficina" ? "" : valueCity,
      zipCode: select === "En oficina" ? "" : valueZipCode,
      latlng: select === "En oficina" ? "" : latlng,
      date: dateString,
      collectedName: collectedName,
      collectedPhone: collectedPhone,
      type: select,
    };
    sessionStorage.setItem("stepTwo", JSON.stringify(data));
  }, [collectedName, collectedPhone, dateString, latlng, select]);

  useEffect(() => {
    const data = {
      street: select === "En oficina" ? "" : street,
      city: select === "En oficina" ? "" : city,
      zipCode: select === "En oficina" ? "" : zipCode,
      latlng: select === "En oficina" ? "" : latlng,
      date: dateString,
      collectedName: select === "En oficina" ? "" : collectedName,
      collectedPhone: select === "En oficina" ? "" : collectedPhone,
      type: select,
    };
    sessionStorage.setItem("stepTwo", JSON.stringify(data));
  }, [
    city,
    collectedName,
    collectedPhone,
    dateString,
    latlng,
    select,
    street,
    zipCode,
  ]);

  useEffect(() => {
    if (props.cita && props.cita.id_cita) {
      setDate(new Date(props.cita.date));
      setSelect(props.cita.type);
      setCollectedName(props.cita.collectedName);
      setCollectedPhone(props.cita.collectedPhone);
      setStreet(props.cita.street);
      setZipCode(props.cita.zipCode);
      setCity(props.cita.city);
      if (props.cita.type === "En oficina") {
        setLatlng(latlngOffice);
      } else {
        setLatlng({ lat: props.cita.lat, lng: props.cita.lng });
        document.getElementById("street") &&
          (document.getElementById("street").value = props.cita.street);
        document.getElementById("street") &&
          (document.getElementById("city").value = props.cita.city);
        document.getElementById("street") &&
          (document.getElementById("zipCode").value = props.cita.zipCode);
        document.getElementById("street") &&
          (document.getElementById("collectedName").value =
            props.cita.collectedName);
        document.getElementById("street") &&
          (document.getElementById("collectedPhone").value =
            props.cita.collectedPhone);
      }
    }
  }, [props.id_cita, props]);

  useEffect(() => {
    setDateString(
      new Intl.DateTimeFormat("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).format(date)
    );
  }, [date]);

  useEffect(() => {
    if (street && city && zipCode) {
      setAddress(`${street} ${city} ${zipCode}`);
    } else {
      setAddress(null);
    }
  }, [street, city, zipCode]);

  return (
    <ContainerMap width={props.width}>
      <ContainerSelect>
        <ODTText>Entrega del producto</ODTText>
        <CustomSelect
          defaultValue={select}
          onChange={(e) => {
            setLatlng(latlngOffice);
            setSelect(e.target.value);
          }}
        >
          <option
            value="En oficina"
            selected={select === "En oficina" && "selected"}
          >
            En oficina
          </option>
          <option value="Pick up" selected={select === "Pick up" && "selected"}>
            Pick up
          </option>
          <option value="Insitu" selected={select === "Insitu" && "selected"}>
            Institu
          </option>
        </CustomSelect>
      </ContainerSelect>
      <ContainerInfo>
        <Text10>
          Nosotros pasamos por tus productos a tus instalaciones. (Costo extra)
        </Text10>
      </ContainerInfo>
      <hr />
      <Text14 className="text-map">
        Elige la ubicación en el mapa y agrega la dirección de fora escrita.
      </Text14>
      <ContainerInfo>
        <Leaflet
          draggable={select !== "En oficina" ? true : false}
          address={address}
          latlng={latlng}
          setLatlng={setLatlng}
        />
        <ContainerDatos>
          {select === "En oficina" ? (
            <div>
              <Text12>Dirección de la entrega en nuestras oficinas</Text12>
              <hr />
              <Text12 className="txt-address">
                Eugenio Sue #316 Col. Polanco CDMX, México.
              </Text12>
              <BotonEnviarCorreo>
                Enviar por correo <IconEmailPink />
              </BotonEnviarCorreo>
              <hr />
              <Text12 className="office-contact">Contacto de oficina</Text12>
              <Text11>+52 (55) 4165-1497 </Text11>
              <Text11>01-800-26-91-338</Text11>
              <hr />
            </div>
          ) : (
            <div>
              <Text12>Otra dirección o elige el la ubicación en el mapa</Text12>
              <InputCalle
                id="street"
                placeholder="Calle"
                autoComplete="off"
                onBlur={onChange}
                defaultValue={street}
              />
              <Row>
                <InputEstado
                  id="city"
                  placeholder="Estado"
                  autoComplete="off"
                  onBlur={onChange}
                  defaultValue={city}
                />
                <InputCP
                  id="zipCode"
                  placeholder="CP"
                  autoComplete="off"
                  onBlur={onChange}
                  defaultValue={zipCode}
                />
              </Row>
              <hr />
              <Text12>Contacto de recolección</Text12>
              <InputNombre
                id="collectedName"
                placeholder="Nombre"
                onChange={onChange}
                defaultValue={collectedName}
              />
              <Row>
                <p className="pNumber">+52 </p>
                <InputTelefono
                  id="collectedPhone"
                  placeholder="Teléfono"
                  onChange={onChange}
                  defaultValue={collectedPhone}
                />
              </Row>
              <hr />
            </div>
          )}
          <ContainerCalendar>
            <ContainerDate>
              <Text12>Asigna el dia de tu visita</Text12>
              <DateButton onClick={() => setCalendarIsOpen(!calendarIsOpen)}>
                {dateString}
              </DateButton>
            </ContainerDate>
            {calendarIsOpen && (
              <Calendar
                id="calendar"
                isGregorian={true}
                min={today.toDateString()}
                onChange={(value) => {
                  setDate(value._d);
                  setCalendarIsOpen(false);
                }}
              />
            )}
          </ContainerCalendar>
        </ContainerDatos>
      </ContainerInfo>
    </ContainerMap>
  );
}
export default Map;
