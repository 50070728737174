import { Container } from "./styles";
import { TableBody } from "./TableBody";
import { TableHeader } from "./TableHeader";

export const Table = ({
  filters = {},
  retailers,
  articlesList,
  productsToBilling,
  setProductsToBilling,
}) => {
  return (
    <Container>
      <TableHeader
        filters={filters}
        articlesList={articlesList}
        setProductsToBilling={setProductsToBilling}
      />
      <TableBody
        articlesList={articlesList}
        retailers={retailers}
        productsToBilling={productsToBilling}
        setProductsToBilling={setProductsToBilling}
      />
    </Container>
  );
};
