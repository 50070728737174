import React from "react";
import { Link } from "react-router-dom"; 
import { ContainerCard } from "./styles";

const CardTutorials = ({ image, title, section, platform, path }) => {
  console.log("at",path)
  return (
    <Link to={path.toLowerCase()}>
      <ContainerCard>
        <img className="card-image" src={image} alt="" />
        <div class="p-5">
          <p class="mb-2 title">{title}</p>
          {section && <p class="mb-2 description">{section}</p>}
          {platform && <p class="mb-2 description">{platform}</p>}
        </div>
      </ContainerCard>
    </Link>
  );
};

export default CardTutorials;
