import styled from "styled-components";
import { COLORS } from "../../../variables";

export const ContainerHeader = styled.header`
  width: 100%;
  justify-content: space-around;
  align-items: center;
  background-color: #ffffff;
  display: flex;
  box-shadow: 0 1px 0 rgba(22, 8, 43, 0.1);
  height: 100px;
  flex-shrink: 0;
`;
export const ContainerPrincipal = styled.div`
  width: 100%;
  display: flex;
  height: 100vh;
  justify-content: space-between;
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 3.5rem;
`;
export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
`;
export const ContainerInfoRight = styled.div`
  display: flex;
  width: 156px;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ContainerInfoTitle = styled.span`
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  width: 100%;
  text-align: right;
  letter-spacing: -0.015em;
  color: #b12d84;
`;
export const ContainerLogUser = styled.div`
  display: flex;
  text-align: center;
`;

export const ContainerInfoSubtitle = styled.span`
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 8px;
  text-align: right;
  color: #817393;
`;

export const UserInfo = styled.div`
  width: 230px;
  display: flex;
  align-items: center;
  padding: 0 10px 0 10px;
  height: 53px;
  background: #f0eef2;
  border-radius: 25px;
  justify-content: space-between;
  position: relative;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 99vh;
  max-height: 99vh;
  overflow: hidden;
  flex-direction: column;
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;

export const HeaderImg = styled.img`
  max-width: 100%;
  height: auto;
  width: 35px;
  height: 35px;
  border-radius: 50%;
`;
export const HeaderTitle = styled.span`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.015em;
  color: #b12d84;
`;
export const HeaderInfo = styled.span`
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 8px;
  color: #817393;
`;
export const ContainIcon = styled.div`
  height: 35px;
  position: relative;
`;

export const HeaderSearch = styled.div`
  display: flex;
  width: 50%;
  background-color: transparent;
  height: 30px;
  padding: 0 15px 0 0;
`;

export const PulsatingCircle = styled.div`
  position: relative;
  right: -95%;
  top: -30px;
  transform: translateX(-50%) translateY(-50%);
  width: 15px;
  height: ${(props) => (props.notifications ? "15px" : "0px")};

  &:before {
    content: "";
    position: relative;
    right: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #ff009a;
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
  @keyframes pulse-ring {
    0% {
      transform: scale(0.33);
    }
    80%,
    100% {
      opacity: 0;
    }
  }
  @keyframes pulse-dot {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }
`;
export const HeaderInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  background-color: #ffffff;
`;
export const HeaderToggle = styled.div`
  color: #19181b;
  cursor: pointer;
  font-size: 1.2rem;
`;

export const ProfileMenu = styled.div`
  display: block;
  position: absolute;
  padding: 20px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f0eef2;
  margin-top: 10px;
  border-radius: 15px;
  z-index: 10;

  .title-role {
    background-color: #603888;
    width: 168px;
    height: 25px;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    color: #ededed;
    text-align: center;
    border-radius: 15px;
  }

  .account-item {
    display: flex;
    margin-left: 30px;
    text-decoration: none;

    .icon-container {
      img {
        height: 100%;
      }

      & + * {
        margin-left: 12px;
      }
    }

    p {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #817393;
    }
  }

  * + a {
    margin-top: 10px;
  }
`;

export const NavContainer = styled.div`
  width: ${(props) => props.latcontain};
  padding: 20px;
  box-sizing: border-box;
  background-color: ${COLORS.primary};
  z-index: 0;
  transition: 0.4s;
`;

export const Navbar = styled.nav`
  height: 100%;
  width: ${(props) => props.nav};
  box-sizing: border-box;
  padding: 0 5px 0 5px;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  border-radius: 20px;
  background: linear-gradient(180deg, #e33aa9 0%, #3b1366 100%);
  scrollbar-width: none;
  display: flex;
`;
export const NavLogo = styled.a`
  font-weight: 600;
  padding: ${(props) => props.padlogo};
  margin-bottom: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #58555e;
`;
export const NavLogoName = styled.span`
  opacity: 0;
  transition: 0.3s;
  &:hover {
    opacity: 1;
  }
`;
export const NavItems = styled.div`
  row-gap: 1.5rem;
  display: grid;
`;

export const NavList = styled.div`
  row-gap: 2.5rem;
`;
export const NavLinks = styled.div`
  margin: ${(props) => props.navlink};
`;
export const NavSubtitle = styled.h3`
  font-size: 0.938rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: #a5a1aa;
`;
export const NavLinkz = styled.div`
  display: flex;
  width: 100%;
  height: ${(props) => props.height};
`;
NavLinkz.defaultProps = {
  height: "34px",
  width: "100%",
};
export const NavLink = styled.a`
  display: flex;
  width: 100%;

  text-decoration: none;
  height: 34px;
  align-items: center;
  color: #58555e;
  border-radius: 18px;
  cursor: pointer;
  background-color: ${(props) => props.bgcolor};
`;

export const NavLink2 = styled.a`
  display: flex;
  width: 100%;

  text-decoration: none;
  height: 34px;
  align-items: center;
  color: #58555e;
  border-radius: 18px;
  cursor: pointer;
  background-color: ${(props) => props.bgcolor};

  &.active {
    border: 1px solid #e33aa9;
  }

  &:hover {
    background-color: #603888;
  }
`;
NavLink2.defaultProps = {
  bgcolor: "",
};

NavLink.defaultProps = {
  bgcolor: "",
};

export const NavName = styled.span`
  font-family: "Avenir Next";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.015em;
  color: #fcfcfc;
`;

export const NavDropdownCollapse = styled.a`
  text-decoration: none;
  position: relative;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  border-left: 1px solid #f0eef2;
  line-height: 16px;
  letter-spacing: -0.015em;
  color: #f0eef2;
  padding-top: 10px;
  padding-left: 26px;
  &::before {
    border-left: 1px solid #e33aa9;
    width: 10px;
    content: "";
    border-radius: 50%;
    height: 10px;
    background-color: white;
    position: absolute;
    left: -4%;
    bottom: 1%;
  }
`;

export const NavDropdown = styled.div`
  overflow: hidden;
  height: ${(props) => props.height};
  transition: 0.4s ease-in-out;
`;

export const NavDropdownContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 33px;
`;
export const ProfileNavLink = styled(NavLink)`
  display: flex;
  margin-left: 30px;
  text-decoration: none;

  .icon-container {
    img {
      height: 100%;
    }

    & + * {
      margin-left: 12px;
    }
  }

  p {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #817393;
  }

  &.profile-link {
    height: auto;
  }
`;
export const NavDropdownItem = styled.a`
  font-size: 0.75rem;
  font-weight: 500;
  z-index: 0;
  height: 40px;
  color: white;
  &:hover {
    height: 40px;
    visibility: visible;
    z-index: 1;
  }
`;
export const NavContain = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
`;
export const ContainerIcons = styled.div`
  display: flex;
  padding: 10px;
  justify-content: space-between;
  .open {
    margin-bottom: 60px;
  }
`;
export const IconsBut = styled.div`
  cursor: pointer;
`;
