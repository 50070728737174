 export const COLORS = {
   magentaOriginal:'#B12D84',
   purpuraOriginal:'#',
   purpuraS2:'#',
   purpuraS3:'#',
   magentaS2:'#',
   purpuraDark:'#',
   grayS5:'#',
   grayS4:'#',
   grayS3:'#',
   grayS2:'#',
   grayS1:'#'
  };

  export const magentaOriginal = '#B12D84';
  export const grayS2 = '#F0EEF2';
  export const grayS4 = '#817393';
  export const grayS5 = '#503D66';