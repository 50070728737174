
function IconClose(props) {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" style={{cursor: 'pointer'}}>
            <rect x="0.5" y="0.5" width="29" height="29" rx="2.5" stroke="#EDEDED" />
            <line x1="7.70711" y1="7" x2="23" y2="22.2929" stroke="#D43594" strokeLinecap="round" strokeLinejoin="round" />
            <line x1="23" y1="7.70711" x2="8.70711" y2="22" stroke="#D43594" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
export default IconClose;