import { InputContainer } from "./styles";
import searchIcon from "../iconsFolder/searchIcon.svg";
import { useEffect, useState } from "react";
import { FilterOption } from "../filterOption";
import { FiltersInput } from "../inputFilter";

export const InputSection = (props) => {
  const [showInput, setShowInput] = useState(false);

  const filtersOptions = props.enabledFilters;

  const showInputFilter = (e) => {
    if (!e.target.closest("#input-filter") && showInput) {
      document.removeEventListener("click", showInputFilter, false);
      setShowInput(false);
    }
  };

  useEffect(() => {
    //props.applyFilters();
    if (showInput) {
      document.addEventListener("click", showInputFilter, false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showInput]);

  return (
    <InputContainer>
      <div
        className="input-container"
        onClick={(e) => {
          e.stopPropagation();
          setShowInput(true);
        }}
      >
        <div className="fake-input-text">
          <img src={searchIcon} alt="search icon" />
          <p>{props.keyword}</p>
        </div>
        <div className="chosen-filters">
          {Object.keys(props.globalFilters)
            ?.reverse()
            ?.map(
              (filterActive, index) =>
                index < 2 && (
                  <FilterOption
                    key={"filter-option-" + index}
                    label={filterActive}
                    optionSelected={true}
                    index={index}
                    globalFilters={props.globalFilters}
                    setGlobalFilters={props.setGlobalFilters}
                    setKeyword={props.setKeyword}
                  />
                )
            )}
          {Object.keys(props.globalFilters).length > 2 && (
            <div className="extra-filters">
              <p>+{Object.keys(props.globalFilters).length - 2}...</p>
            </div>
          )}
        </div>
      </div>
      <div className="products-loaded">
        <p>
          {props.charged} de {props.total} cargados
        </p>
      </div>
      {showInput && (
        <FiltersInput
          filters={props.filters}
          keyWords={props.keyword}
          setKeyword={props.setKeyword}
          filtersOptions={filtersOptions}
          filterInputFunct={props.filterInputFunct}
          globalFilters={props.globalFilters}
          setGlobalFilters={props.setGlobalFilters}
        />
      )}
    </InputContainer>
  );
};
