import { FilterContainer } from "./styles";
import { InputSection } from "./inputSection";
import { OptionsButtons } from "./buttonsSection";
import { useEffect, useState } from "react";

export const FilterInput = (props) => {
  //const [filters, setFilters] = useState({});
  const [keyword, setKeyword] = useState("");
  const [hideButtons] = useState(props.hideButtons);

  const filters = {
    Compania: props.companiesOption,
    Región: props.countryOption,
    Marca: props.brandOption,
    Orden: props.ordersOption,
    Cadena: props.retailersOption,
    Estatus: props.statusOption,
    Prioridad: props.priorityOption,
  };

  const [globalFilters, setGlobalFilters] = useState({});

  useEffect(() => {
    props.filterInputFunct(globalFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilters]);

  return (
    <FilterContainer taskFilter={props.taskFilter}>
      <InputSection
        filters={filters}
        enabledFilters={props.enabledFilters}
        total={props.total}
        charged={props.charged}
        setKeyword={setKeyword}
        keyword={keyword}
        filterInputFunct={props.filterInputFunct}
        globalFilters={globalFilters}
        setGlobalFilters={setGlobalFilters}
      />
      <OptionsButtons
        editProducts={props.editProducts}
        exportProducts={props.exportProducts}
        download={props.download}
        loadingExportables={props.loadingExportables}
        setLoadingExportables={props.setLoadingExportables}
        loadMoreProducts={() => props.loadMoreProducts(globalFilters)}
        setKeyword={setKeyword}
        taskFilter={props.taskFilter}
        hideButtons={hideButtons}
        csvDownload={props.csvDownload}
      />
    </FilterContainer>
  );
};
