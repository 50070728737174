import React, { useEffect, useState } from "react";
import { Container, Content, ServTab, SaveButton } from "./style";
import { CheckboxCustom } from "../customInputs/checkboxCustom";
import saveIcon from "../../assets/IconComponents/multipleEditionIcons/saveIcon.svg";
import axios from "axios";
import Loading from "../../components/loading/index";

export default function ServicesRequest(props) {
  const [isHover, setIsHover] = useState(false);
  const [currentService, setCurrentService] = useState("");
  const [statusList, setStatusList] = useState();
  const [retDatasheet, setDatasheet] = useState();
  const [retDescription, setDescription] = useState();
  const [retImages, setImages] = useState();
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [retailerServices, setRetailerServices] = useState([]);

  const getStatus = async () => {
    setLoading(true);

    const response = await axios.get(
      `${process.env.REACT_APP_RETAILERS_SERVICES}?articleId=${props.articleId}&orderId=${props.orderId}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    const parseData = JSON.parse(response.data.body);
    const { productsList, retailerServices } = parseData.data || {};

    setRetailerServices(retailerServices);
    setStatusList(productsList);
    setDatasheet(productsList?.relRetailerServices?.datasheet);
    setDescription(productsList?.relRetailerServices?.description);
    setImages(productsList?.relRetailerServices?.images);
    setLoading(false);
    setIsHover(true);

    const element = document.querySelector(".close-Container");
    document.addEventListener("click", (event) => {
      if (!element.contains(event.target)) {
        setIsHover(false);
      }
    });
  };

  useEffect(() => {
    const { datasheets, descriptions, images } = props.status;
    if (datasheets && descriptions && images) setCurrentService("DDI");
    else if ((datasheets || descriptions) && images) setCurrentService("DI");
    else if (datasheets && descriptions) setCurrentService("TXT");
    else if (datasheets) setCurrentService("Dat");
    else if (descriptions) setCurrentService("Dsc");
    else if (images) setCurrentService("Imgs");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  const updateServices = async (services, generalStatus) => {
    const { articlesLists, setArticlesLists, rowIndex, orderId, articleId } =
      props;
    const newServices = {
      datasheets: services.datasheet,
      descriptions: services.description,
      images: services.images,
    };
    const status = {
      datasheet_status: generalStatus.datasheet,
      description_status: generalStatus.description,
      images_status: generalStatus.images,
      status: generalStatus.articleStatus,
    };
    if (articlesLists?.productsList?.length > 0) {
      setArticlesLists.setProductsList({
        type: "updateServices",
        services: newServices,
        status,
        index: rowIndex,
      });
    }
    if (articlesLists?.productsListCopy?.length > 0) {
      const index = articlesLists?.productsListCopy.findIndex(
        (f) => f.article.id_article === articleId && f.orderId === orderId
      );
      if (index >= 0) {
        setArticlesLists.setProductsListCopy({
          type: "updateServices",
          services: newServices,
          status,
          index: index,
        });
      }
    }
    if (articlesLists?.productsListAux?.length > 0) {
      const index = articlesLists?.productsListAux.findIndex(
        (f) => f.article.id_article === articleId && f.orderId === orderId
      );
      if (index >= 0) {
        setArticlesLists.setProductsListAux({
          type: "updateServices",
          services: newServices,
          status,
          index: index,
        });
      }
    }
    setUpdate(!update);
  };

  const saveRequest = async () => {
    let seleccion = {
      orderId: props.orderId,
      articleId: props.articleId,
      addService: {
        datasheet: {},
        description: {},
        images: {},
      },
      removeService: {
        datasheet: {},
        description: {},
        images: {},
      },
    };
    const services = ["datasheet", "description", "images"];
    services.forEach((service) => {
      statusList.retailers.forEach(({ id }) => {
        const chkDataSheet = document.getElementById(
          `checkbox-${id}-${service}`
        ).checked;
        const action = chkDataSheet ? "addService" : "removeService";
        seleccion[action][service][id] = chkDataSheet;
      });
    });

    const addDatasheet = Object.keys(seleccion.addService.datasheet).filter(
      (key) => seleccion.addService.datasheet[key]
    );
    const removeDatasheet = Object.keys(
      seleccion.removeService.datasheet
    ).filter((key) => !seleccion.removeService.datasheet[key]);

    seleccion.addService.datasheet = addDatasheet;
    seleccion.removeService.datasheet = removeDatasheet;

    const addDescription = Object.keys(seleccion.addService.description).filter(
      (key) => seleccion.addService.description[key]
    );
    const removeDescription = Object.keys(
      seleccion.removeService.description
    ).filter((key) => !seleccion.removeService.description[key]);

    seleccion.addService.description = addDescription;
    seleccion.removeService.description = removeDescription;

    const addImages = Object.keys(seleccion.addService.images).filter(
      (key) => seleccion.addService.images[key]
    );
    const removeImages = Object.keys(seleccion.removeService.images).filter(
      (key) => !seleccion.removeService.images[key]
    );
    seleccion.addService.images = addImages;
    seleccion.removeService.images = removeImages;

    try {
      const response = await axios.put(
        process.env.REACT_APP_RETAILERS_SERVICES,
        seleccion,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      );
      const succesResponse = {
        status: true,
        message: "Servicios guardados correctamente",
        detail: "",
        img: true,
      };
      const errorResponse = {
        status: false,
        message: "Error al guardar los servicios",
        detail: "Verifica los servicios seleccionados",
        img: false,
      };

      if (response.data.statusCode === 200) {
        const service = { datasheet: 0, description: 0, images: 0 };
        Object.keys(seleccion.addService).forEach((key) => {
          if (seleccion.addService[key].length > 0) service[key] = 1;
        });
        updateServices(service, JSON.parse(response.data.body).lessWeight);
      }
      props.setShowModal && props.setShowModal(true);
      props.setModalData &&
        props.setModalData(
          response.data.statusCode === 200 ? succesResponse : errorResponse
        );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container onClick={() => getStatus()} key={props.key}>
      <Content cont={currentService}>
        <div id={`assign-services`}>{currentService}</div>
      </Content>

      {isHover && (
        <ServTab
          onClick={(evt) => {
            evt.stopPropagation();
          }}
          className="close-Container "
        >
          <div>
            <span>Contenido de solicitud</span>
            <p>{props.upc && props.upc}</p>
          </div>
          <div className="flex title">
            <p>Cadena</p>
            <p>Datos</p>
            <p>Desc.</p>
            <p>Imgs.</p>
          </div>
          {loading ? (
            <Loading />
          ) : (
            statusList.retailers.map((retailer) => (
              <div className="flex">
                <p>{retailer.name}</p>
                <p>
                  {retailerServices?.datasheets?.some(
                    (srv) => srv.id_retailer === retailer.id
                  ) ? (
                    <CheckboxCustom
                      type="checkbox"
                      id={`checkbox-${retailer.id}-datasheet`}
                      htmlFor={`checkbox-${retailer.id}-datasheet`}
                      defaultChecked={retDatasheet?.find(
                        (dat) => dat === retailer.id
                      )}
                    />
                  ) : (
                    <input
                      id={`checkbox-${retailer.id}-datasheet`}
                      htmlFor={`checkbox-${retailer.id}-datasheet`}
                      type="checkbox"
                      disabled
                    />
                  )}
                </p>
                <p>
                  {retailerServices?.descriptions?.some(
                    (srv) => srv.id_retailer === retailer.id
                  ) ? (
                    <CheckboxCustom
                      type="checkbox"
                      id={`checkbox-${retailer.id}-description`}
                      htmlFor={`checkbox-${retailer.id}-description`}
                      defaultChecked={retDescription?.find(
                        (des) => des === retailer.id
                      )}
                    />
                  ) : (
                    <input
                      id={`checkbox-${retailer.id}-description`}
                      htmlFor={`checkbox-${retailer.id}-description`}
                      type="checkbox"
                      disabled
                    />
                  )}
                </p>
                <p>
                  {retailerServices?.images?.some(
                    (srv) => srv.id_retailer === retailer.id
                  ) ? (
                    <CheckboxCustom
                      type="checkbox"
                      id={`checkbox-${retailer.id}-images`}
                      htmlFor={`checkbox-${retailer.id}-images`}
                      defaultChecked={retImages?.find(
                        (img) => img === retailer.id
                      )}
                    />
                  ) : (
                    <input
                      id={`checkbox-${retailer.id}-images`}
                      htmlFor={`checkbox-${retailer.id}-images`}
                      type="checkbox"
                      disabled
                    />
                  )}
                </p>
              </div>
            ))
          )}
          <SaveButton
            onClick={() => {
              saveRequest();
            }}
          >
            <img src={saveIcon} alt="Guardar" />
          </SaveButton>
        </ServTab>
      )}
    </Container>
  );
}
