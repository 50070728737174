import styled from 'styled-components'

export const ContainerPrincipal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
`
export const ContainerText = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #503d66;
`

export const Img = styled.img`
  width: 40%;
  margin-top:40px;
  margin-bottom: 20px;
`
