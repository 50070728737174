import styled from "styled-components";

export const Button = styled.div`
  background: #e33aa9;
  border-radius: 40px;
  color: white;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;

  p {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.015em;

    & + img {
      margin-left: 5px;
    }
  }
`;

export const RowStatus2 = styled.div`
  background-color: ${(props) => props.bg};
  font-family: Lato;
  padding: 0 12px;
  height: 20px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height, or 138% */

  letter-spacing: -0.015em;

  color: #ffffff;
  border-radius: 3px;
`;

export const ContainerPrincipal = styled.button`
  border: none;
  display: block;
  width: 592px;
  height: calc(100vh - 100px);
  position: fixed;
  right: -592px;
  top: 100px;
  overflow-y: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #fafafa;
  padding: 14px 12px;
  box-sizing: border-box;
  z-index: 9;
  transition: right 0.5s;

  * {
    box-sizing: border-box;
  }
  .containerEstatus {
    display: flex;
    width: 200px;

    .title-progress {
      & + * {
        margin-left: 11px;
      }
    }
  }

  .containerImg {
    width: 240px;
    height: 235px;
    object-fit: scale-down;
  }

  &.active-modal {
    right: 0;
  }
`;
export const ContainerIcon = styled.div`
  border: 1px solid #f0eef2;
  box-sizing: border-box;
  border-radius: 50%;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
`;
export const Progressdiv = styled.div`
  width: 240px;
  height: 23px;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.015em;
  color: #817393;
  border: 1px solid ${(props) => props.color};
  box-sizing: border-box;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 10px;

  & + * {
    margin-top: 10px;
  }
`;

export const AvatarContainer = styled.div`
  position: relative;

  .avatars {
    display: flex;
  }

  & + * {
    margin-top: 10px;
  }
`;

export const HeaderTitle = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #b12d84;
  position: relative;

  &:before {
    content: "";
    background-image: url(${(props) => props.redFlag});
    background-repeat: no-repeat;
    position: absolute;
    width: 14px;
    height: 14px;
    left: calc(100% + 10px);
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const HeaderImg = styled.img`
  max-width: 100%;
  height: auto;
  width: 35px;
  height: 35px;
  border-radius: 50%;
`;
export const HeaderLogo = styled.a`
  color: #19181b;
  font-weight: 500;
  display: none;
  text-decoration: none;
`;
export const HeaderSearch = styled.div`
  display: flex;
  padding: 0.4rem 0.75rem;
  background-color: #f4f0fa;
  border-radius: 0.25rem;
`;

export const HeaderInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  background-color: #f4f0fa;

  &::placeholder {
    font-family: "Poppins", sans-serif;
    color: #58555e;
  }
`;
export const HeaderToggle = styled.div`
  color: #19181b;
  cursor: pointer;
  font-size: 1.2rem;
`;

export const NavContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0%;
  height: 93vh;
  width: 200px;
  padding: 1rem 1rem 0;
  background-color: #ffffff;
  z-index: 0;
  transition: 0.4s;
`;

export const Navbar = styled.nav`
  height: 100%;
  display: block;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 3rem;
  overflow: auto;
  border-radius: 20px;
  background-color: #603888;
  scrollbar-width: none;
`;

export const NavLogo = styled.a`
  font-weight: 600;
  padding: 2rem 1rem;
  margin-bottom: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #58555e;
`;

export const NumberProduct = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #817393;

  &.total-price {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 40px;

    & + * {
      margin-left: 10px;
    }
  }
`;
export const NavItems = styled.div`
  row-gap: 1.5rem;
  display: grid;
`;

export const NavList = styled.div`
  row-gap: 2.5rem;
`;
export const NavLinks = styled.div`
  margin: 0 15px 0 26px;
`;
export const NavSubtitle = styled.h3`
  font-size: 0.938rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: #a5a1aa;
`;
export const NavLink = styled.a`
  display: flex;
  width: 190px;
  height: 34px;
  align-items: center;
  color: #58555e;
  &:hover {
    border-radius: 18px;
    background-color: #e33aa9;
  }
`;
export const NavName = styled.span`
  font-family: "Raleway";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.015em;
  color: #fcfcfc;
`;

export const NavDropdownCollapse = styled.div`
  background-color: #a5a1aa;
  border-radius: 0.25rem;
  margin-top: 1rem;
`;

export const NavDropdown = styled.div`
  overflow: hidden;
  max-height: 21px;
  transition: 0.4s ease-in-out;
  &:hover {
    max-height: 100rem;
  }
`;

export const NavDropdownContent = styled.div`
  display: grid;
  row-gap: 0.5rem;
  padding: 0.75rem 2.5rem 0.75rem 1.8rem;
`;

export const NavDropdownItem = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  color: #58555e;
`;
export const Tables = styled.table`
  border: 1px solid #f0eef2;
  width: 241px;
  box-sizing: border-box;
  border-radius: 15px;

  display: block;
  max-height: 240px;
  overflow: auto;
  flex: 0%;

  ::-webkit-scrollbar {
    width: 5px;
    position: absolute;
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 100px;
  }

  thead {
    display: block;

    tr {
      display: flex;
      justify-content: space-between;
    }
  }

  tbody {
    display: flex;
    flex-wrap: wrap;
    max-width: 300px;

    tr {
      display: flex;
      justify-content: space-between;

      td {
        white-space: normal;
      }
    }
  }

  & + * {
    margin-left: 10px;
  }
`;
export const TablesHead = styled.thead`
  width: 100%;
  border: none;
  border-bottom: 1px solid #f0eef2;
`;
export const TablesHeadRow = styled.tr`
  width: 100%;
  border: none;
  min-height: 21px;
  padding: 0 5px;
`;
export const TitleImages = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.015em;
  color: #817393;

  & + * {
    margin-top: 5px;
  }
`;
export const TablesRowH = styled.th`
  border: none;
  padding: 3px 10px;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: ${(props) => props.align};
  height: 23px;
  letter-spacing: -0.015em;
  box-sizing: border-box;
  color: #817393;
`;
export const TablesRowD = styled.td`
  border: none;
  padding-bottom: 0;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 20px;
  text-align: ${(props) => props.align};
  letter-spacing: -0.015em;
  color: #817393;
  justify-content: ${(props) => props.align};

  &:first-child {
    min-width: 60%;
  }
`;
export const Container = styled.div`
  display: flex;
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  justify-content: ${(props) => props.justifycontent};
  flex-direction: ${(props) => props.flexdirection};
  > a {
    cursor: pointer;
  }

  &.img {
    width: 240px;
    height: 235px;
  }

  &.title-container {
    justify-content: initial;
  }

  &.progressbar-container {
    justify-content: initial;

    & + * {
      margin-top: 8px;
    }
  }

  &.category-container {
    & + * {
      margin-top: 9px;
    }
  }

  &.image-container {
    border-top: 1px solid #f0eef2;
    padding-top: 5px;
  }

  &.image-container-2 {
    display: block;
    width: initial;
    overflow: hidden;
    min-width: 240px;

    img {
      max-width: 100%;
    }

    & + * {
      margin-left: 10px;
    }
  }

  &.progress-percent {
    display: block;

    & + * {
      margin-left: 14px;
    }
  }

  &.avatar-container {
    display: flex;
    width: 69px;
    min-width: 69px;
    position: relative;
    justify-content: space-between;
  }

  &.comments-container {
    display: block;
    border-top: 1px solid #f0eef2;
    border-bottom: 1px solid #f0eef2;
    padding: 10px 0;
  }

  &.comments-avatar {
    justify-content: initial;
  }

  &.footer {
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #f0eef2;

    .title-footer {
      display: block;
      width: 200px;
    }
  }

  &.summary-container {
    width: fit-content;
    display: block;
  }

  &.total-summary {
    display: block;
    width: auto;
  }

  &.button-container {
    justify-content: flex-end;
  }

  & + .button-container {
    margin-top: 10px;
  }

  &.resume-product {
    min-width: 328px;
  }

  &.product-netwoks {
    justify-content: initial;
    flex-direction: column;

    .header {
      display: flex;
      font-family: Avenir Next;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      text-align: left;
      justify-content: space-between;
      align-items: center;

      p {
        color: #503d66;
      }

      .rounded-images {
        display: flex;
        align-items: center;
        overflow: hidden;

        img {
          width: 20px;
          height: 20px;
          object-fit: cover;
          border-radius: 50%;
          border: 1px solid #fff;

          & + * {
            margin-left: -5px;
            z-index: -1;
          }
        }

        & + * {
          margin-left: 5px;
        }
      }

      & + * {
        margin-top: 6px;
      }
    }

    .desc {
      width: 222px;
    }

    .stat {
      width: 28px;

      & + * {
        margin-left: 8px;
      }
    }

    .asig {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      line-height: 20px;

      img {
        margin-right: 5px;
        width: 20px;
        height: 20px;
      }
    }

    .product-description-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .percentages-container {
        display: flex;

        & + * {
          margin-top: 10px;
        }
      }

      .desc {
        display: flex;
        justify-content: space-between;
        font-family: Avenir Next;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 23px;
        color: #969696;
        height: 23px;
        border: 1px solid ${(props) => (props.color ? props.color : "darkgray")};
        border-radius: 20px;
        padding: 0 5px;
        width: 270px;

        & + * {
          margin-left: 14px;
        }
      }

      .stat {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        display: flex;
        align-items: center;
        min-width: 27px;

        p {
          color: #fff;
          background-color: ${(props) =>
            props.backgroundColor ? props.backgroundColor : "darkgray"};
          border-radius: 3px;
          text-align: center;
          width: 100%;
        }
      }

      .asig {
        img {
          display: block;
          margin: auto;
        }
      }

      & + * {
        margin-top: 10px;
      }
    }
  }

  .left-table {
    & + * {
      margin-left: 10px;
    }
  }

  .right-table {
    flex: 0%;

    > div {
      padding: 0 5px;
    }

    table {
      width: 100%;
    }
  }

  &.table {
    width: 100%;
    overflow-y: auto;
  }
`;

export const Feature = styled.div`
  display: flex;
  justify-content: space-between;
  height: 25px;
  font-family: Avenir Next;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  border: 1px solid #d4d1d7;
  align-items: center;
  border-radius: 7px;
  padding: 0 5px;

  .bold-text {
    font-weight: 600;
    color: #503d66;
  }

  .normal-text {
    font-weight: normal;
    color: #817393;
  }

  & + * {
    margin-top: 5px;
  }

  & + .img-title-container {
    margin-top: 10px;
  }
`;

export const ImgCarrousel = styled.div`
  display: flex;

  img {
    display: block;
    width: 25%;
    cursor: pointer;
    object-fit: cover;
  }
`;

export const ImageTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 240px;

  .asig-container {
    display: flex;
    align-items: center;
    margin: 0;

    .rounded-images {
      display: flex;
      align-items: center;
      overflow: hidden;

      img {
        width: 20px;
        height: 20px;
        object-fit: cover;
        border-radius: 50%;
        border: 1px solid #fff;

        & + * {
          margin-left: -5px;
          z-index: -1;
        }
      }

      & + * {
        margin-left: 5px;
      }
    }

    .asig-text {
      font-family: Avenir Next;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #969696;
    }
  }

  &.descriptions {
    min-width: 270px;
  }

  & + * {
    margin-top: 6px;
  }

  & + .stat {
    margin: 0;
  }
`;

Container.defaultProps = {
  width: "100%",
  padding: "0",
  flexdirection: "row",
  justifycontent: "space-around",
};

export const Title = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.015em;
  color: #817393;
  text-align: left;

  cursor: text;
  user-select: text;

  &.date-container {
    & + * {
      margin-top: 10px;
    }
  }

  &.footer-title {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 12px;
    color: #817393;
  }

  &.title-summary {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 11px;
  }
`;
export const TitleDate = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
  /* or 86% */

  text-align: center;
  letter-spacing: -0.015em;

  /* Gray S4 */

  color: #817393;
`;
export const ContainerLeft = styled.div`
  > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  &.categiry-container {
    width: 191px;
  }

  & + * {
    margin-left: 13px;
  }
`;
export const TitleContainCat = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #817393;
`;

export const SubtitleContainCat = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
  color: #817393;
`;

export const TitleCoantin = styled.div`
  background: #f0eef2;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 5px 5px 5px 10px;
  line-height: 18px;
  border-radius: 7px;
  letter-spacing: -0.015em;
  text-align: left;
  position: relative;

  &.container-right {
    flex: 0%;
  }
`;
export const TitleFooter = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 36px;
  letter-spacing: -0.015em;
  color: #503d66;
  text-align: left;
`;

export const ProductIndicator = styled(TitleFooter)`
  width: 100%;
  padding: 1%;
`;

export const TitleComents = styled.div`
  background: #f0eef2;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
  box-sizing: border-box;
  padding: 10px 21px 10px 10px;
  letter-spacing: -0.015em;
  color: #817393;
  border-radius: 7px;
  width: 450px;
`;
export const Avatar = styled.img`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #fff;

  &.avatar-img {
    display: block;
    margin: auto;
  }

  &.avatar-comment {
    width: 40px;
    height: 40px;
  }

  & + * {
    margin-left: -5px;
  }
`;
export const TitleCoantinVersion = styled.div`
  background: #f0eef2;
  min-height: 20px;
  width: 93px;
  display: flex;
  padding: 2px 5px;
  justify-content: space-around;
  border-radius: 15px;
  color: #817393;

  /* identical to box height, or 150% */
  /* Gray S4 */
`;
export const TitleVersion = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  letter-spacing: -0.015em;
`;
export const Subtitle = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  text-align: center;
  letter-spacing: -0.015em;

  /* Gray S4 */

  color: #817393;

  &.upc-container {
    & + * {
      margin-left: 27px;
    }
  }

  &.title-progress {
    & + * {
      margin-left: 8px;
    }
  }
`;

export const rowTable = styled.tr`
  background-color: red;
  &hover {
    cursor: pointer;
  }
`;

export const NewComment = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;

  form {
    display: flex;
    align-items: center;

    .text-button-container {
      display: flex;
      align-items: center;
      background: #f0eef2;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;

      .textarea-container {
        padding: 7px 10px;
        background: #f0eef2;
        border-radius: 4px;

        textarea {
          padding: 0;
          border: none;
          background: #f0eef2;
          width: 272px;
          resize: none;

          &::placeholder {
            font-family: Avenir Next;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 13px;
            color: #817393;
          }
        }
      }

      .button-container {
        height: 44px;
        width: 88px;

        button {
          height: 100%;
          width: 100%;
          border: 1px solid #603888;
          background-color: #fafafa;
          font-family: Raleway;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: #603888;
          border-radius: 30px;
        }
      }

      & + * {
        margin-left: 13px;
      }
    }
  }
  .button-validate {
    button {
      border: none;
      border-radius: 30px;
      background: #71de56;
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      width: 118px;
      height: 44px;
      color: #ffffff;
    }
  }
`;

export const AssignToStyles = styled.div`
  display: ${(props) => (props.showAssign ? "block" : "none")};
  position: absolute;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  width: 180px;
  right: 0;
  top: 0;

  .search-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 9px 8px 13px;
    background-color: #f0eef2;
    border: 1px solid #d4d1d7;
    border-radius: 6px;

    input {
      width: 90px;
      height: 21px;
      color: #281f33;
      border: none;
      outline: none;
      background-color: #d4d1d7;
    }

    .close-button {
      width: 14px;
      height: 100%;
      border: none;
      padding: 0;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }

    & + * {
      margin-top: 3px;
    }
  }

  .search-results {
    padding: 8px 13px;
    background-color: #f0eef2;
    border: 1px solid #d4d1d7;
    border-radius: 6px;

    ul {
      li {
        .result-item {
          display: flex;

          .result-img {
            & + * {
              margin-left: 13px;
            }
          }
        }

        & + * {
          margin-top: 10px;
        }
      }
    }
  }

  .circle-image,
  .result-img {
    width: 25px;
    height: 25px;

    img {
      width: 100%;
      height: auto;
    }
  }
`;

export const State = styled.div`
  color: white;
  min-width: 30px;
  height: 20px;
  border-radius: 3px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "darkgray"};

  p {
    text-align: center;
    line-height: 20px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
  }

  & + * {
    margin-left: 5px;
  }
`;
