import moment from "moment";

export const getOrdersFilterOptions = (completeOrderList) => {
  try {
    let uniqueOrderIds = [
      ...new Set(completeOrderList.map((orderItem) => orderItem.id_order)),
    ];
    uniqueOrderIds = uniqueOrderIds.map((order) => ({
      name: order,
      value: order,
    }));

    return uniqueOrderIds;
  } catch (err) {
    console.log(err, "unable to set unique orders for filter functionality");
    return [];
  }
};

export const getCompaniesFilterOptions = (completeOrderList) => {
  try {
    let uniqueProviders = [
      ...new Set(completeOrderList.map((orderItem) => orderItem.company_name)),
    ];
    uniqueProviders = uniqueProviders.map((provider) => ({
      name: provider,
      value: provider,
    }));

    return uniqueProviders;
  } catch (err) {
    console.log(err, "unable to set unique orders for filter functionality");
    return [];
  }
};

export const filterOrders = (filters, list) => {
  try {
    filters?.forEach((filterParams) => {
      if (filterParams.filter === "date") {
        list = filterByDates(list, filterParams.values);
      } else {
        const filterKey =
          filterParams.filter === "orderId" ? "id_order" : filterParams.filter;
        list = list.filter((order) =>
          filterParams.values.includes(order[filterKey])
        );
      }
    });
    return list;
  } catch (err) {
    console.log(err, "Unable to filter orders list, please try again");
    return list;
  }
};

const filterByDates = (list, dates) => {
  const startDate = new Date(dates.startDate).getTime();
  const endDate = new Date(dates.endDate).getTime();
  return list.filter(
    (order) =>
      startDate <= new Date(order.timestamp).getTime() &&
      new Date(order.timestamp) <= endDate
  );
};

export const statusOptions = [
  { name: "Validando", value: "R" },
  { name: "En proceso", value: "CA" },
  { name: "Rechazada", value: "REJECTED" },
  { name: "Aprobada", value: "ACA" },
];

export const enabledFilters = [
  { name: "Compania", active: false },
  { name: "Orden", active: false },
  { name: "Estatus", active: false },
];
