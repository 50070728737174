import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

// components
import Select from "../customInputs/Select";
import GenericModal from "../GenericModal/index";
import Loading from "../../components/loading/index";
import { getProfilePicture } from "../../_utils/helper";
import ReactImageFallback from "react-image-fallback";

import {
  MainContainer,
  Header,
  TitleIcon,
  BasicData,
  Icon,
  InputContainer,
  ErrorLabel,
} from "./styles";

// SVG assets
import addPeople from "../../assets/IconComponents/addPeople.svg";
import trash from "../../assets/IconComponents/trash.svg";
import imgDefault from "../../assets/defaultImages/defaultProfile.png";
import face from "../../assets/IconComponents/face.svg";

export default function AddPeople() {
  const [listUsers, setListUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rolesList] = useState([
    { id_role: 1, name: "Administrador" },
    { id_role: 2, name: "KAM" },
    { id_role: 3, name: "Recepcionista" },
    { id_role: 4, name: "Facilitador textos" },
    { id_role: 5, name: "Facilitador imagenes" },
    { id_role: 6, name: "Auditor QA" },
    { id_role: 7, name: "Especialista textos" },
    { id_role: 8, name: "Especialista edición" },
    { id_role: 9, name: "Entregas" },
  ]);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [permissions, setPermissions] = useState({
    role: 1,
    editar: true,
    registrarFechaEntrega: true,
    marcarOdtPagadas: true,
    recepcionProductos: true,
    asignarProductos: true,
    consultarTodos: true,
    exportarProductos: true,
    editarDatos: true,
    editarDescripciones: true,
    editarFotos: true,
    editarImagenes: true,
    QaDatosDesc: true,
    QaFotos: true,
  });
  const [modalData, setModalData] = useState({});

  const getUsers = async () => {
    setLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_USER_ENDPOINT}?isColaborators=1`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    const collaborators = JSON.parse(response.data.body).data[2];
    setListUsers(collaborators);
    sessionStorage.setItem("collaborators", JSON.stringify(collaborators));
    setLoading(false);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const onSubmit = useCallback(
    async (evt) => {
      evt.preventDefault();
      const emailColab = document.querySelector("#emailInput").value;
      let newPermissions = permissions;
      newPermissions.emailCollaborator = emailColab;
      if (emailColab !== "") {
        setEmptyEmail(false);
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_USER_ENDPOINT}`,
            newPermissions,
            {
              headers: {
                Authorization: sessionStorage.getItem("jwt"),
              },
            }
          );
          if (res.data.statusCode === 200) {
            setModalData({
              img: face,
              message: `Se creó el usuario ${emailColab} correctamente`,
              detail: "Se le envió un correo con una contraseña temporal",
              button2: { name: "Aceptar", action: () => setShow(false) },
            });
            getUsers();
            document.querySelector("#emailInput").value = "";
          } else {
            setModalData({
              message: `Ocurrió un error al crear la cuenta ${emailColab}`,
              detail: "Asegúrese que no se encuentra registrado actualmente",
              button2: { name: "Aceptar", action: () => setShow(false) },
            });
          }
          setShow(true);
        } catch (err) {
          console.log(err);
        }
      } else {
        setEmptyEmail(true);
      }
    },
    [permissions]
  );

  const deleteCollaborator = async (idUser) => {
    try {
      await axios({
        method: "delete",
        url: process.env.REACT_APP_USER_ENDPOINT,
        data: { idUser },
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      });
      getUsers();
      setModalData({
        img: face,
        message: `Se eliminó correctamente`,
        button2: { name: "Aceptar", action: () => setShow(false) },
      });
      setShow(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePermissions = (value) => {
    let newPermissions = {};
    newPermissions.role = value.id_role;
    switch (value.id_role) {
      case 1:
        // Admin
        newPermissions.registrarFechaEntrega = true;
        newPermissions.marcarOdtPagadas = true;
        newPermissions.recepcionProductos = true;
        newPermissions.asignarProductos = true;
        newPermissions.consultarTodos = true;
        newPermissions.exportarProductos = true;
        newPermissions.editarDatos = true;
        newPermissions.editarDescripciones = true;
        newPermissions.editarFotos = true;
        newPermissions.editarImagenes = true;
        newPermissions.QaDatosDesc = true;
        newPermissions.QaFotos = true;
        break;

      case 2:
        //KAM
        newPermissions.registrarFechaEntrega = true;
        newPermissions.marcarOdtPagadas = true;
        newPermissions.consultarTodos = true;
        newPermissions.exportarProductos = true;
        break;

      case 3:
        //Recepcionista
        newPermissions.recepcionProductos = true;
        newPermissions.asignarProductos = true;
        newPermissions.consultarTodos = true;
        break;

      case 4:
        //Facilitador textos
        newPermissions.asignarProductos = true;
        newPermissions.consultarTodos = true;
        newPermissions.editarDatos = true;
        newPermissions.editarDescripciones = true;
        newPermissions.QaDatosDesc = true;
        break;

      case 5:
        //Facilitador imagenes
        newPermissions.consultarTodos = true;
        newPermissions.editarFotos = true;
        newPermissions.editarImagenes = true;
        newPermissions.QaFotos = true;
        break;

      case 6:
        //Auditor QA
        newPermissions.consultarTodos = true;
        newPermissions.editarDatos = true;
        newPermissions.editarDescripciones = true;
        newPermissions.QaDatosDesc = true;
        newPermissions.editarFotos = true;
        newPermissions.editarImagenes = true;
        newPermissions.QaFotos = true;
        break;

      case 7:
        //Especialista textos
        newPermissions.consultarTodos = true;
        newPermissions.editarDatos = true;
        newPermissions.editarDescripciones = true;
        break;

      case 8:
        //Especialista edicion
        newPermissions.consultarTodos = true;
        newPermissions.editarFotos = true;
        newPermissions.editarImagenes = true;
        break;

      case 9:
        //Entregas
        newPermissions.consultarTodos = true;
        newPermissions.exportarProductos = true;
        break;

      case 10:
        // PERMISOS SE DEFINEN INDIVIDUALMENTE EL CHECKEAR LAS CHECKBOXES (handleCheckboxes)
        break;

      default:
        break;
    }
    setPermissions(newPermissions);
  };

  const updateRol = async (rol, user, i) => {
    const res = await axios.put(
      process.env.REACT_APP_USER_ROL,
      { rol, user: user.id_user },
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    if (res.data.statusCode === 200) {
      const temp = listUsers.slice();
      temp[i].id_role = rol;
      setListUsers(temp);
      setModalData({
        img: face,
        message: `Se actualizó el rol de ${user.name} correctamente`,
        button2: { name: "Aceptar", action: () => setShow(false) },
      });
      setShow(true);
    }
  };

  return (
    <MainContainer>
      {show && <GenericModal close={() => setShow(false)} {...modalData} />}
      <Header>
        <div>
          <TitleIcon>
            <img src={addPeople} alt="Section icon" />
          </TitleIcon>
          <p>Agregar personas</p>
        </div>
      </Header>

      <BasicData>
        <div className="column">
          <p>
            Agrega a personas a tu empresa, envía la invitación por correo
            electrónico y asigna los beneficios.
          </p>
          <div className="row space">
            <div style={{ width: "100%", display: "flex" }}>
              <InputContainer>
                <input
                  id="emailInput"
                  type="email"
                  placeholder="Ingresa el correo electrónico"
                />
              </InputContainer>
              {rolesList !== 0 && (
                <Select
                  id="selectInput"
                  list={rolesList}
                  onChange={(value) => {
                    handlePermissions(value);
                  }}
                  left
                />
              )}
            </div>
            <button onClick={(evt) => onSubmit(evt)}>Enviar</button>
          </div>
          {emptyEmail && (
            <ErrorLabel>Ingrese el correo de la persona</ErrorLabel>
          )}
        </div>
      </BasicData>

      <BasicData>
        <div className="row header">
          <p className="col">Perfil</p>
          <p className="col text_center">Nombre</p>
          <p className="col text_center">Puesto en la empresa</p>
          <p className="col">Correo</p>
          <p className="col"></p>
        </div>
        {loading ? (
          <Loading />
        ) : (
          rolesList !== 0 &&
          listUsers.length > 0 &&
          listUsers.map((e, i) => (
            <div className="row margin_row" key={i}>
              <div className="col">
                <Select
                  id={e.id_user}
                  defaultValue={
                    rolesList.find((role) => role.id_role === e.id_role)
                      ?.name || "Seleccionar"
                  }
                  list={rolesList}
                  onChange={(value) => {
                    updateRol(value.id_role, e, i);
                  }}
                  left
                />
              </div>
              <div className="col">
                <Icon>
                  <ReactImageFallback
                    src={getProfilePicture(e.id_user, 40, 40)}
                    fallbackImage={imgDefault}
                    className="img"
                  />
                </Icon>
                <p>{e.name ? `${e.name} ${e.last_name}` : "---"}</p>
              </div>
              <div className="col1">
                <p>{e.position ? e.position : "---"}</p>
              </div>
              <div className="col">
                <p>{e.email ? e.email : "---"}</p>
              </div>
              <Icon>
                <img
                  src={trash}
                  alt="Eliminar"
                  onClick={() => {
                    setModalData({
                      message: `¿Estás seguro de que quieres eliminar a ${e.name}?`,
                      button1: {
                        name: "Cancelar",
                        action: () => setShow(false),
                      },
                      button2: {
                        name: "Confirmar",
                        action: () => {
                          deleteCollaborator(e.id_user);
                          setShow(false);
                        },
                      },
                    });
                    setShow(true);
                  }}
                />
              </Icon>
            </div>
          ))
        )}
      </BasicData>
    </MainContainer>
  );
}
