export const enabledFilters = [
  { name: "Región", active: false },
  //{ name: "Cadena", active: false },
  { name: "Compania", active: false },
  { name: "Marca", active: false },
  { name: "Orden", active: false },
  { name: "Estatus", active: false },
  { name: "Prioridad", active: false },
];

export const filterBySections = (productList, userRole, values) => {
  const userIsTextFacilitator = userRole === 4;
  const userIsImagesFacilitator = userRole === 5;

  values.forEach((status) => {
    if (status === "IE") {
      // Filter by assigned sections status
      if (userIsTextFacilitator)
        productList = productList.filter((product) =>
          values.includes(
            product.datasheet_status || product.description_status
          )
        );
      else if (userIsImagesFacilitator)
        productList = productList.filter((product) =>
          values.includes(product.images_status)
        );
    } else {
      // Filter by general status
      productList = productList.filter((product) =>
        values.includes(product.status)
      );
    }
  });
  return productList;
};
