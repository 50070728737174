import React, { useState, useEffect, useCallback, useReducer } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { enabledFilters } from "./filtersOptions";
import GenericModal from "../../components/GenericModal";
import TableTasks from "../Tasks/Table";
import { getAllRetailers } from "../../_utils/data";
import { VirtualizedContainer } from "../Tasks/styles";
// Styled components
import { MainContainer, Title, Title2, SubTitle, Container } from "./styles.js";
import { FilterInput } from "../../components/filterInput/index.js";
import { runAssignations } from "../../_utils/products-tasks/userAssignationsFunctions.js";
//SVGs
import sucessModal from "../../assets/IconComponents/downLoadModalIcons/downloadSucces.svg";
import errorModal from "../../assets/IconComponents/downLoadModalIcons/downloadFailed.svg";
import { fetchUsers } from "../../_utils/data";
import { defineFileFilters } from "./utils/productsUtils";
// Components
import ContentohExportModal from "../../components/modalsExport/index";
import { getMetricsProducts } from "../../_utils/helper";
import { getOrderedServices } from "../../_utils/data";
import ProductDetail from "./productDetail/index";

const reducerProducts = (state, action) => {
  const temp = state.slice();
  switch (action.type) {
    case "init":
      return action.value;
    case "addMore":
      return [...temp, ...action.value];
    case "selectOne":
      return temp.map((product) => {
        if(product.article.id_article !== action.id_article || product.orderId !== action.id_order){
          return product;
        }
        return { ...product, checked: action.checked }
      });  
    case "selectAll":
      return temp.map((product) => ({ ...product, checked: action.checked }));
    case "updateServices":
      temp[action.index].services = action.services;
      temp[action.index].datasheet_status = action.status?.datasheet_status;
      temp[action.index].description_status = action.status?.description_status;
      temp[action.index].images_status = action.status?.images_status;
      temp[action.index].status = action.status?.status;
      return temp;
    default:
      return state;
  }
};

// Functions
const TaskList = (props) => {
  const [checkboxCounter, setCheckboxCounter] = useState(0);
  const [showContentohExportModal, setShowContentohExportModal] =
    useState(false);
  const [indexSelected, setIndexSelected] = useState(null);
  const [productsList, setProductsList] = useReducer(reducerProducts, []);
  const [productsListCopy, setProductsListCopy] = useReducer(
    reducerProducts,
    []
  );
  const [sidebool, setSidebool] = useState(false);
  const [productServicesPrices] = useState();
  const [productDetail, setProductDetail] = useState(null);
  const [productsListAux, setProductsListAux] = useReducer(reducerProducts, []);
  const [indexProducts, setIndexProducts] = useState(100);
  const [firstFilter, setFirstFilter] = useState(null);
  const [firstFilterExecuted, setFirstFilterExecuted] = useState(false);
  const [productsSelected, setProductsSelected] = useState([]);
  const [redirectMultipleEdition, setRedirectMultipleEdition] = useState(false);
  const statusOption = [
    { name: "Por Asignar", value: "'PA'", active: false },
    { name: "Asignado", value: "'AS'", active: false },
    { name: "Capturando", value: "'CA'", active: false },
    { name: "QA Coordinador", value: "'IE'", active: false },
    { name: "Aprobado Coordinador", value: "'AC'", active: false },
    { name: "Rechazado Coordinador", value: "'RC'", active: false },
    { name: "Aprobado Auditor", value: "'AA'", active: false },
    { name: "Rechazado Auditor", value: "'RA'", active: false },
    { name: "Aprobado Proveedor", value: "'AP'", active: false },
    { name: "Rechazado Proveedor", value: "'RP'", active: false },
    { name: "Aprobado Cadena", value: "'ACA'", active: false },
    { name: "Rechazado Cadena", value: "'RCA'", active: false },
  ];
  const priorityOption = [
    { name: "Alta", value: "'high'", active: false },
    { name: "Media", value: "'medium'", active: false },
    { name: "Baja", value: "'low'", active: false },
    { name: "Sin asignar", value: "'none'", active: false },
  ];
  const [userGroups, setUserGroups] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [retailersOption, setRetailersOption] = useState([]);
  const [countryOption, setCountryOption] = useState([]);
  const [ordersOption, setOrdersOption] = useState([]);
  const [companiesOption, setCompaniesOption] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalAux, setTotalAux] = useState(0);
  const [filterActive, setFilterActive] = useState(false);
  const [reportFilters, setReportFilters] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({
    close: () => setShowModal(false),
    button1: { name: "Entendido", action: () => setShowModal(false) },
  });

  const assignUser = async (
    productData,
    assigneeID,
    assignationTarget,
    concept,
    target
  ) => {
    const assignationForAuditor = target === "auditor";
    const serviceStatus = `${concept}_status`;
    const productsArray = [];
    const data = { concept, userId: assigneeID };

    let newList = productsList.slice();
    let newRowValues = listAuxDataRight.slice();
    let newAuxList = productsListAux.slice();
    let selectedItems = productsSelected;

    const isArticleDifferentFromSelected =
      selectedItems.find(
        (item) =>
          item.orderId === productData.orderId &&
          item.article.id_article === productData.article.id_article
      ) === undefined;
    //ARTICLE CLICKED IS NOT PART OF SELECTED PRODUCTS, PUSH TO PRODUCT ARRAY AND UPDATE ASSIGNEE
    const arrayAssignations = [];
    if (isArticleDifferentFromSelected) {
      arrayAssignations.push({
        list: 1,
        data: [
          productData,
          newList,
          serviceStatus,
          assignationTarget,
          assigneeID,
          assignationForAuditor,
        ],
      });
      productsArray.push({
        orderId: productData.orderId,
        articleId: productData.article.id_article,
      });
    }

    if (selectedItems.length > 0) {
      selectedItems.forEach((item) => {
        productsArray.push({
          orderId: item.orderId,
          articleId: item.article.id_article,
        });
        arrayAssignations.push({
          list: 1,
          data: [
            item,
            newList,
            serviceStatus,
            assignationTarget,
            assigneeID,
            assignationForAuditor,
          ],
        });
        arrayAssignations.push({
          list: 2,
          data: [
            item,
            newAuxList,
            serviceStatus,
            assignationTarget,
            assigneeID,
            assignationForAuditor,
          ],
        });
      });
    }

    // Update the pushed assignation components
    setListAuxDataRight(newRowValues);
    data.articleList = productsArray;
    // Update assignations in database
    const res = await axios.post(
      process.env.REACT_APP_ASSIGNATIONS_ENDPOINT,
      data,
      { headers: { Authorization: sessionStorage.getItem("jwt") } }
    );

    if (res.data.statusCode !== 200) {
      setModalData((prev) => ({
        message: "Error al asignar al usuario",
        img: errorModal,
        close: prev.close,
        button1: prev.button1,
      }));
      setShowModal(true);
    } else {
      arrayAssignations.forEach((item) => {
        const { list, data } = item;
        const { updatedList } = runAssignations(...data);
        if (list === 1) newList = updatedList;
        else newAuxList = updatedList;
      });
    }
    // Finally update productslist for re-render
    setProductsListAux({ type: "init", value: newAuxList });
    setProductsList({ type: "init", value: newList });
  };

  const filterInputFunct = async (filtersObject, index = 0) => {
    if (productsList.length > 0) {
      setProductsSelected([]);
      setProductsList({ type: "selectAll", checked: false });
    }
    const filters = Object.keys(filtersObject);
    const filterForExport = Object.values(filtersObject);
    defineFileFilters(filterForExport, reportFilters, setReportFilters);
    const params = {
      query: {
        productsList: true,
        indexFront: index,
      },
    };
    if (filters?.length > 0) {
      setFilterActive(true);
      params.query.filter = filtersObject[filters[0]].filter;
      params.query.value = filtersObject[filters[0]].values;
    }
    const response = await getOrderedServices(params);
    if (filters.length === 0 && filterActive) {
      setProductsList({ type: "init", value: productsListCopy });
      setTotalProducts(totalAux);
      setIndexProducts(100);
      setFilterActive(false);
      return;
    }
    let arrayFiltered = [];
    const resParsed = JSON.parse(response.data.body);
    const filterList = Object.values(resParsed.data)
      .filter((e) => e.article)
      .map((e) => {
        return { ...e, country: e.article.country, upc: e.article.upc };
      });
    arrayFiltered = filterList;

    filters?.splice(0, 1);
    let array = [];
    filters?.forEach((filter) => {
      if (filtersObject[filter].filter !== "retailers") {
        arrayFiltered.forEach((article) => {
          let filtro = "";
          if (filtersObject[filter].filter === "company") {
            filtro = "company_id";
            if (
              filtersObject[filter].values?.findIndex(
                (v) => v === article.article[filtro]
              ) > -1
            ) {
              array.push(article);
            }
          } else if (filtersObject[filter].filter === "orderId") {
            filtro = "orderId";
            if (
              filtersObject[filter].values?.findIndex((v) => {
                return v === article[filtro];
              }) > -1
            ) {
              array.push(article);
            }
          } else {
            filtro = filtersObject[filter].filter;
            if (
              filtersObject[filter].values
                ?.map((e) => e.replaceAll("'", ""))
                .findIndex((v) => v === article[filtro]) > -1
            ) {
              array.push(article);
            }
          }
        });
      } else {
        arrayFiltered.forEach((article) => {
          let isValid = false;
          filtersObject[filter].values.forEach((r) => {
            if (article.retailers.findIndex((f) => f.id === r) > -1) {
              isValid = true;
            }
          });
          if (isValid) {
            array.push(article);
          }
        });
      }
      arrayFiltered = array.slice();
      array = [];
    });
    setTotalProducts(resParsed.count);
    if (firstLoad) {
      const { companyList, ordersList, countryList, count } = resParsed;
      setTotalAux(count);
      setCompaniesOption(
        companyList.map((e) => ({
          name: e.company_name,
          value: e.id_company,
          active: false,
        }))
      );
      setOrdersOption(
        ordersList.map((e) => ({ name: e, value: e, active: false }))
      );
      setCountryOption(
        countryList.map((e) => ({
          name: e || "Sin región seleccionada",
          value: e,
          active: false,
        }))
      );

      setProductsListCopy({ type: "init", value: arrayFiltered });
      setFirstLoad(false);
    }

    if (index > 0) setProductsList({ type: "addMore", value: arrayFiltered });
    else setProductsList({ type: "init", value: arrayFiltered });
  };

  const articleClicked = useCallback((checked, product, list) => {
    try {
      setProductsList({ type: "selectOne", id_article: product.article.id_article, id_order: product.orderId, checked });
      if (checked) {
        setProductsSelected((prev) => [...prev, product]);
      } else {
        const updatedArticles = list.slice();
        let productFound = updatedArticles.find(
          (prod) =>
            product.article.id_article === prod.article.id_article &&
            product.orderId === prod.orderId
        );
        if (productFound) {
          const productIndex = updatedArticles.indexOf(productFound);
          updatedArticles.splice(productIndex, 1);
          setProductsSelected(updatedArticles);
        }
      }
    } catch (err) {
      console.log(err, "error updating assignations");
    }
  }, []);

  const allClicked = useCallback(
    (checked) => {
      const chks = document.querySelectorAll(".chk-products");
      chks.forEach((ch) => (ch.checked = checked));
      if (checked) {
        setProductsSelected(productsList.slice());
        setCheckboxCounter(productsList.slice().length);
      } else {
        setProductsSelected([]);
        setCheckboxCounter(0);
      }
      setProductsList({ type: "selectAll", checked });
    },
    [productsList]
  );

  useEffect(() => {
    if (productsList.length > 0) {
      const allProductsAreSelected =
        productsSelected.length === productsList.length;
      document.getElementById("globalHeaderCheckbox").checked =
        allProductsAreSelected;
    }
    if (firstLoad) filterInputFunct({}, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsSelected]);

  function limpiaFiltros() {
    setProductsList({ type: "init", value: productsListAux });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchUsers().then((res) => setUserGroups(res));
    getAllRetailers().then((retailers) => setRetailersOption(retailers));
    getMetricsProducts().then((response) => {
      let percentArray = response[2];
      let colorPercentArray = [];
      Object.keys(percentArray).forEach((key) => {
        let element = {
          color: "",
          value: 0,
        };

        element.color = key;
        element.value = percentArray[key];
        colorPercentArray.push(element);
      });

      colorPercentArray.sort((a, b) => (a.value > b.value ? 1 : -1));
    });
  }, []);

  const [filterCadenas, setFilterCadenas] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);
  const [filterPrioridades, setFilterPrioridades] = useState(false);
  const [filterOrders, setFilterOrders] = useState(false);
  const [filterCompanies, setFilterCompanies] = useState(false);
  const [filterRegion, setFilterRegion] = useState(false);
  const [activeClass, setActiveClass] = useState("");

  const closePopUp = useCallback(
    (e) => {
      if (
        !e.target.closest("#popup-container") &&
        (filterStatus ||
          filterRegion ||
          filterCadenas ||
          filterPrioridades ||
          filterOrders ||
          filterCompanies)
      ) {
        setFilterStatus(false);
        setFilterRegion(false);
        setFilterCadenas(false);
        setFilterPrioridades(false);
        setFilterOrders(false);
        setFilterCompanies(false);
        document.removeEventListener("click", closePopUp, false);
      }
    },
    [
      filterCadenas,
      filterCompanies,
      filterOrders,
      filterPrioridades,
      filterRegion,
      filterStatus,
    ]
  );

  useEffect(() => {
    if (
      filterStatus ||
      filterRegion ||
      filterCadenas ||
      filterPrioridades ||
      filterOrders ||
      filterCompanies
    ) {
      document.addEventListener("click", closePopUp, false);
    }
  }, [
    filterStatus,
    filterRegion,
    filterCadenas,
    filterPrioridades,
    filterOrders,
    filterCompanies,
    closePopUp,
  ]);

  const [listAuxDataRight, setListAuxDataRight] = useState([]);

  const [loadingExportables, setLoadingExportables] = useState(false);

  const getExportables = async () => {
    setLoadingExportables(true);
    const email = encodeURIComponent(
      JSON.parse(sessionStorage.getItem("user")).email
    );
    const filtersForQuery = encodeURIComponent(JSON.stringify(reportFilters));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_EXPORTABLES}?email=${email}&filters=${filtersForQuery}&reportType=1`,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      );

      const modalData = {
        message: "Error al enviar el archivo",
        img: errorModal,
        detail: "por favor valida tu archivo .csv e inténtalo de nuevo.",
      };
      if (response.data.statusCode === 200) {
        modalData.message = "Archivo .csv enviado con éxito";
        modalData.img = sucessModal;
        delete modalData.detail;
      }
      setModalData((prev) => ({
        ...modalData,
        close: prev.close,
        button1: prev.button1,
      }));
      setShowModal(true);
      setLoadingExportables(false);
    } catch (error) {
      console.log(error);
    }
  };

  const openModal = (e) => {
    if (
      (!e.target.closest("#article-detail") && sidebool) ||
      e.target.id === "close-modal-button"
    ) {
      document.removeEventListener("click", openModal, false);
      setActiveClass("");
      setTimeout(() => setSidebool(false), 500);
    }
  };

  useEffect(() => {
    if (sidebool) {
      document.addEventListener("click", openModal, false);
      setActiveClass("active-modal");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebool]);

  const editProducts = () => {
    if (productsSelected.length > 0) {
      let orderedArray = productsSelected.sort(
        (a, b) => a.article.id_article - b.article.id_article
      );
      sessionStorage.setItem(
        "multipleEditionList",
        JSON.stringify(orderedArray)
      );

      setRedirectMultipleEdition(true);
    }
  };

  const exportProducts = () => {
    if (productsSelected.length > 0) {
      setShowContentohExportModal(true);
    }
  };

  const loadMoreProducts = (filterGlobal) => {
    filterInputFunct(filterGlobal, indexProducts);
    setIndexProducts(indexProducts + 100);
  };
  const clsFilters = () => {
    limpiaFiltros();
    setFirstFilter("");
    setFirstFilterExecuted(false);
  };

  const rowClicked = (clickedId, product, index = null) => {
    const assignComponentWasNotClicked = !clickedId.startsWith("assign");
    if (assignComponentWasNotClicked) {
      setTimeout(() => setSidebool(true), 500);
      setProductDetail(product);
      if (![null, undefined].includes(index)) setIndexSelected(index);
      sessionStorage.setItem("productSelected", JSON.stringify(product));
      sessionStorage.setItem("version", JSON.stringify(product?.version));
      sessionStorage.setItem(
        "productEdit",
        JSON.stringify({
          ArticleId: product?.article.id_article,
          categoryId: product?.article.id_article,
          product: [product],
        })
      );
    }
  };

  return (
    <MainContainer>
      {props.path === "Productos" ? (
        <div>
          <Container className="main-container">
            <Container className="title-container">
              <Title>Lista de Productos</Title>
            </Container>
          </Container>

          <FilterInput
            statusOption={statusOption}
            priorityOption={priorityOption}
            editProducts={editProducts}
            exportProducts={exportProducts}
            charged={productsList.length}
            total={totalProducts}
            loadingExportables={loadingExportables}
            setLoadingExportables={setLoadingExportables}
            download={() => getExportables()}
            loadMoreProducts={loadMoreProducts}
            setFirstFilter={setFirstFilter}
            firstFilter={firstFilter}
            firstFilterExecuted={firstFilterExecuted}
            clsFilters={clsFilters}
            productsListCopy={productsListCopy}
            setproductsList={setProductsList}
            filterInputFunct={filterInputFunct}
            enabledFilters={enabledFilters}
            retailersOption={retailersOption}
            countryOption={countryOption}
            ordersOption={ordersOption}
            companiesOption={companiesOption}
          />
        </div>
      ) : (
        <Container className="main-container">
          <Container className="title-container">
            <Title2>Productos por realizar</Title2>
            <SubTitle>
              Una vez asignada la tarea, aquí te mostraremos el estatus de esta,
              estaras compartiendo en tiempo real tus estatus.
            </SubTitle>
          </Container>
        </Container>
      )}
      <VirtualizedContainer>
        <TableTasks
          articlesList={productsList.slice()}
          usersByRole={userGroups}
          rowClicked={rowClicked}
          checkboxClicked={articleClicked}
          checkAllItems={allClicked}
          assignUser={assignUser}
          productsSelected={productsSelected.slice()}
          articlesLists={{
            productsList,
            productsListCopy,
            productsListAux,
          }}
          setArticlesLists={{
            setProductsList,
            setProductsListCopy,
            setProductsListAux,
          }}
        />
      </VirtualizedContainer>
      {sidebool && (
        <ProductDetail
          // setSidebool={setSidebool}
          productDetail={productDetail}
          sidebool={sidebool}
          className={activeClass}
          articleIndex={indexSelected}
          articlesLists={{
            productsList,
            productsListCopy,
            productsListAux,
          }}
          setArticlesLists={{
            setProductsList,
            setProductsListCopy,
            setProductsListAux,
          }}
        />
      )}
      {showContentohExportModal && (
        <ContentohExportModal
          productServicesPrices={productServicesPrices}
          productsList={productsList}
          products={productsSelected}
          close={setShowContentohExportModal}
          checkboxCounter={checkboxCounter}
        />
      )}
      {redirectMultipleEdition && (
        <Redirect
          to={{
            pathname: `/products/multipleEdition`,
          }}
        />
      )}
      {showModal && <GenericModal {...modalData} />}
    </MainContainer>
  );
};

export default TaskList;
