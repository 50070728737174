import React from 'react'
import styled from 'styled-components'

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  width: ${props => props.width ? props.width : '150px'};
  height: 30px;
  border: 1px solid #F0EEF2;
  border-radius: 30px;
  padding: 0 10px;
  box-sizing: border-box;

  & + * {
    margin-left: 10px;
  }
`;

const Input = styled.input`
  border-width: 0;
  height: 90%;
  width: ${props => props.hasIcon ? '90%' : '100%'};
`;

const Icon = styled.figure`
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export default function InputSearch(props){
  return <MainContainer>
    <Input 
    id={props.id}
    type="text"
    onChange={(event) => props.onChange && props.onChange(event.target.value)}
    hasIcon={props.icon}/>
    {props.icon && <Icon onClick={props.iconClick}><img src={props.icon} alt={props.alt}/></Icon> }
  </MainContainer>
}