import styled from "styled-components";

export const MainContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

export const Header = styled.div`
  padding: 18px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div:last-child {
    display: flex;
    & > div:last-child {
      margin-left: 10px;
    }
  }
`;

export const ChartsSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 18px 25px;
`;
