export const tableStyle = {
  className: "table-products",
  rowClassName: "table-row",
  headerStyle: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Avenir Next",
    fontWeight: 600,
    fontSize: "12px",
    color: "#503D66",
    margin: 0,
  },

  tableStyle: {
    overflow: "unset",
  },
  rowStyle: {
    display: "flex",
    overflow: "unset",
    textAlign: "center",
    fontFamily: "Avenir Next",
    fontSize: 13,
    color: "#817393",
  },
};

export const checkboxColumn = {
  className: "table-column",
  flexGrow: 1,
  minWidth: "3.6%",
  maxWidth: "3.6%",
  dataKey: "checked",
  style: {
    textAlign: "center",
    margin: "0",
  },
};

export const regColumn = {
  className: "table-column",
  flexGrow: 1,
  maxWidth: "4%",
  minWidth: "4%",
  dataKey: "status",
  style: {
    textAlign: "center",
    margin: "0",
    overflow: "unset",
  },
};

export const orderColumn = {
  className: "table-column",
  flexGrow: 1,
  dataKey: "orderId",
  style: {
    textAlign: "center",
    margin: "0",
  },
};

export const pageFacturationColumn = {
  style: {
    overflow: "visible",
  },
};

export const upcColumn = {
  className: "table-column",
  flexGrow: 1,
  maxWidth: "7.1%",
  minWidth: "7.1%",
  dataKey: "article.upc",
  style: {
    textAlign: "center",
    margin: "0",
  },
};

export const nameColumn = {
  className: "table-column",
  flexGrow: 1,
  maxWidth: "6.1%",
  minWidth: "6.1%",
  dataKey: "article.name",
  style: {
    justifyContent: "flex-start",
    textAlign: "left",
    margin: "0",
    paddingLeft: "10px",
  },
};

export const categoryColumn = {
  className: "table-column",
  flexGrow: 1,

  dataKey: "article.category",
  style: {
    justifyContent: "flex-start",
    textAlign: "left",
    margin: "0",
    paddingLeft: "10px",
  },
};

export const retailersColumn = {
  className: "table-column",
  flexGrow: 1,
  dataKey: "retailers",
  style: {
    textAlign: "center",
    margin: "0",
  },
};

export const estatusColumn = {
  className: "table-column",
  flexGrow: 1,

  dataKey: "status",
  style: {
    textAlign: "center",
    margin: "0",
    overflow: "unset",
  },
};

export const articleStatusColumn = {
  className: "table-column",
  flexGrow: 1,
  minWidth: "5%",
  dataKey: "status",
  style: {
    textAlign: "center",
    margin: "0",
    overflow: "unset",
  },
};

export const marcaColumn = {
  className: "table-column",
  flexGrow: 1,

  dataKey: "brand",
  style: {
    textAlign: "center",
    margin: "0",
  },
};

export const missingDataColumn = {
  className: "table-column",
  flexGrow: 1,

  dataKey: "brand",
  style: {
    textAlign: "center",
    margin: "0",
  },
};

export const descServiceColumn = {
  className: "table-column",
  flexGrow: 1,

  style: {
    textAlign: "center",
    justifyContent: "flex-start",
    paddingLeft: "10px",
    margin: "0",
  },
};

export const validColumn = {
  className: "table-column",
  flexGrow: 1,

  dataKey: "status",
  style: {
    textAlign: "center",
    margin: "0",
    overflow: "unset",
  },
};

export const priorityColumn = {
  className: "table-column",
  flexGrow: 1,

  dataKey: "prio",
  style: {
    textAlign: "center",
    margin: "0",
    overflow: "unset",
  },
};

export const asignColumn = {
  className: "table-column",
  flexGrow: 1,
  dataKey: "prio",
  style: {
    textAlign: "center",
    position: "relative",
    margin: "0",
    overflow: "unset",
  },
};

export const servicesColumn = {
  className: "table-column",
  flexGrow: 1,
  dataKey: "service",
  style: {
    textAlign: "center",
    margin: "0",
    overflow: "unset",
  },
};
