import { TableTitleContainer } from "./styles";
import axios from "axios";
//Images
import trashIcon from "../../../../assets/IconComponents/multipleEditionIcons/trashIcon.svg";
import defaultButtonState from "../../../../assets/IconComponents/multipleEditionIcons/defaultButtonState.svg";
import acceptIcon from "../../../../assets/IconComponents/multipleEditionIcons/acceptIcon.svg";
import rejectIcon from "../../../../assets/IconComponents/multipleEditionIcons/rejectIcon.svg";
import saveIcon from "../../../../assets/IconComponents/multipleEditionIcons/saveIcon.svg";

export const TableTitle = (props) => {
  const securityCodes = props.securityCodes;
  const productsTotal = JSON.parse(
    sessionStorage.getItem("multipleEditionList")
  );
  const user = JSON.parse(sessionStorage.getItem("user"));

  const sendEvaluations = async (result) => {
    const servicesPromises = [];
    let originalCopy = props.productsOriginalFormat.slice();
    props.productsForEvaluation.forEach((item) => {
      const evalStatus = item[`${props.concept}_status`];
      const serviceIsRequest = evalStatus !== "NS";
      const articleId = item.article.id_article;
      const hasNullRequired =
        props.nullRequired[props.concept][articleId] !== 0;
      if (!serviceIsRequest || hasNullRequired) return;
      item.retailers.forEach((retailer) => {
        let data = {};
        data = {
          articleId,
          orderId: item.article.id_order,
          concept: props.concept,
          result,
          evalStatus,
          retailerId: retailer.id,
        };
        if (evalStatus === "IE") {
          data.facilitator = true;
        }

        servicesPromises.push(
          axios.put(`${process.env.REACT_APP_EVALUATION_ENDPOINT}`, data, {
            headers: {
              Authorization: sessionStorage.getItem("jwt"),
            },
          })
        );
      });
    });
    const resopnse = await Promise.all(servicesPromises);
    const { newStatus } = JSON.parse(resopnse?.pop()?.data?.body || "{}");

    originalCopy = originalCopy.map((item) => {
      props.productsForEvaluation
        .map(({ article }) => article.id_article)
        .includes(item.article.id_article) &&
        item[`${props.concept}_status`] !== "NS" &&
        (item[`${props.concept}_status`] = newStatus);
      return item;
    });
    sessionStorage.setItem("multipleEditionList", JSON.stringify(originalCopy));
    props.setProductsList(originalCopy);
    props.setLoading(false);
  };

  return (
    <TableTitleContainer>
      <div className="title-container">
        <h3>{props.title ? props.title : " "}</h3>
        <p>{props.subTitle ? props.subTitle : " "}</p>
      </div>
      <div className="side-container">
        {props.tableInfo ? (
          <div className="side-container">
            <p>{productsTotal.length ? productsTotal.length : 0} productos</p>
            <div className="buttons-container">
              <button className="save-icon">
                <img
                  src={saveIcon}
                  alt="Save"
                  onClick={() => props.saveAll()}
                />
              </button>
              <button className="trash-icon">
                <img src={trashIcon} alt="Delete" />
              </button>
              {[7, 8].includes(user.id_role) ? (
                <button
                  className={
                    props.canEvaluate && props.rowsSelected !== 0
                      ? "send-to-evaluation"
                      : "unable-button"
                  }
                  onClick={() => {
                    if (props.canEvaluate && props.rowsSelected !== 0) {
                      sendEvaluations("A");
                    }
                  }}
                >
                  Enviar evaluación
                </button>
              ) : (
                <>
                  <button
                    onClick={() => {
                      if (props.canEvaluate && props.rowsSelected !== 0)
                        props.setLoading(true);
                      sendEvaluations("A");
                    }}
                    className="accept"
                  >
                    <img
                      src={
                        props.canEvaluate && props.rowsSelected !== 0
                          ? acceptIcon
                          : defaultButtonState
                      }
                      alt="Accept"
                    />
                  </button>
                  <button
                    onClick={() => {
                      if (props.canEvaluate && props.rowsSelected !== 0)
                        props.setLoading(true);
                      sendEvaluations("R");
                    }}
                    className="reject"
                  >
                    <img
                      src={
                        props.canEvaluate && props.rowsSelected !== 0
                          ? rejectIcon
                          : defaultButtonState
                      }
                      alt="Reject"
                    />
                  </button>
                </>
              )}
            </div>
          </div>
        ) : (
          securityCodes && (
            <div className="security-codes">
              <p>Códigos de seguridad</p>
            </div>
          )
        )}
      </div>
    </TableTitleContainer>
  );
};
