import React, { useState, useEffect } from "react";
import { VirtualizedContainer } from "./styles";
import CardTutorials from "../../components/CardTutorials";

import tutorials from './utils/tutorials'

import searchIcon from '../../../src/assets/IconComponents/searchblack.svg'

const TutorialsView = () => {
  const [searchValue, setSearchValue] = useState("");
  const [filteredTutorials, setFilteredTutorials] = useState([]);


  useEffect(() => {
    const filtered = tutorials.filter((tutorial) =>
      tutorial.title.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredTutorials(filtered);
  }, [searchValue, tutorials]);

  return (
    <>
      <VirtualizedContainer>
        <div className="flex justify-center my-4">
          <input
            type="text"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Buscar"
            className="input-search"
            style={{
              backgroundImage: `url(${searchIcon})`,
              backgroundPosition: '10px 50%', 
              backgroundSize: '14px', 
              backgroundRepeat: 'no-repeat',
              paddingLeft: '35px',
            }}
          />
        </div>
        <div className="container-general">
          <h1>¡Bienvenido a los tutoriales!</h1>
          <p>Aquí podrás encontrar todos los tutoriales para poder entender la plataforma.</p>
        </div>
        <div className="container-videos">
          {filteredTutorials.map((tutorial, index) => (
            <CardTutorials
              key={index}
              image={tutorial.image}
              title={tutorial.largeTitle}
              platform={tutorial.platform}
              path={(tutorial.shortUrl)}
              pathVideo={tutorial.path}
              description={tutorial.description}
            />
          ))}
        </div>
      </VirtualizedContainer>
    </>
  );
};

export default TutorialsView;
