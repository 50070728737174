import axios from "axios";
import { Base64 } from "js-base64";
import { v4 as uuidv4 } from "uuid";

import defaultUpdate from "../assets/defaultImages/defaultUpdate.png";

// SVG FLAGS
import mexico from "../assets/IconFlags/mexico.svg";
import argentina from "../assets/IconFlags/argentina.svg";
import honduras from "../assets/IconFlags/honduras.svg";
import colombia from "../assets/IconFlags/colombia.svg";
import costa from "../assets/IconFlags/costa.svg";
import ecuador from "../assets/IconFlags/ecuador.svg";
import panama from "../assets/IconFlags/panama.svg";
import peru from "../assets/IconFlags/peru.svg";
import salvador from "../assets/IconFlags/salvador.svg";
import defaultFlag from "../assets/IconFlags/defaultflag.svg";

//SVG payment
import valid from "../assets/IconComponents/valid.svg";
import authorized from "../assets/IconComponents/authorized.svg";
import missing from "../assets/IconComponents/null.svg";

//SVG Prio
import greenFlag from "../assets/IconComponents/greenFlag.svg";
import yellowFlag from "../assets/IconComponents/yellowFlag.svg";
import redFlag from "../assets/IconComponents/redFlag.svg";
import emptyFlag from "../assets/IconComponents/emptyFlag.svg";

export const isElementClicked = (evt, element) => {
  let flyoutElement;
  try {
    flyoutElement = document.getElementById(element);
  } catch (err) {}
  let targetElement = evt.target;
  do {
    if (targetElement === flyoutElement) {
      return true;
    }
    // Go up the DOM
    targetElement = targetElement.parentNode;
  } while (targetElement);
  return false;
};

export const getMetricsProducts = async () => {
  let productArraylist;
  let productCounter = 0;
  await axios
    .get(`${process.env.REACT_APP_ORDERED_SERVICES_ENDPOINT}?received=1`, {
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    })
    .then(
      (response) => (productArraylist = JSON.parse(response.data.body).data)
    );

  let metricsArray = [];
  let percentArray = [];
  productArraylist?.forEach((product) => {
    productCounter++;
    if (!metricsArray[getStateFilter(product.article.status)])
      metricsArray[getStateFilter(product.article.status)] = 1;
    else metricsArray[getStateFilter(product.article.status)]++;
  });

  productArraylist?.forEach((product) => {
    percentArray[getStateFilter(product.article.status)] =
      (metricsArray[getStateFilter(product.article.status)] / productCounter) *
      100;
  });

  return [metricsArray, productCounter, percentArray];
};

export const getStateFilter = (status) => {
  switch (status) {
    case "AS":
    case "CA":
    case "IE":
      return "#ED9A4D";

    case "AC":
    case "AA":
    case "AP":
      return "#18A0FB";

    case "RC":
    case "RA":
    case "RP":
      return "#D74DED";

    case "Ex":
      return "#71DE56";
    default:
      return "#EDD34D";
  }
};

export const getCollaborators = async () => {
  const response = await axios({
    method: "get",
    url: process.env.REACT_APP_COLLABORATOR_ENDPOINT,
    headers: {
      Authorization: sessionStorage.getItem("jwt"),
    },
  });
  sessionStorage.setItem(
    "collaborator",
    JSON.stringify(JSON.parse(response.data.body).users)
  );
};

export const getStatusColor = (status) => {
  switch (status) {
    case "NULL":
      return "#D4D1D7";
    case "R":
    case "CREATED":
    case "PA":
    case "SAC":
      return "#E67432";
    case "ACA":
    case "AC":
    case "A":
    case "AP":
    case "AA":
    case "AAC":
      return "#18A0FB";
    case "RC":
    case "RA":
    case "RCA":
    case "RP":
    case "RAC":
      return "#D74DED";
    case "CA":
    case "AS":
    case "IE":
    case "Capturando":
      return "#ED9A4D";
    case "NS":
      return "#817393";
    case "Ex":
    case "FAP":
      return "#71DE56";
    default:
      return "#D4D1D7";
  }
};

export const getShortStatus = (status) => {
  switch (status) {
    case "NULL":
      return "-";
    default:
      return status;
  }
};

export const getLongStatus = (status) => {
  switch (status) {
    case "NULL":
      return "-";
    case "R":
      return "En Recepción";
    case "PA":
      return "Por asignar";
    case "AS":
      return "Asignado";
    case "AC":
      return "Aprobado Coordinador";
    case "RC":
      return "Rechazado Coordinador";
    case "AA":
      return "Aprobado A";
    case "RA":
      return "Rechazado A";
    case "AP":
      return "Aprobado P";
    case "RP":
      return "RechazadoP";
    case "ACA":
      return "Aprobado Cadena";
    case "RCA":
      return "Rechazado Cadena";
    case "IE":
      return "QA Coordinador";
    case "CA":
      return "Capturando";
    default:
      return "Capturando";
  }
};

export const getRegionFlag = (region) => {
  switch (region) {
    case "México":
      return mexico;
    case "Argentina":
      return argentina;
    case "Honduras":
      return honduras;
    case "Colombia":
      return colombia;
    case "Costa Rica":
      return costa;
    case "Ecuador":
      return ecuador;
    case "El Salvador":
      return salvador;
    case "Panamá":
      return panama;
    case "Perú":
      return peru;
    default:
      return defaultFlag;
  }
};

export const getPaymentStatus = (payment) => {
  switch (payment) {
    case "Pagado":
    case "pagado":
      return valid;
    case "autorizado":
      return authorized;
    case "Pendiente":
    case "pendiente":
      return missing;
    default:
      return "P";
  }
};

export const getBillingStatus = (is_billing) => (is_billing ? valid : missing);

export const getPrio = (prio) => {
  switch (prio) {
    case "low":
      return greenFlag;
    case "medium":
      return yellowFlag;
    case "high":
      return redFlag;
    default:
      return emptyFlag;
  }
};

export const getRoleUser = (userId) => {
  switch (userId) {
    case 1:
      return "Administrador";
    case 2:
      return "KAM";
    case 3:
      return "Recepcionista";
    case 4:
      return "Facilitador textos";
    case 5:
      return "Facilitador imagenes";
    case 6:
      return "Auditor QA";
    case 7:
      return "Especialista textos";
    case 8:
      return "Especialista edición";
    case 9:
      return "Entregas";
    case 10:
      return "Custom";
    default:
      return "Proveedor";
  }
};

export const getImageType = (Id) => {
  switch (Id) {
    case 1:
      return "Fotografía del producto";
    case 2:
      return "Render";
    default:
      return "--";
  }
};

export const getImagePackingType = (Id) => {
  switch (Id) {
    case 1:
      return "Embalaje adicional";
    case 2:
      return "En paquete";
    case 3:
      return "Producto expuesto";
    case 4:
      return "Embalaje multiforme";
    case 5:
      return "Si no es una imagen / no corresponde";
    default:
      return "--";
  }
};

export const checkInitialStatus = (status) => {
  if (status === "CA") {
    return "CA";
  } else {
    return status;
  }
};

export const getImage = (imageGeneral, width, height) => {
  try {
    const image = imageGeneral;
    const ext = image.src.split(".");
    const uuid = ext[0].split("-");
    return {
      id: image.id,
      image_id: image.image_id,
      packing_type: +image.packing_type,
      image_type: +image.image_type,
      name: image.name,
      version: image.id_version,
      width: image.width,
      height: image.height,
      ext: ext[ext.length - 1],
      uuid:
        uuid.reduce((prevVal, currVal, idx) => {
          if (idx < 2) return "";
          else return idx === 2 ? currVal : prevVal + "-" + currVal;
        }, "") || uuidv4(),
      srcDB: image.src,
      src:
        "https://d24s337exbt5zr.cloudfront.net/" +
        Base64.encode(
          JSON.stringify({
            bucket: process.env.REACT_APP_IMAGES_BUCKET,
            key: image.src,
            edits: {
              resize: {
                width: width * 4,
                height: height * 4,
                fit: "contain",
                background: {
                  r: 255,
                  g: 255,
                  b: 255,
                  alpha: 1,
                },
              },
            },
          })
        ),
    };
  } catch (err) {
    console.log("err", err);
    return defaultUpdate;
  }
};

export const getProfilePicture = (userId, width, height) => {
  try {
    return (
      "https://d24s337exbt5zr.cloudfront.net/" +
      Base64.encode(
        JSON.stringify({
          bucket: process.env.REACT_APP_IMAGES_PROFILE_BUCKET,
          key: `id-${userId}/${userId}.png`,
          edits: {
            resize: {
              width: width,
              height: height,
              fit: "contain",
              background: {
                r: 255,
                g: 255,
                b: 255,
                alpha: 0,
              },
            },
          },
        })
      )
    );
  } catch (err) {
    console.log("err", err);
    return defaultUpdate;
  }
};

export const isUserAssigned = (article, concept) => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  let assigned = false;
  switch (user.id_role) {
    case 7:
      assigned =
        (article.id_datasheet_especialist === user.id_user &&
          concept === "datasheet") ||
        (article.id_description_especialist === user.id_user &&
          concept === "description");
      break;
    case 8:
      assigned = article.id_images_especialist === user.id_user;
      break;
    case 4:
      assigned =
        (article.id_datasheet_facilitator === user.id_user &&
          concept === "datasheet") ||
        (article.id_description_facilitator === user.id_user &&
          concept === "description");
      break;
    case 5:
      assigned = article.id_images_facilitator === user.id_user;
      break;
    case 6:
      assigned = article.id_auditor === user.id_user;
      break;
    case 1:
      assigned = true;
      break;
    case 9:
      // PENDING CUSTOM USER ROLE (CHECK PERMISSION GRANTED FOR THIS USER IN DB OR SESSION STORAGE)
      break;
    default:
      break;
  }
  return assigned;
};

export const isStatusCorrect = (article, concept) => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  let correct = false;
  /*
    ROLES IDS:
      4 = DATASHEET/DESCRIPTION FACILITATORS
      5 = IMAGES FACILITATORS
      6 = AUDITOR
      7 = DATASHEET/DESCRIPTION SPECIALISTS
      8 = IMAGES SPECIALISTS

    STATUSES:
      CA = (PRODUCT OR CONCEPTS ARE BEING WORKED AT THE MOMENT)

      IE CONCEPT = (CONCEPT HAS TO BE EVALUATED BY ASSIGNED FACILITATOR)
      IE ARTICLE STATUS = (ALL REQUESTED CONCEPTS HAVE BEEN SENT FOR FACILITATORS EVALUATIONS AND AT LEAST 1 IS STILL WAITING FOR THAT EVALUATION)

      AC CONCEPT = (ASSIGNED FACILITATOR APPROVED CONCEPT)
      AC ARTICLE STATUS = (ALL FACILITATORS HAVE APPROVED THEIR RESPECTIVE CONCEPTS, AUDITOR HAS TO EVALUATE THE PRODUCT)

      RA CONCEPT = AUDITOR REJECTED ONE OF THE CONCEPTS (EITHER IT WAS SENT BY FACILITATOR OR AGREED WITH A VENDOR/PROVIDER REJECTION)
      RA ARTICLE STATUS = AT LEAST ONE OF THE CONCEPTS WAS REJECTED BY AUDITOR (SPECIALISTS HAVE TO MAKE CORRECTIONS)

      RP CONCEPT = (CONCEPT WAS REJECTED BY VENDOR/PROVIDER)
      RP ARTICLE STATUS = AT LEAST ONE OF THE CONCEPTS WAS REJECTED BY VENDOR/PROVIDER (AUDITOR HAS TO EVALUATE THE REJECTION)
  */
  switch (user.id_role) {
    case 4:
      if (["IE", "CA", "RC", "RA"].includes(article.status)) {
        correct =
          (article.datasheet_status === "IE" && concept === "datasheet") ||
          (article.description_status === "IE" && concept === "description");
      }
      break;
    case 5:
      if (["IE", "CA", "RC", "RA"].includes(article.status)) {
        correct = article.images_status === "IE" && concept === "images";
      }
      break;
    case 6:
      if (["AC", "RP", "RA"].includes(article.status)) {
        correct = ["AC", "RP"].includes(article[`${concept}_status`]);
      }
      break;
    case 7:
      if (["CA", "AS", "RA", "RC"].includes(article.status)) {
        correct = ["CA", "RA", "RC"].includes(article.images_status);
      }
      break;
    case 8:
      if (["CA", "AS", "RA", "RC"].includes(article.status)) {
        correct = ["CA", "RA", "RC"].includes(article[`${concept}_status`]);
      }
      break;
    default:
      break;
  }
  return correct;
};

export const datasheetsDataComplete = (datasheets, product) => {};

export const statusFilterOptions = [
  { name: "Capurando", value: "CA", active: false },
  { name: "Asignado", value: "AS", active: false },
  { name: "QA Coordinador", value: "IE", active: false },
  { name: "Aprobado Coordinador", value: "AC", active: false },
  { name: "Rechazado Coordinador", value: "RC", active: false },
  { name: "Aprobado Auditor", value: "AA", active: false },
  { name: "Rechazado Auditor", value: "RA", active: false },
  { name: "Aprobado Proveedor", value: "AP", active: false },
  { name: "Rechazado Proveedor", value: "RP", active: false },
];

export const priorityFilterOptions = [
  { name: "Alta", value: "high", active: false },
  { name: "Media", value: "medium", active: false },
  { name: "Baja", value: "low", active: false },
  { name: "Sin asignar", value: "none", active: false },
];

export const getRetailerPicture = (retailerId, width, height) => {
  try {
    return (
      "https://d24s337exbt5zr.cloudfront.net/" +
      Base64.encode(
        JSON.stringify({
          bucket: process.env.REACT_APP_IMAGES_BUCKET,
          key: `retailers/${retailerId}.png`,
          edits: {
            resize: {
              width: width,
              height: height,
              fit: "fill",
              background: {
                r: 255,
                g: 255,
                b: 255,
                alpha: 0,
              },
            },
          },
        })
      )
    );
  } catch (err) {
    console.log("err", err);
    return defaultUpdate;
  }
};
