import styled from "styled-components";

export const Container = styled.header`
  display: flex;
  align-items: center;
  gap: 10px;
  .input-container {
    display: flex;
    align-items: center;
    border: 1px solid #f0f0f0;
    border-radius: 50px;
    padding: 5px;
    flex: 3;

    span {
      & + input {
        margin-left: 10px;
      }
    }

    input {
      border: none;
      display: block;
      flex: 1;
      font-size: 12px;
      font-family: Roboto;

      &::placeholder {
        font-size: 12px;
        font-family: Roboto;
      }
    }
  }

  .upload-container {
    position: relative;
    cursor: pointer;
    background-color: #e33aa9;
    padding: 7px 15px;
    border-radius: 17px;
    border: 1px solid #e33aa9;
    color: white;

    p {
      font-size: 12px;
      cursor: pointer;
    }

    input {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      z-index: 10;

      &#file-upload-button {
        cursor: pointer;
        width: 100;
        margin: 0;
        z-index: -1;
      }
    }
  }

  & + * {
    margin-top: 20px;
  }
`;
