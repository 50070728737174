import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import axios from "axios";

import Separator from "../../../assets/IconComponents/Separator";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  ContainerHeader,
  ContainIcon,
  PulsatingCircle,
  UserInfo,
  Container,
  ContainerInfo,
  ContainerInfoSubtitle,
  ContainerInfoRight,
  ContainerInfoTitle,
  HeaderTitle,
  HeaderInfo,
  HeaderSearch,
  ProfileMenu,
  ProfileNavLink,
} from "./styles";
import { Routers } from "../../../routers/Routers.js";
import IconNotifications from "../../../assets/IconComponents/IconNotifications";
import MyAccount from "../../../assets/IconComponents/MyAccount.svg";
import AccountLogOut from "../../../assets/IconComponents/AccountLogOut.svg";
import NotifyBar from "../../../components/notifyBar/index";
import ReactImageFallback from "react-image-fallback";
import defaultProfile from "../../../assets/defaultImages/defaultProfile.png";
import spinner from "../../../assets/defaultImages/Spinner.gif";
import { getRoleUser } from "../../../_utils/helper";
const isMobile = window.innerWidth <= 500;

export const ContainerDashboard = () => {
  const history = useHistory();
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem("user"))[0]
      ? JSON.parse(sessionStorage.getItem("user"))[0]
      : JSON.parse(sessionStorage.getItem("user"))
  );
  const [unreadNotif, setUnreadNotif] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [activeClass, setActiveClass] = useState("");
  const location = useLocation();
  const getNotifications = async () => {
    const notificationsResponse = await axios.get(
      process.env.REACT_APP_NOTIFICATIONS_ENDPOINT,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    const notif = notificationsResponse.data.body
      ? JSON.parse(notificationsResponse.data.body).data.reverse()
      : [];
    setNotifications(notif);
  };

  useEffect(() => {
    getNotifications();
    const intervalImg = setInterval(() => {
      if (user.src !== JSON.parse(sessionStorage.getItem("user")).src) {
        setUser(JSON.parse(sessionStorage.getItem("user")));
      }
    }, 1000);
    return () => {
      clearInterval(intervalImg);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUnreadNotif(notifications?.filter((not) => not.read === 0).length > 0);
  }, [notifications]);

  const [show, setShow] = useState(false);
  const [menuUser, setMenuUser] = useState(false);

  const closeMenuUser = (e) => {
    if (!e.target.closest("#menu-user") && menuUser) {
      setMenuUser(false);
      document.removeEventListener("click", closeMenuUser, false);
    }
  };

  useEffect(() => {
    if (menuUser) {
      document.addEventListener("click", closeMenuUser, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuUser]);

  const openModal = (e) => {
    if (
      (!e.target.closest("#notification-modal") && show) ||
      e.target.closest("#icon-close-button")
    ) {
      document.removeEventListener("click", openModal, false);
      setActiveClass("");
      setTimeout(() => setShow(false), 500);
    }
  };

  useEffect(() => {
    if (show) {
      document.addEventListener("click", openModal, false);
      setActiveClass("notification-active");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <Container>
      <ContainerHeader>
        {!isMobile && (
          <ContainerInfo>
            {location.pathname === "/tutorials" ? (
              <HeaderTitle>Tutoriales</HeaderTitle>
            ) : (
              <>
                <HeaderTitle>{user.name}</HeaderTitle>
                <HeaderInfo>{user.position}</HeaderInfo>
              </>
            )}
          </ContainerInfo>
        )}
        {!isMobile && <HeaderSearch></HeaderSearch>}
        <Separator />
        <ContainIcon onClick={() => setTimeout(() => setShow(true), 500)}>
          <IconNotifications />
          <PulsatingCircle notifications={unreadNotif} />
        </ContainIcon>
        <UserInfo id="menu-user" onClick={() => setMenuUser(true)}>
          <ReactImageFallback
            src={user.src}
            fallbackImage={defaultProfile}
            initialImage={spinner}
            alt="avatar"
            className="avatar"
          />
          <ContainerInfoRight>
            <ContainerInfoTitle>
              {user.name === ""
                ? "Complete su perfil"
                : `${user.name} ${user.last_name}`}
            </ContainerInfoTitle>
            <ContainerInfoSubtitle>{user.position}</ContainerInfoSubtitle>
          </ContainerInfoRight>
          {menuUser && (
            <ProfileMenu id="menu-user">
              <div className="title-role">
                <p>{getRoleUser(user.id_role)}</p>
              </div>
              <ProfileNavLink className="profile-link" href="/profile">
                <div className="icon-container">
                  <img src={MyAccount} alt="My account circle" />
                </div>
                <p>Mi cuenta</p>
              </ProfileNavLink>
              <ProfileNavLink
                className="profile-link"
                to="#"
                onClick={async (event) => {
                  event.preventDefault();
                  try {
                    await Auth.signOut({ global: true });
                    history.push({ pathname: "/login" });
                    window.location.reload();
                    sessionStorage.clear();
                  } catch (error) {}
                }}
              >
                <div className="icon-container">
                  <img src={AccountLogOut} alt="log out icon" />
                </div>
                <p>Cerrar sesión</p>
              </ProfileNavLink>
            </ProfileMenu>
          )}
        </UserInfo>
      </ContainerHeader>
      {show && (
        <NotifyBar
          setShow={setShow}
          setNotifications={setNotifications}
          notifications={notifications}
          className={activeClass}
        />
      )}
      <Routers />
    </Container>
  );
};
