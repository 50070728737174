import Main from './pages/Menu/index'
import { Switch,BrowserRouter as Router, Route } from 'react-router-dom';
import Login from './pages/Login/Login.js'
import './index.css'

function App() {

  return (
    <Router>
      <Switch>
        <AuthDetection/>
      </Switch>
    </Router>
  );
}
export default App;

function AuthDetection (props){
  // TODO: Improve this mechanism to detect authentication
  const authenticated = sessionStorage.getItem('auth') === 'true' ? true : false; 
  // const authenticated = true
  // const authenticated = false

  return <Route render={() => authenticated 
    ? <Main {...props}></Main> 
    : <Login {...props}></Login>}/>;
}