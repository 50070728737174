import React from "react";
import {
  RequestModal,
  ModalContainer,
  CloseButton,
  Title,
  ButtonContainer,
  FormActiveContainer,
} from "./styles";

// imports SVG
import emailSend from "../../assets/IconComponents/emailSend.svg";

//Functions
export default function ProductosElegidosModal(props) {
  return (
    <RequestModal>
      <div className="transparent-background">
        <ModalContainer>
          <CloseButton>
            <button
              className="close"
              onClick={() => props.close(false)}
            ></button>
          </CloseButton>

          <div className="product-delivered-icon">
            <img src={emailSend} alt="Icon" />
          </div>

          <Title>
            <h2>Tus productos se están procesando.</h2>
            <p>
              Te compartiremos una liga de descarga por correo electrónico al
              terminar.
            </p>
          </Title>

          <FormActiveContainer>
            <ButtonContainer className="btn-container">
              <button
                onClick={() => {
                  props.close(false);
                }}
                className="close-button"
              >
                Cerrar
              </button>
            </ButtonContainer>
          </FormActiveContainer>
        </ModalContainer>
      </div>
    </RequestModal>
  );
}
