import React, { useState } from "react";
import GenericModal from "../../../../components/GenericModal/index";

import { TableCell } from "./styles";
import ReactQuill from "react-quill";
import { CheckboxCustom } from "../../../../components/customInputs/checkboxCustom";

export const DinamicCell = (props) => {
  const [quillState, setQuillState] = useState({});
  const item = JSON.parse(sessionStorage.getItem("multipleEditionList"))[
    props.rIndex
  ];
  const { navSelected } = props;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const errorData = useState({});
  const [showError, setShowError] = useState(false);
  const [inputText, setInputText] = useState({
    value: props.value?.value ? props.value?.value : "",
  });
  const optionList = JSON.parse(props.value?.option_list || "[]");
  const [charsCounter, setCharsCounter] = useState(0);
  const [maxLength] = useState(
    props.value?.max_chars ? props.value?.max_chars : 999
  );
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const [selection, setSelection] = useState(false);
  const assigned = !(
    user.id_user === item?.article[`id_${navSelected}_especialist`] ||
    user.id_user === item?.article[`id_${navSelected}_facilitator`] ||
    user.id_user === item?.article?.id_auditor ||
    user.id_role === 1
  );

  // {
  //   articleId: articleId,
  //   versionId: version,
  //   attributeId: inputId,
  //   value: generalValue,
  // }

  const handleDatasheetsChange = (e) => {
    const val =
      props.value.type === "Booleano" ? e.target.checked : e.target.value;
    setInputText({ value: val });
    const datasheetsTemporal = props.updatedDatasheets.slice();
    const attributeId = props.value?.id_attribute;

    const inputData = {
      articleId: item.article.id_article,
      versionId: item.version,
      attributeId: attributeId,
      value: val,
    };

    const articleIndex = datasheetsTemporal?.findIndex(
      (ds) => ds.articleId === item.article.id_article
    );
    if (articleIndex === -1) {
      datasheetsTemporal.push({
        articleId: item.article.id_article,
        articleData: [inputData],
        orderId: item.article.id_order,
        version: item.version,
      });
    } else {
      const articleToUpdate = { ...datasheetsTemporal[articleIndex] };
      const inputIndex = articleToUpdate.articleData?.findIndex(
        (ad) => ad.attributeId === attributeId
      );
      if (inputIndex === -1) {
        articleToUpdate.articleData.push(inputData);
      } else {
        articleToUpdate.articleData[inputIndex] = inputData;
      }
      datasheetsTemporal[articleIndex] = articleToUpdate;
    }
    props.setUpdatedDatasheets(datasheetsTemporal);
  };

  const setQuill = (ref) => {
    if (!ref || ref === null) return;
    if (typeof ref.getEditor !== "function") return;
    setQuillState(ref.getEditor());
  };

  const getValue = ({ value }) => {
    let temp = value?.replace(/\n/gm, "<br>");
    return temp;
  };

  const valueFormater = (value) => {
    return value.replace(/<\/p><p>/gm, "\n").replace(/<\/?br>|<\/?p>/gm, "");
  };

  const onChange = (valueInput, delta, user, h) => {
    let value = "";
    try {
      if (h.getLength() - 1 <= maxLength) {
        let unformattedText = valueInput
          .replace(/<li.*?>/gm, "   - ")
          .replace(/<\/li>/gm, "\n")
          .replace(/<\/p><p>/gm, "\n")
          .replaceAll(/<\/?(?!\/?strong).*?>/gm, "");
        setInputText({ value: valueInput });
        value = unformattedText;
      } else {
        quillState.deleteText(maxLength, quillState.getLength());
        setInputText((val) => (val = quillState.getText()));
        value = quillState.getText();
      }
    } catch (error) {
      console.log(error);
    }

    setCharsCounter(h.getLength() - 1);
    value = valueFormater(value);

    const descriptionsTemporal = props.updatedDescriptions.slice();
    const attributeId = props.value?.id_description;

    const inputData = {
      articleId: item.article.id_article,
      versionId: item.version,
      attributeId: attributeId,
      value: value,
    };

    const articleIndex = descriptionsTemporal?.findIndex(
      (ds) => ds.articleId === item.article.id_article
    );
    if (articleIndex === -1) {
      descriptionsTemporal.push({
        articleId: item.article.id_article,
        articleData: [inputData],
        orderId: item.article.id_order,
        version: item.version,
      });
    } else {
      const articleToUpdate = { ...descriptionsTemporal[articleIndex] };
      const inputIndex = articleToUpdate.articleData?.findIndex(
        (ad) => ad.attributeId === attributeId
      );
      if (inputIndex === -1) {
        articleToUpdate.articleData.push(inputData);
      } else {
        articleToUpdate.articleData[inputIndex] = inputData;
      }
      descriptionsTemporal[articleIndex] = articleToUpdate;
    }
    props.setUpdatedDescriptions(descriptionsTemporal);
  };

  const getSelection = (range, a, b) => {
    //getBounds(index,length)
    const selected = b.getSelection();
    const bounds = b.getBounds(selected?.index || 0, selected?.length || 0);
    if (a === "user") {
      setPosition({ left: bounds.left, top: bounds.top });
      setSelection(selected?.length > 0);
    }
  };

  const getCheckboxValue = (value) => {
    switch (value) {
      case "true":
        return true;
      case "false":
        return false;
      default:
        return value;
    }
  };

  const renderInput = (inputType) => {
    if (optionList.length > 0) {
      return (
        <select
          onChange={(e) => handleDatasheetsChange(e)}
          defaultValue={inputText.value}
        >
          <option value="">{props.value?.description}</option>
          {optionList.map((element, index) => (
            <option key={element + "-" + index} value={element}>
              {element}
            </option>
          ))}
        </select>
      );
    } else {
      switch (inputType) {
        case "Numérico":
          return (
            <input
              onChange={(e) => handleDatasheetsChange(e)}
              value={inputText?.value}
              placeholder="-"
              disabled={assigned}
              type="number"
            />
          );
        case "Booleano":
          return (
            <CheckboxCustom
              defaultChecked={getCheckboxValue(inputText.value)}
              onChange={(e) => handleDatasheetsChange(e)}
              id={`${props.rIndex}-${props.index}`}
              htmlFor={`${props.rIndex}-${props.index}`}
            />
          );

        default:
          return (
            <input
              onChange={(e) => handleDatasheetsChange(e)}
              value={inputText?.value}
              placeholder="-"
              disabled={assigned}
              type={props.value?.type}
            />
          );
      }
    }
  };
  return (
    <>
      <TableCell
        key={props.index}
        required={props.value?.required > 0}
        selection={selection}
        position={position}
      >
        {!props.value ? (
          <div className="null-container">
            <p>-</p>
          </div>
        ) : props.navSelected === "datasheet" ? (
          renderInput(props.value.type)
        ) : (
          <ReactQuill
            ref={(el) => setQuill(el)}
            id={"description-" + props.index}
            value={getValue(inputText)}
            readOnly={assigned}
            modules={{ toolbar: ["bold"] }}
            onKeyPress={(e) => {
              if (charsCounter >= maxLength && e.key !== "Backspace") {
                e.preventDefault();
              }
            }}
            onKeyDown={(e) => {
              let keysArray = [
                "Backspace",
                "Meta",
                "ArrowLeft",
                "ArrowRight",
                "ArrowUp",
                "ArrowDown",
              ];
              if (
                charsCounter >= maxLength &&
                keysArray.every((key) => e.key !== key)
              ) {
                e.preventDefault();
              }
            }}
            onChange={(valueInput, user, range, h) => {
              onChange(valueInput, user, range, h);
            }}
            onChangeSelection={getSelection}
          />
        )}
      </TableCell>

      {showError && (
        <GenericModal
          close={() => {
            setShowError(false);
          }}
          message={errorData.message}
          detail={errorData.detail}
          img={errorData.icon}
          textArea={false}
          button1={{
            name: "Regresar",
            action: () => window.location.reload(),
          }}
        />
      )}
    </>
  );
};
