import styled from 'styled-components'

export const ContainerCard = styled.div`
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 0.5rem;
    max-width: 280px;
    height: 34vh;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
    cursor:pointer;

    :hover{
        transform: scale(1.05);
        transition: transform 0.3s ease;
    }

    .title {
        margin-bottom: 0.5rem;
        font-size: 2.2vh;
        font-weight: 700;
        line-height: 1.2;
        color: #333333;
    }

    .description {
        margin-bottom: 0.5rem;
        font-size: 1.8vh;
        font-weight: 500;
        line-height: 1.2;
        color: #707070;
    }

    .card-image{
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        max-width: 100%; 
        height: auto;
    }

    .p-5 {
        padding: 1.25rem;
    }

    @media (max-width: 768px) {
        max-width: 100%; 
        .title {
            font-size: 3vw;
        }
    }

    @media (max-width: 480px) {
        .title {
            font-size: 4vw;
        }
    }
`;
