import styled from "styled-components";

export const AssignContainer = styled.div`
  .img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }
`;

export const InputDiv = styled.div`
  padding-top: 5px;
  display: flex;
  background: #f0eef2;
  border: 1px solid #d4d1d7;
  box-sizing: border-box;
  border-radius: 6px;
  width: 177px;
  height: 40px;
  position: absolute;
  left: ${(props) => !props.shownLeft && "70%"};
  top: 0;
  right: ${(props) => props.shownLeft && "70%"};
  z-index: 2;
  align-items: center;
  padding: 0 5px;

  img {
    height: 25px;
    width: 25px;
  }
  .inputSearch {
    color: #503d66;
    font-size: 13px;
  }
  .inputSearch2 {
    border: none;
    background-color: transparent;
    margin-top: 0px;
    width: 105px;
    margin-right: 5px;
  }
`;

export const OptionsContainer = styled.div`
  background: #f0eef2;
  border: 1px solid #d4d1d7;
  box-sizing: border-box;
  border-radius: 6px;
  width: 177px;
  height: 160px;
  position: absolute;
  left: ${(props) => !props.shownLeft && "70%"};
  right: ${(props) => props.shownLeft && "70%"};
  top: 45px;
  z-index: 2;
  padding: 5px 5px;
  overflow-y: scroll;
  .imageInput {
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
  :hover {
    cursor: default;
  }
`;

export const MainContainer = styled.div``;
export const ContainerItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  :hover {
    cursor: pointer;
    background-color: lightgray;
  }
`;
