import { Auth } from "aws-amplify";
import React, { useState, useEffect } from "react";

import {
  BrowserRouter as Router,
  Link,
  NavLink,
  useHistory,
} from "react-router-dom";
import ReactImageFallback from "react-image-fallback";

// Own components
import PersonalData from "../../components/personalData/PersonalData";
import CompanyData from "../../components/companyData/CompanyData";
import ChangePassword from "../../components/changePassword/ChangePassword";
import AddPeople from "../../components/addPeople/AddPeople";

import {
  MainContainer,
  LeftContainer,
  RoleBadge,
  Header,
  MainData,
  DataItem,
  Navbar,
  RightContainer,
  Alert,
} from "./styles";
// SVG assets
import defaultProfile from "../../assets/defaultImages/defaultProfile.png";
import spinner from "../../assets/defaultImages/Spinner.gif";
import arrow from "../../assets/IconComponents/ArrowSeeSection.svg";
import addPeople from "../../assets/IconComponents/addPeople.svg";
import company from "../../assets/IconComponents/company.svg";
import email from "../../assets/IconComponents/email.svg";
import location from "../../assets/IconComponents/location.svg";
import logout from "../../assets/IconComponents/logout.svg";
import password from "../../assets/IconComponents/password.svg";
import phone from "../../assets/IconComponents/phone.svg";
import profile from "../../assets/IconComponents/profile.svg";
import closeWhite from "../../assets/IconComponents/closeWhite.svg";
import infoWhite from "../../assets/IconComponents/infoWhite.svg";

import { getRoleUser } from "../../_utils/helper";

export default function Profile() {
  const history = useHistory();
  const [child, setChild] = useState(<PersonalData />);
  let user = JSON.parse(sessionStorage.getItem("user"))[0]
    ? JSON.parse(sessionStorage.getItem("user"))[0]
    : JSON.parse(sessionStorage.getItem("user"));
  useEffect(() => {
    switch (window.location.pathname) {
      case "/profile/company-data":
        setChild(<CompanyData />);
        break;
      case "/profile/change-pass":
        setChild(<ChangePassword history={history} />);
        break;
      case "/profile/add-people":
        setChild(<AddPeople />);
        break;
      default:
        setChild(<PersonalData />);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);
  return (
    <Router>
      <MainContainer>
        <LeftContainer>
          <RoleBadge>{getRoleUser(user.id_role)}</RoleBadge>
          <Header>
            <ReactImageFallback
              src={user.src}
              fallbackImage={defaultProfile}
              initialImage={spinner}
              alt="avatar"
              className="avatar"
            />
            <div>
              <p id="title">{`${user.name} ${user.last_name}`}</p>
              <p>{user.position}</p>
            </div>
          </Header>

          <MainData>
            <DataItem>
              <figure>
                <img src={email} alt="data-icon"></img>
              </figure>
              <div className="info-container">
                <p id="bolder">Correo electrónico</p>
                <p>{user.email}</p>
              </div>
            </DataItem>
            <DataItem>
              <figure>
                <img src={phone} alt="data-icon"></img>
              </figure>
              <div className="info-container">
                <p id="bolder">Teléfono</p>
                <p>{user.telephone}</p>
              </div>
            </DataItem>
            <DataItem>
              <figure>
                <img src={location} alt="data-icon"></img>
              </figure>
              <div className="info-container">
                <p id="bolder">Ubicación</p>
                <p>{`${user.country}`}</p>
              </div>
            </DataItem>

            <Navbar>
              <NavbarItem
                img={{ src: profile, alt: "menu-icon" }}
                title="Información personal"
                path="/profile"
                history={history}
              />
              <NavbarItem
                img={{ src: company, alt: "menu-icon" }}
                title="Información de la empresa"
                path="/profile/company-data"
                history={history}
              />
              <NavbarItem
                img={{ src: password, alt: "menu-icon" }}
                title="Cambiar contraseña"
                path="/profile/change-pass"
                history={history}
              />

              {/* SHOW ONLY TO ADMIN */}
              {user.id_role === 1 && (
                <NavbarItem
                  img={{ src: addPeople, alt: "menu-icon" }}
                  title="Agregar personas"
                  path="/profile/add-people"
                  history={history}
                />
              )}

              <NavbarItem
                img={{ src: logout, alt: "menu-icon" }}
                title="Cerrar sesión"
                notMenu
                path="/logout"
                history={history}
              />
            </Navbar>
          </MainData>
        </LeftContainer>
        <RightContainer>{child}</RightContainer>
      </MainContainer>
    </Router>
  );
}

function NavbarItem(props) {
  return (
    <li>
      <NavLink
        exact
        activeClassName="active"
        to={props.path ? props.path : ""}
        onClick={async (event) => {
          if (props.path === "/logout") {
            try {
              await Auth.signOut({ global: true });
              sessionStorage.clear();
              props.history.push({
                pathname: "/login",
              });
              window.location.reload();
            } catch (error) {}
          } else {
            props.history.push({
              pathname: props.path,
            });
          }
        }}
      >
        <div id="leftContainer">
          <figure>
            <img src={props.img.src} alt={props.img.alt} />
          </figure>
          <p>{props.title}</p>
        </div>
        {!props.notMenu && (
          <figure id="arrow">
            <img src={arrow} alt="open-menu" />
          </figure>
        )}
      </NavLink>
    </li>
  );
}
