import React, { useState } from "react";
import { Row, LongItem, ConfirmationRowButton, Button } from "./styles";
import { Auth } from "aws-amplify";

const ResetPassword = (props) => {
  const [emptyEmail, setEmptyEmail] = useState(true);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [awsError, setAwsError] = useState("");

  const validate = async (e) => {
    let valid = true;
    setShowErrors(true);
    e.preventDefault();
    const email = document.querySelector("#emailInput").value;
    email === "" ? setEmptyEmail(true) : setEmptyEmail(false);
    !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email
    )
      ? setInvalidEmail(true)
      : setInvalidEmail(false);

    emptyEmail && (valid = false);
    invalidEmail && (valid = false);

    if (valid) {
      try {
        await Auth.forgotPassword(email);
        sessionStorage.setItem("email", JSON.stringify(email));
        sessionStorage.setItem("resetPasswordProcess", JSON.stringify("true"));
        props.setPaso(5);
      } catch (err) {
        setAwsError(err.code);
      }
    } else {
      console.log("invalid email");
    }
  };

  return (
    <>
      <Row>
        <LongItem>
          <input id="emailInput" type="text" />
          {showErrors && emptyEmail && <label>Ingrese su correo</label>}
          {showErrors && invalidEmail && !emptyEmail && (
            <label>Ingrese un correo valido</label>
          )}
          {showErrors && awsError === "LimitExceededException" && (
            <label>
              Has cambiado la contraseña recientemente, intenta despues
            </label>
          )}
        </LongItem>
      </Row>

      <ConfirmationRowButton>
        <Button type="submit" onClick={(e) => validate(e)}>
          Envíar
        </Button>
      </ConfirmationRowButton>

      <Row>
        <label onClick={() => props.setPaso(7)} className="changeEmail">
          Regresar...
        </label>
      </Row>
    </>
  );
};

export default ResetPassword;
