import React, { useState, useEffect } from "react";
import styled from "styled-components";

//Icon imports
import fs from "../../../assets/IconComponents/fullscreen.svg";
import roundedchecked from "../../../assets/IconComponents/rounded-checked.svg";
import ReactImageFallback from "react-image-fallback";
import { getProfilePicture } from "../../../_utils/helper";
import imgDefault from "../../../assets/defaultImages/defaultProfile.png";

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  width: 100%;
  height: 100%;
`;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;

  .topBar {
    display: flex;
    height: 30px;
    width: 100%;
    box-sizing: border-box;
    background-color: #3b1366;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;

    .asig-container {
      display: flex;
      align-items: center;

      .first-letter {
        width: 20px;
        height: 20px;
        background-color: #71de56;
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
        border-radius: 50%;
      }

      .rounded-photo {
        width: 21px;
        height: 21px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 21px;
          height: 21px;
          object-fit: cover;
        }
      }

      .short-text {
        font-family: Avenir Next;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #d4d1d7;
      }

      * + * {
        margin-left: 5px;
      }
    }

    > figure:hover {
      cursor: pointer;
    }
  }
`;
const ScrollerImg = styled.div`
  width: 100%;
  height: 100%;
`;

const ImageItem = styled.figure`
  box-sizing: border-box;
  border-radius: 4px 4px 0px 0px;
  height: 100%;
  width: 100%;
  :hover {
    cursor: pointer;
  }
  > figure {
    height: 40px;
    width: 60px;
    min-width: 25%;
    > img {
      object-fit: scale-down;
      height: 100%;
      width: 100%;
    }
  }
  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    box-sizing: border-box;
    > p:last-child {
      font-size: 10px;
    }
  }
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.015em;
    color: #969696;
  }
`;

export default function CarrouselImg({
  images,
  seleccionado,
  hideTopBar,
  setDataImages,
  namesList,
  product,
  setImgFullScreen
}) {
  return (
    <MainContainer>
      {!hideTopBar && (
        <>
          <div className="topBar">
            <div className="asig-container">
              <figure className="rounded-photo">
                <ReactImageFallback
                  src={getProfilePicture(
                    product.article.id_images_especialist,
                    30,
                    30
                  )}
                  fallbackImage={imgDefault}
                  alt=""
                />
              </figure>

              <figure className="rounded-photo">
                <ReactImageFallback
                  src={getProfilePicture(
                    product.article.id_images_facilitator,
                    30,
                    30
                  )}
                  fallbackImage={imgDefault}
                  alt=""
                />
              </figure>

              <p className="short-text">Asig.</p>

              <figure className="checked-icon">
                <img src={roundedchecked} alt="" />
              </figure>
            </div>

            <figure>
              <img src={fs} alt="" onClick={() => setImgFullScreen(true)} />
            </figure>
          </div>
        </>
      )}

      <ScrollerImg>
        <ImageList
          seleccionado={seleccionado}
          images={images}
          setDataImages={setDataImages}
          namesList={namesList}
        />
      </ScrollerImg>
    </MainContainer>
  );
}

const ImageList = ({ images, seleccionado, setDataImages, namesList }) => {
  const [data, setData] = useState([]);

  const onLoad = (item) => {
    const img = new Image();
    img.src = item.src;
    const name =
      namesList &&
      namesList.find((e) => e.id === item.image_id) &&
      namesList.find((e) => e.id === item.image_id).name;
    setData((prev) => [
      ...prev,
      { name, dimension: `${img.width}x${img.height}` },
    ]);
  };

  useEffect(() => {
    if (data.length > 0) {
      if (setDataImages) {
        setDataImages(data);
      }
    }
  }, [data, setDataImages]);

  const renderImage = (image, index) => {
    return (
      <div key={index}>
        <div>
          <ImageItem onClick={() => seleccionado(image)}>
            <figure>
              <img alt="img" src={image.src} onLoad={() => onLoad(image)} />
            </figure>
          </ImageItem>
        </div>
      </div>
    );
  };

  return <ImageContainer>{images.map(renderImage)}</ImageContainer>;
};
