import React, { useEffect, useState } from "react";
import spinner from "../../assets/defaultImages/Spinner.gif";
import defaultProfile from "../../assets/defaultImages/defaultProfile.png";
import alertIcon from "../../assets/IconComponents/alertIcon.svg";
import IconClose from "../../assets/IconComponents/IconClose";

import ReactImageFallback from "react-image-fallback";

import {
  AssignContainer,
  InputDiv,
  OptionsContainer,
  ContainerItem,
} from "./styles";
import { MainContainer } from "./styles";
import { getProfilePicture } from "../../_utils/helper";

export default function AsignationControl(props) {
  const targetData = () => {
    if (props.target === "auditor") {
      return "id_auditor";
    } else {
      return `id_${props.concept}_${props.target}`;
    }
  };

  const [show, setShow] = useState(false);
  const [usersAvailable, setUsersAvailable] = useState(props.userGroup);
  const [filteredUsers, setFilteredUsers] = useState(props.userGroup);
  const user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    setFilteredUsers(props.userGroup);
    setUsersAvailable(props.userGroup);
    setCurrentAssignation(
      props.userGroup.find(
        (item) => item.id_user === props.item?.article[targetData()]
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userGroup]);

  const closePopUp = (e) => {
    if (
      (!e.target.closest(`.${props.modalClass}`) && show) ||
      e.target.closest("#close-popup")
    ) {
      setTimeout(() => {
        window.removeEventListener("click", closePopUp, false);
      }, 500);
      setShow(false);
    }
  };

  useEffect(() => {
    if (show) window.addEventListener("click", closePopUp, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const [currentAssignation, setCurrentAssignation] = useState({});

  const filterUserList = (filterValue) => {
    if (filterValue.length > 0) {
      const listToFilter = usersAvailable.slice();
      setFilteredUsers(
        listToFilter.filter((user) =>
          `${user.name} ${user.last_name}`
            .toLowerCase()
            .includes(filterValue.toLowerCase())
        )
      );
    } else {
      setFilteredUsers(usersAvailable);
    }
  };

  return (
    <MainContainer key={props.key}>
      {/* Check if task has been already assigned and set initial picture */}
      <AssignContainer
        className="boxMain2"
        onClick={() => {
          if (props.item[`${props.concept}_status`] !== "NS")
            setTimeout(() => {
              setShow(true);
            }, 100);
        }}
      >
        <ReactImageFallback
          id={`assign-control-image-${props.item?.orderId}-${props.item?.article?.id_article}`}
          src={getProfilePicture(currentAssignation?.id_user, 40, 40)}
          fallbackImage={defaultProfile}
          initialImage={spinner}
          className="img"
        />
      </AssignContainer>
      {show && ![2, 3, 7, 8].includes(user.id_role) ? (
        <div
          onClick={(evt) => {
            evt.stopPropagation();
          }}
          className={props.modalClass}
        >
          <InputDiv shownLeft={props.shownLeft} className="divInputSearch2">
            <ReactImageFallback
              src={getProfilePicture(currentAssignation?.id_user, 40, 40)}
              fallbackImage={defaultProfile}
              initialImage={spinner}
              alt="profile13"
              className="imageInput"
            />
            {/** This input shows the currently assigned user name */}
            <input
              type="text"
              className="inputSearch2"
              placeholder={
                currentAssignation
                  ? `${currentAssignation?.name} ${currentAssignation?.last_name}`
                  : "Sin asignación"
              }
              onChange={(e) => filterUserList(e.target.value)}
              onKeyDown={(e) => {
                e.key === "Enter" &&
                  usersAvailable[0] &&
                  props.assignUser(
                    props.item,
                    usersAvailable[0]?.id_user,
                    targetData(),
                    props.concept,
                    props.target
                  );
              }}
            />
            <div
              id="close-popup"
              onClick={() => {
                window.removeEventListener("click", closePopUp, false);
                setShow(false);
              }}
            >
              <IconClose />
            </div>
          </InputDiv>
          <OptionsContainer
            shownLeft={props.shownLeft}
            className="divBoxOptions2"
          >
            {filteredUsers.map((item, index2) => (
              <ContainerItem
                className="dataAsign"
                key={index2}
                onClick={(evt) => {
                  props.assignUser(
                    props.item,
                    item?.id_user,
                    targetData(),
                    props.concept,
                    props.target
                  );
                  setCurrentAssignation(item);
                  window.removeEventListener("click", closePopUp, false);
                  setShow(false);
                }}
              >
                <ReactImageFallback
                  src={getProfilePicture(item.id_user, 40, 40)}
                  fallbackImage={defaultProfile}
                  initialImage={spinner}
                  alt="profile13"
                  className="imageInput"
                />
                <p className="textItem2">{`${item.name} ${item.last_name}`}</p>
              </ContainerItem>
            ))}

            {filteredUsers.length === 0 && (
              <div className="textNoData2">
                <ReactImageFallback
                  src={alertIcon}
                  fallbackImage={defaultProfile}
                  initialImage={spinner}
                  alt="alert"
                  className="imageInput"
                />
                <p>Sin coincidencias</p>
              </div>
            )}
          </OptionsContainer>
        </div>
      ) : null}
    </MainContainer>
  );
}
