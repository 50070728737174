import styled from "styled-components";

export const VirtualizedContainer = styled.div`
  overflow: auto;
  padding: 20px;
  padding-right: 40px;
  .table-products {
    .table-column {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      box-sizing: border-box;

      &:first-child {
        div {
          width: 30px;
          height: 30px;
          padding: 0;

          input {
            margin: auto;
          }
        }
      }

      & + .table-column {
        border-left: 1px solid #f0eef2;
      }
    }
    .table-row {
      cursor: pointer;

      &:nth-child(odd) {
        background-color: #fafafa;
      }

      &:hover {
        background-color: #d4d1d7;
      }
    }

    .ReactVirtualized__Grid {
      will-change: auto !important;

      .ReactVirtualized__Grid__innerScrollContainer {
        overflow: unset !important;
      }
    }
  }

  .container-general {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 14.06px;
    text-align: left;
    color: #707070;
    h1{
      font-weight: 600;
      font-size: 18px;
    }
    p{
      font-weight: 400;
    }
  }

  .container-videos {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 20px;
  }

  .input-search {
    padding: 0.5rem 1rem;
    border: 1px solid #f0f0f0;
    border-radius: 17px;
    outline: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 20px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
    text-align: left;
  }

  .input-search:focus {
    border-color: #3b82f6;
    box-shadow: 0 0 0 0.125rem rgba(59, 130, 246, 0.25);
  }
`;
