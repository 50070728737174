import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

// Styled components
import {
  MainContainer,
  Title,
  SubTitle,
  Container,
  Icon,
  Button,
  Table,
  TableHeader,
  TableCol,
  TableRow,
  TableContainer,
  ProductCount,
  ButtonScan,
  ButtonContainer,
} from "./styles.js";

//FUNCTIONS
import {
  getStatusColor,
  getRegionFlag,
  getLongStatus,
  getPrio,
} from "../../_utils/helper";

// SVG
import searchIcon from "../../assets/IconComponents/search.svg";
import optionIcon from "../../assets/IconComponents/option-circle.svg";
import scanIcon from "../../assets/IconComponents/scanIcon.svg";

// Components
import InputSearch from "../../components/customInputs/InputSearch";
import ExportAlert from "../../components/alerts/exportAlert";
import Loading from "../../components/loading/index";
import StatusOrders from "../../components/statusTable/StatusOrders.js";

const OrderDetails = (props) => {
  const headers = [
    "Reg.",
    "Orden",
    "UPC",
    "Nombre",
    "Proveedor",
    "Estatus",
    "",
    "Prio",
  ];

  const [modalView, setModalView] = useState(null);
  const [productsList, setproductsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState();
  const [articlesSelected, setArticlesSelected] = useState([]);
  const { orderId } = useParams();
  useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProducts = async () => {
    setLoading(true);
    const ordersResponse = await axios.get(
      `${process.env.REACT_APP_ORDER_ENDPOINT}?orderId=${orderId}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );

    const order = JSON.parse(ordersResponse.data.body).data;
    setOrderData(order[0]);

    const productsResponse = await axios.get(
      `${process.env.REACT_APP_ORDERED_SERVICES_ENDPOINT}?receptionDetails=true&orderId=${orderId}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );

    const products = JSON.parse(productsResponse.data.body).data;
    setproductsList(Object.values(products || {}));
    setLoading(false);
  };

  const handleProducts = (productId, orderId) => {
    let products = articlesSelected.slice();
    if (products.find((id) => id === productId) === undefined) {
      products.push(productId);
    } else {
      const index = products.indexOf(productId);
      products.splice(index, 1);
    }
    setArticlesSelected(products);
  };

  const recibirProducto = async (articlesArray, multipleArticles) => {
    const data = {
      articles: articlesArray,
      orderId: orderData.order.id_order,
    };
    const res = await axios.put(process.env.REACT_APP_ARTICLE_ENDPOINT, data, {
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });

    if (res.data.statusCode === 200) {
      let tempProducts = productsList.slice();
      articlesArray.forEach((articleId) => {
        const item = tempProducts.find((prod) => prod.articleId === articleId);
        const index = tempProducts.indexOf(item);
        tempProducts[index].status = "PA";
        multipleArticles &&
          (document.querySelector(`#check-${articleId}`).checked = false);
      });
      setproductsList(tempProducts);
      multipleArticles && setArticlesSelected([]);
    }
  };

  /*** Check All Articles ****/

  const checkAllArticles = useCallback(
    (evt) => {
      const chks = document.querySelectorAll(".chk-articles");
      chks.forEach((ch) => (ch.checked = evt.target.checked));
      if (evt.target.checked) {
        let listP = productsList.map((idProduct) => {
          return idProduct.articleId;
        });
        setArticlesSelected(listP);
      } else {
        setArticlesSelected([]);
      }
    },
    [productsList]
  );

  return (
    <MainContainer>
      <Container className="main-container">
        <Container className="title-container">
          <Title>Lista de Órdenes</Title>
          <SubTitle>
            En esta lista encontrarás todas las ordenes solicitadas a contentoh.
          </SubTitle>
        </Container>
        <Container className="cards-container">
          <ProductCount color="#E67432">
            <p className="title">Por recibir</p>
            <p className="amount">13</p>
          </ProductCount>

          <ProductCount color="#ED9A4D">
            <p className="title">Recibidos</p>
            <p className="amount">4</p>
          </ProductCount>

          <ProductCount color="#F0EEF2" bg="#F0EEF2">
            <p className="title">En proceso</p>
            <p className="amount">34</p>
          </ProductCount>
        </Container>
      </Container>
      <Container
        id="button-container"
        padding=" 20px 0px"
        justifycontent="flex-end"
      >
        <InputSearch id="search" icon={searchIcon} alt="Buscar" />
        <Icon>
          <img src={optionIcon} alt="Opción" />
        </Icon>
        <div className="export-button-container">
          <Button
            disabled={articlesSelected.length === 0}
            className="receive-button"
            onClick={() => {
              if (articlesSelected.length !== 0) {
                recibirProducto(articlesSelected, true);
              }
            }}
          >
            Recibir
          </Button>
          <ExportAlert modalView={modalView} setModalView={setModalView} />
        </div>
      </Container>
      <TableContainer>
        {!loading ? (
          <Table>
            {/*TABLE HEADERS*/}
            <TableHeader>
              <input
                id="chk-allChecks"
                type="checkbox"
                onClick={(evt) => {
                  checkAllArticles(evt);
                }}
              />
            </TableHeader>
            {headers.map((header) => (
              <TableHeader>{header}</TableHeader>
            ))}

            {productsList.length > 0 &&
              productsList.map((product) => (
                <TableRow
                  onClick={(evt) => {
                    evt.stopPropagation();
                  }}
                >
                  <TableCol>
                    <input
                      className="chk-articles"
                      onClick={(evt) => {
                        evt.stopPropagation();
                        handleProducts(product.articleId);
                        if (evt.target.checked === false) {
                          const chkAllArticles = document.querySelector(
                            'input[id="chk-allChecks"]'
                          );
                          chkAllArticles.checked = false;
                        }
                      }}
                      type="checkbox"
                      id={`check-${product.articleId}`}
                    />
                  </TableCol>

                  {orderData && (
                    <TableCol width={"3.9%"} minw="50px">
                      <figure>
                        <img
                          className="image"
                          src={getRegionFlag(product?.article?.country)}
                          alt={product?.article?.country || ""}
                        />
                      </figure>
                    </TableCol>
                  )}

                  {orderData && (
                    <TableCol width={"5%"} minw="78px">
                      {product.orderId}
                    </TableCol>
                  )}

                  <TableCol width={"7%"} minw="102px">
                    {product.article.upc}
                  </TableCol>

                  <TableCol width={"10%"} minw="91px">
                    {product.article.name}
                  </TableCol>

                  <TableCol width={"10%"} minw="102px">
                    {product.article.company_name}
                  </TableCol>

                  <TableCol width={"7%"} noLastBorder minw="90px">
                    <StatusOrders
                      borderRadius={"10px"}
                      width={"95%"}
                      status={getLongStatus(product.status)}
                      color={getStatusColor(product.status)}
                    />
                  </TableCol>

                  <TableCol noBorder scan width={"63%"}>
                    <ButtonContainer>
                      <ButtonScan
                        disabled={product.status === "PA"}
                        onClick={(evt) => {
                          evt.stopPropagation();
                          //SEND ARRAY FOR FUNCTION ALSO WORKING WITH MULTIPLE SELECTION
                          recibirProducto([product.articleId]);
                        }}
                      >
                        <figure>
                          <img src={scanIcon} alt="" />
                        </figure>
                        <p>Escanear UPC</p>
                      </ButtonScan>
                    </ButtonContainer>
                  </TableCol>
                  <TableCol minw={"60px"}>
                    <figure>
                      <img src={getPrio(product.prio)} alt="" />
                    </figure>
                  </TableCol>
                </TableRow>
              ))}
          </Table>
        ) : (
          <Loading />
        )}
      </TableContainer>
    </MainContainer>
  );
};

export default OrderDetails;
