import styled from "styled-components";

export const MainContainer = styled.div`
  box-sizing: border-box;
  height: calc(100% - 100px);
  width: 100%;
  margin-right: 1%;
  padding: 0.5%;
  .img{
    height: 20px;
    width: 20px;
  }
`;

export const Title = styled.p`
  font-family: Raleway;
  font-size: 30px;
  font-weight: 400;
  color: #503D66;
  line-height: 35px;
  margin-bottom: 1.3%;
`;

export const SubTitle = styled.p`
  font-family: Avenir Next;
  width: 82%;
  font-size: 18px;
  line-height: 21px;
  color: #817393;
  margin-bottom: 10.2%;
`;

export const Container = styled.div`
  display: flex;
  flex: ${(props) => props.flex};
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  justify-content: ${(props) => props.justifycontent};
  flex-direction: ${(props) => props.flexdirection};
  flex-wrap: ${(props) => props.flexwrap};
  align-self: ${(props) => props.alignself};
  .imgIcon {
    margin-right: 1rem;
  }

  &.title-container {
    flex-direction: column;
    max-width: 550px;

    & + * {
      margin-left: 100px;
    }
  }

  &.main-container {
    justify-content: space-between;
  }

  &.cards-container {
    width: 40%;
    align-items: flex-end;
    margin-bottom: 60px;
    margin-top: 30px;
    margin-right: 90px;
  }
`;
Container.defaultProps = {
  width: "100%",
  padding: "0",
  flexdirection: "row",
  justifycontent: "space-around",
  flexwrap: "nowrap",
};

export const Icon = styled.figure`
  display: flex;
  align-items: center;
  height: 80%;
  ${( { carrito } ) => carrito && `
    border-left: 1px solid #F0EEF2;
    padding-left: 18px;
    box-sizing: border-box;
  `};
  >img{
    width: ${props => props.logo ? '50px' : '30px'};
    height: ${props => props.logo ? '50px' : '30px'};
    ${( { logo } ) => logo && `
      border-radius: 50%;
      margin-right: 20px;
    `}
    ${( { carrito } ) => carrito && `
      width: 18px;
      height: 18px;
    `}
  }
`;

export const Button = styled.p`
  display: flex;
  align-items: center;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  height: ${props => props.height ? props.height : '40px'};
  padding: 0 40px;
  box-sizing: border-box;
  background-color: ${props => props.disabled ? 'transparent' : '#E33AA9'};
  border: ${props => props.disabled ? '1px solid #D4D1D7' : '#E33AA9'};
  color: ${props => props.disabled ? '#D4D1D7' : '#FFF'};
  border-radius: 40px;
  box-sizing: border-box;
  >figure{
    margin-left: 5px;
    >img{
      width: 15px;
      height: 15px;
    }
  }

  :hover{
    cursor: ${props => !props.disabled && 'pointer'};
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  max-height:300px;
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
  border-right: 1px solid #d4d1d7;
`;

export const TableContainer = styled.div`
  box-sizing: border-box;
  height: calc(100% - 260px);
  overflow-y: auto;
  width: 100%;
`;

export const TableRow = styled.tr`
  box-sizing: border-box;
  height: 30px;
  background-color: ${(props) => (props.index % 2 === 0 ? "#FAFAFA" : "white")};
  width: 100%;
  :nth-child(odd) {
    background:  #FAFAFA;
    height: 30px;
  }
  .iconDrop {
    visibility: hidden;
  };
  :hover .iconDrop{
    visibility: visible;
    cursor: pointer;
  }
  :hover{
    background-color: #D4D1D7
  }
`;

export const TableCol = styled.td`
  font-family: Avenir Next; 
  position: ${props => props.retailers && 'relative'};
  box-sizing: border-box;
  border-left: ${(props) => (props.noBorder ? "none" : "1px solid #F0EEF2")};
  border-right: ${(props) =>
    props.noLastBorder ? "none" : " 1px solid #F0EEF2"};
  vertical-align: middle;
  text-align: center;
  font-size: 13px;
  color: #817393;
  min-width: ${props => props.minw ? props.minw : '30px'};
  justify-content: ${props => props.scan && 'flex-end'};
  width: ${props => props.width && props.width};
  position: relative;
  :hover{
    cursor: ${props => props.deliver && 'pointer'};
  }
  .image{
    height: 30px;
    width: 30px;
    object-fit: contain;
  }
  
  .payment{
    height: 20px;
    width: 20px;
    object-fit: contain;
  }
  .imageInput{
    height: 20px;
    width: 20px;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .divInputSearch {
    padding-top: 5px;
    display: flex;
    background: #F0EEF2;
    border: 1px solid #D4D1D7;
    box-sizing: border-box;
    border-radius: 6px;
    width: 177px;
    height: 40px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }

  .divBoxOptions{
    background: #F0EEF2;
    border: 1px solid #D4D1D7;
    box-sizing: border-box;
    border-radius: 6px;
    position: absolute;
    width: 177px;
    height: 160px;
    left: 0;
    top: 45px;
    z-index: 2;
    overflow-y: scroll;
  }

  .dataAsign{
    display: flex;
  }

  .textItem {
    padding: 5px;
  }

  .textNoData{
    margin-top: 40px;
  }

  .dataAsign:hover{
    display: flex;
    background-color: #d4d1d7;
  }

  .inputSearch{
    border: none;
    background-color: transparent;
    margin-top: 0px;
    width: 105px;
    margin-right: 5px;
  }
`;

export const TableHeader = styled.th`
  color: #503d66;
  font-weight: 600;
  font-family: Avenir Next;
  font-size: 14px;
`;

export const ContainerChart = styled.td`
  padding-right: 25px;
`;

export const CheckboxContainer = styled.div`
  border-radius: 171px;
  padding: 16px 20px;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  max-height: 171px;
  width:534px;
  background-color: #F0EEF2;
  right:0;
  z-index: 50;
  border-radius: 7px;
  overflow-y: auto;
`;

export const Label = styled.label`
  width:25%;
  text-align: left;
  font-family: Lato;
  font-size: 11px;
  line-height: 21px;
  position: relative;
  margin-bottom: 1%;
`;

export const Row = styled.table`
  display: flex;
  justify-content:space-between;
  .Cadenas{
    padding-top: 10px;
  };
`;

export const Pending = styled.span`
  background-color: ${props => props.setted ? 'green' : 'gray'};
  box-sizing: border-box;
  padding: 5px 15px;
  border-radius: 5px;
  color:white;
`

export const DropRetailers = styled.div`
  width:13px;
  height:30px;
  border-radius: 2px;
  box-sizing: border-box;
  position: absolute;
  right: 0;
  top:0;
  /* opacity:0; */
  border: ${props => props.active ? '1px solid #E33AA9' : '1px solid #817393'} ;
  display: flex;
  align-items: center;
  justify-content: center;
  figure {
    img {
      height: 3px;
      width: 5.91px;
    }
  }
  :hover{
    cursor:pointer;
    opacity:100%;
  }
`
export const ProductCount = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align:center;
  border: ${props => props.color && `1px solid ${props.color}`};
  border-radius: 7px;
  padding:7px;
  color: #817393;
  font-family: Avenir Next;
  background-color: ${props => props.bg && props.bg};
  height: 49px;
  width:112px;
  .title{
    font-size: 11px;
  }
  .amount{
    font-size: 24px;
  }
` 
export const ButtonScan = styled.button`
  display: flex;
  width: 115px;
  height: 24px;
  border: 1px solid #817393;
  border-radius: 3px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  color: #817393;
  font-family: Lato;
  font-size: 13px;
  :hover{
    cursor:pointer;
    background: #817393;
    color:white;
  }
  >figure{
    img{
      width:14px;
      object-fit: contain;
    }
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  width:100%;
  padding: 0 2%;
` 