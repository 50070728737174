import React, { useEffect, useState } from "react";
import { OrderDetail } from "contentoh-components-library";
import { Table, Column, AutoSizer } from "react-virtualized";
import "react-virtualized/styles.css"; // only needs to be imported once
import DateRangeSelection from "../../components/dateRangePicker";
import axios from "axios";
import {
  getRegionFlag,
  getStatusColor,
  getShortStatus,
  isElementClicked,
} from "../../_utils/helper";
import moment from "moment";
import StatusOrders from "../../components/statusTable/StatusOrders";
import TableDatepicker from "../../components/tableDatepicker";
import PaymentModal from "../../components/tablePaymentModal";
import BillingModal from "../../components/tableBillingModal";
import {
  tableStyle,
  orderColumn,
  retailersColumn,
  regColumn,
  estatusColumn,
  pageFacturationColumn,
} from "../Tasks/utils/tableStyle";
import { defineFileFilters } from "../Productos_Tareas/utils/productsUtils";
import { Title, TableWarning } from "./styles";
import { updateDates, updatePayments } from "./utils/updateOrders";
import { FilterInput } from "../../components/filterInput";
import { getOrders, getOrderDetail, updateBilling } from "../../_utils/data";
import Loading from "../../components/loading";
import {
  getOrdersFilterOptions,
  getCompaniesFilterOptions,
  statusOptions,
  enabledFilters,
  filterOrders,
} from "./utils/filtersOptions";
import GenericModal from "../../components/GenericModal";
//SVGs
import sucessModal from "../../assets/IconComponents/downLoadModalIcons/downloadSucces.svg";
import errorModal from "../../assets/IconComponents/downLoadModalIcons/downloadFailed.svg";

const OrdersReception = () => {
  const [ordersList, setOrdersList] = useState([]);
  const [ordersInitialList, setOrdersInitialList] = useState([]);
  const [ordersOptions, setOrdersOptions] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [ordersLoaded, setOrdersLoaded] = useState(false);
  const [showOrderDetail, setShowOrderDetail] = useState(false);
  const [clickedOrder, setClickedOrder] = useState();
  const [errorRetrievingOrders, setErrorRetrievingOrders] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [loadingExportables, setLoadingExportables] = useState(false);
  const [reportFilters, setReportFilters] = useState({});
  const [statusCode, setStatusCode] = useState(0);
  const [dateFilter, setDateFilter] = useState();
  const [allFilters, setAllFilters] = useState([]);
  const [downloadingDetails, setDownloadingDetails] = useState(false);

  useEffect(() => {
    if (ordersList.length > 0 && !ordersLoaded) {
      setOrdersLoaded(true);
    }
  }, [ordersList, ordersLoaded]);

  useEffect(
    () => {
      const filterBundle = allFilters.slice();
      if (dateFilter) filterBundle.push(dateFilter);
      executeFilters(filterBundle);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allFilters, dateFilter]
  );

  useEffect(() => {
    const setInitialOrders = async () => {
      const ordersRetrieved = await getOrders();
      if (ordersRetrieved.length === 0) {
        setWarningMessage("Hubo un problema al recuperar las ordenes");
        setErrorRetrievingOrders(true);
      }
      setOrdersList(ordersRetrieved);
      setOrdersInitialList(ordersRetrieved);
      setOrdersOptions(getOrdersFilterOptions(ordersRetrieved));
      setCompaniesOptions(getCompaniesFilterOptions(ordersRetrieved));
    };
    setInitialOrders();
  }, []);

  const getExportables = async () => {
    setLoadingExportables(true);
    const email = encodeURIComponent(
      JSON.parse(sessionStorage.getItem("user")).email
    );
    const filtersForQuery = encodeURIComponent(JSON.stringify(reportFilters));

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ORDERS_EXPORTABLES}?email=${email}&filters=${filtersForQuery}`,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      );
      setStatusCode(response.data.statusCode);
      setLoadingExportables(false);
    } catch (error) {
      console.log(error);
    }
  };

  const executeFilters = (filters) => {
    try {
      defineFileFilters(filters, reportFilters, setReportFilters);
      if (ordersLoaded) {
        if (dateFilter) filters.push(dateFilter);
        if (filters.length > 0) {
          let listToFilter = ordersInitialList.slice();
          listToFilter = filterOrders(filters, listToFilter);
          if (listToFilter.length === 0) {
            setWarningMessage(
              "No hay ordenes registradas con esas caracteristicas"
            );
          }
          setOrdersList(listToFilter);
        } else {
          setOrdersList(ordersInitialList);
        }
      }
    } catch (err) {
      console.log(err, "There was an error handling the filters values");
      setOrdersList(ordersInitialList);
    }
  };
  const handleFilters = (filtersObject) => {
    let filters;
    if (filtersObject) {
      filters = Object.values(filtersObject);
    } else {
      filters = allFilters.slice();
    }
    setAllFilters(filters);
  };

  const updateOrdersList = (orderId, newDeliverDate) => {
    setOrdersList(
      updateDates({ ordersList: ordersList.slice(), orderId, newDeliverDate })
    );
  };

  const updatePaymentStatus = (orderId, newPaymentStatus) => {
    setOrdersList(
      updatePayments({
        ordersList: ordersList.slice(),
        orderId,
        newPaymentStatus,
      })
    );
  };

  const updateBillingStatus = (orderId, newBillingStatus) => {
    const params = {
      body: { orderId, newBillingStatus },
    };
    updateBilling(params);
    updateGeneralBilling(orderId, newBillingStatus);
  };

  useEffect(() => {
    clickedOrder && setShowOrderDetail(true);
  }, [clickedOrder]);

  const renderTable = () => {
    if (errorRetrievingOrders || ordersList.length === 0)
      return <TableWarning>{warningMessage}</TableWarning>;
    if (ordersLoaded) {
      return (
        <div style={{ width: "100%", height: "100%" }}>
          <AutoSizer>
            {({ width, height }) => (
              <Table
                {...tableStyle}
                headerHeight={40}
                width={width}
                height={height}
                rowHeight={40}
                rowCount={ordersList.length}
                onRowClick={handleRowClick}
                rowGetter={({ index }) => ordersList[index]}
              >
                <Column
                  label="Reg."
                  {...regColumn}
                  width={width * 0.2}
                  cellRenderer={({ rowData }) => (
                    <figure>
                      <img
                        style={{
                          height: 30,
                          width: 30,
                          objectFit: "contain",
                        }}
                        src={getRegionFlag(rowData.country)}
                        alt={rowData.country}
                      />
                    </figure>
                  )}
                />
                <Column
                  width={width * 0.2}
                  {...retailersColumn}
                  minWidth={"14%"}
                  label="Proveedor"
                  cellDataGetter={({ rowData }) => rowData.company_name}
                />
                <Column
                  width={width * 0.2}
                  {...orderColumn}
                  label="Orden"
                  cellDataGetter={({ rowData }) => rowData.id_order}
                />
                <Column
                  width={width * 0.2}
                  label="Num.Prod"
                  cellDataGetter={({ rowData }) => rowData.product_count}
                />
                <Column
                  width={width * 0.2}
                  label="Fecha Compra"
                  cellDataGetter={({ rowData }) =>
                    moment(rowData.timestamp).format("DD/MM/YYYY")
                  }
                />
                <Column
                  {...estatusColumn}
                  width={width * 0.2}
                  label="Estat."
                  cellRenderer={({ rowData }) => (
                    <StatusOrders
                      width={"55%"}
                      color={getStatusColor(rowData?.status)}
                      status={getShortStatus(rowData?.status)}
                    >
                      {rowData?.status}
                    </StatusOrders>
                  )}
                />
                <Column
                  width={width * 0.2}
                  label="Entrega Estimada"
                  cellRenderer={({ rowData, rowIndex }) => (
                    <TableDatepicker
                      item={{
                        key: `${rowData.id_order}-${rowData.deliver_date}`,
                        initialDate: rowData.deliver_date,
                        updateDate: updateOrdersList,
                        orderId: rowData.id_order,
                      }}
                    />
                  )}
                />
                <Column
                  width={width * 0.2}
                  label="Costo"
                  cellDataGetter={({ rowData }) =>
                    `$${rowData.total.toFixed(2)}`
                  }
                />
                <Column
                  width={width * 0.2}
                  label="Pago"
                  {...pageFacturationColumn}
                  cellRenderer={({ rowData }) => (
                    <PaymentModal
                      item={{
                        key: `${rowData.id_order || rowData.orderId}-${
                          rowData.payment
                        }`,
                        orderId: rowData.id_order || rowData.orderId,
                        payment: rowData.payment,
                        updatePayment: updatePaymentStatus,
                      }}
                    />
                  )}
                />
                <Column
                  width={width * 0.2}
                  label="Facturación"
                  {...pageFacturationColumn}
                  cellRenderer={({ rowData }) => (
                    <BillingModal
                      item={{
                        key: `${rowData.id_order || rowData.orderId}-${
                          rowData.is_billing
                        }`,
                        orderId: rowData.id_order || rowData.orderId,
                        is_billing: rowData.is_billing,
                        updateBilling: updateBillingStatus,
                      }}
                    />
                  )}
                />
              </Table>
            )}
          </AutoSizer>
        </div>
      );
    }
  };

  const handleRowClick = (order) => {
    const name = order.event.target.className;
    const class1 = name.includes("orders");
    const class2 = name.startsWith("react-datepicker");
    if (class1 || class2) {
      setClickedOrder();
    } else {
      setClickedOrder(order.rowData);
    }
    order.event.stopPropagation();
  };

  const clickOutsideToClose = (evt) => {
    if (!isElementClicked(evt, "orderDetail")) {
      document.removeEventListener("click", clickOutsideToClose);
      setShowOrderDetail(false);
      setClickedOrder();
    }
  };

  useEffect(() => {
    if (clickedOrder && !showOrderDetail) {
      setShowOrderDetail(true);
      document.addEventListener("click", clickOutsideToClose);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedOrder]);

  const updateGeneralBilling = (orderId, isBilling) => {
    const temp = ordersList.slice();
    const index = temp.findIndex((f) => f.id_order === orderId);
    temp[index].is_billing = isBilling;
    setOrdersList(temp);
  };
  const downloadDetails = async () => {
    setDownloadingDetails(true);
    const email = encodeURIComponent(
      JSON.parse(sessionStorage.getItem("user")).email
    );
    const filterForDownload = {
      filterByOrder: [clickedOrder.id_order],
    };
    const filtersForQuery = encodeURIComponent(
      JSON.stringify(filterForDownload)
    );
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_EXPORTABLES}?email=${email}&filters=${filtersForQuery}&reportType=1`,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      );
      if (response.data.statusCode === 200) {
        //console.log(response);
        //More logic here
      }
      setDownloadingDetails(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Title>Lista de ordenes recibidas</Title>
      {showOrderDetail && (
        <OrderDetail
          id="orderDetail"
          order={clickedOrder}
          isDownloading={downloadingDetails}
          download={downloadDetails}
          setShowOrderDetail={setShowOrderDetail}
          updateGeneralBilling={updateGeneralBilling}
          updatePaymentStatus={updatePaymentStatus}
          getOrderDetail={getOrderDetail}
          updateBilling={updateBilling}
        />
      )}
      <FilterInput
        charged={ordersList.length}
        total={ordersInitialList.length}
        enabledFilters={enabledFilters}
        ordersOption={ordersOptions}
        companiesOption={companiesOptions}
        statusOption={statusOptions}
        loadingExportables={loadingExportables}
        setLoadingExportables={setLoadingExportables}
        download={() => getExportables()}
        filterInputFunct={handleFilters}
        hideButtons
        csvDownload
        taskFilter
      />
      <DateRangeSelection
        handleFilters={handleFilters}
        setDateFilter={setDateFilter}
        setReportFilters={setReportFilters}
      />
      {!ordersLoaded && <Loading />}
      {renderTable(warningMessage)}
      {statusCode !== 0 && (
        <GenericModal
          message={
            statusCode === 200
              ? "Archivo .csv enviado con éxito"
              : "Error al enviar el archivo"
          }
          detail={
            statusCode !== 200 &&
            "por favor valida tu archivo .csv e inténtalo de nuevo."
          }
          img={statusCode === 200 ? sucessModal : errorModal}
          close={() => setStatusCode(0)}
          button1={{ name: "Entendido", action: () => setStatusCode(0) }}
        />
      )}
    </>
  );
};

export default OrdersReception;
