import styled from "styled-components";

export const MainContainer = styled.div`
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Section = styled.div`
  width: 95%;
  border-bottom: 1px solid #e2e2e2;
  padding: 24px 0px;
  :last-child {
    border-bottom: 0px;
  }
  p {
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #707070;
  }
`;

export const Header = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  p {
    font-family: "Avenir Next";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #503d66;
  }
`;

export const BasicData = styled(Section)`
  font-family: "Avenir Next";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.015em;
  color: #817393;

  .text_center {
    text-align: center;
  }
  .space {
    justify-content: space-between;
  }
  .align_end {
    justify-content: flex-end;
  }
  .margin_row {
    & + * {
      margin-top: 15px;
    }
  }
  .column {
    display: flex;
    flex-direction: column;

    p {
      color: #817393;

      & + * {
        margin-top: 5px;
      }
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .col {
      max-width: 23%;
      flex: 1 0 20%;
      display: flex;
      overflow: hidden;
      align-items: center;

      figure {
        & + * {
          margin-left: 10px;
        }
      }

      &:first-child {
        justify-content: center;
        align-items: center;
        overflow: inherit;
      }
      .text_center {
        text-align: center;
      }
    }
    .col1 {
      max-width: 23%;
      flex: 1 0 20%;
      display: flex;
      overflow: hidden;
      align-items: center;
      justify-content: center;
    }

    &.header {
      .col {
        min-width: 25%;
      }
    }
  }
  .center {
    justify-content: center;
    align-items: center;
  }
  input[type="email"] {
    width: 360px;
    height: 30px;
    border: 1px solid #f0eef2;
    box-sizing: border-box;
    border-radius: 7px;
    margin-right: 20px;
    ::placeholder {
      color: #d4d1d7;
    }
  }
  input[type="checkbox"]:checked {
    width: 75px;
    background-color: #e33aa9;
    color: green;
  }
  select {
    width: 116px;
    height: 30px;
    padding: 0 8px;
    border: 1px solid #e33aa9;
    border-radius: 7px;
    box-sizing: border-box;
    background: #fff;
  }
  button {
    width: 130px;
    height: 30px;
    font-size: 14px;
    line-height: 19px;
    color: #e33aa9;
    background: #fff;
    border: 1px solid #e33aa9;
    box-sizing: border-box;
    border-radius: 30px;
    :hover {
      cursor: pointer;
    }
  }
`;

export const Icon = styled.figure`
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`;

export const TitleIcon = styled.figure`
  margin: 0 15px 0 0;
  align-items: center;
`;

export const PermissionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 2%;
  box-sizing: border-box;
`;

export const CheckboxPermission = styled.div`
  display: flex;
  justify-content: flex-start;
  color: #503d66;
  font-size: 12px;
  font-family: Avenir Next;
  font-weight: 400;
  flex-wrap: wrap;
  flex: 1 0 25%;
  min-height: 30px;
  overflow: hidden;

  .checkbox-container {
    display: flex;
    align-items: center;

    input {
      & + label {
        margin-left: 4px;
      }
    }

    label {
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;

export const ErrorLabel = styled.label`
  color: red;
  font-family: Avenir Next;
  font-weight: 500;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
