import { TableRow } from "../TableRow";
import { Container } from "./styles";

export const TableBody = ({
  articlesList = [],
  retailers = {},
  productsToBilling,
  setProductsToBilling,
}) => {
  return (
    <Container>
      {articlesList?.map((article, i) => (
        <TableRow
          id={
            i +
            "-" +
            article.upc +
            "-" +
            article.articleId +
            "-" +
            article.orderId +
            "-" +
            article.retailer
          }
          key={
            i +
            "-" +
            article.upc +
            "-" +
            article.articleId +
            "-" +
            article.orderId +
            "-" +
            article.retailer
          }
          item={article}
          retailerO={retailers[article.retailer]}
          productsToBilling={productsToBilling}
          setProductsToBilling={setProductsToBilling}
        />
      ))}
    </Container>
  );
};
