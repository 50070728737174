import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Container, SaveButton } from "./styles";
import { getCategoryRetailer } from "../../_utils/data";
import GenericModal from "../GenericModal";
import editIcon from "../../assets/IconComponents/EditIcon.svg";
import saveIcon from "../../assets/IconComponents/multipleEditionIcons/saveIcon.svg";
import succesIcon from "../../assets/IconComponents/multipleEditionIcons/succesIcon.svg";
import Loading from "../../components/loading/index";

const PopUpRetailers = (props) => {
  const [retailersList, setRetailersList] = useState([]);
  const [firstExecution, setFirstExecution] = useState(true);
  const [modalStates, setModalStates] = useState({
    isOpen: false,
    message: "",
    detail: "",
    img: null,
  });
  const [loading, setLoading] = useState(false);

  const updateRetailers = useCallback(() => {
    const retailersSelected = retailersList.filter((f) => f.selected);

    if (retailersSelected.length > 0) {
      const data = {
        idArticle: props.articleId,
        newRetailers: retailersSelected.map((ret) => ret.id),
        version: props.versionId,
        idOrder: props.orderId,
      };
      axios
        .post(process.env.REACT_APP_ARTICLE_RETAILER_ENDPOINT, data, {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        })
        .then((r) => {
          let message, detail, img;
          if (r.data.statusCode === 200) {
            props.callback &&
              props.callback(props.articleId, retailersSelected);
            message = "Cadenas actualizadas con éxito";
            img = succesIcon;
          } else {
            message = "Lo sentimos ha ocurrido un error";
            detail = "Si el error persiste contacte al equipo de TI.";
          }
          setModalStates({ detail, message, isOpen: true, img });
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.articleId, props.versionId, props.orderId, retailersList]);

  const getRetailerServices = async () => {
    if (firstExecution) {
      setLoading(true);
      setFirstExecution(false);
      const [res, parseDataRetailerServices] = await Promise.all([
        getCategoryRetailer({
          query: {
            category: props.category,
          },
        }),
        axios.get(
          `${process.env.REACT_APP_RETAILERS_SERVICES}?articleId=${props.articleId}&orderId=${props.orderId}`,
          {
            headers: {
              Authorization: sessionStorage.getItem("jwt"),
            },
          }
        ),
      ]);
      const retailers = Object.values(JSON.parse(res.data.body).data).sort(
        (a, b) => {
          let fa = a.name.toLowerCase();
          let fb = b.name.toLowerCase();
          if (fa < fb) return -1;
          if (fa > fb) return 1;
          return 0;
        }
      );
      const parseDataRetailers = JSON.parse(
        parseDataRetailerServices.data.body
      );
      const { productsList } = parseDataRetailers.data;
      const idsRetailesServices = productsList.retailers.map(
        (retail) => retail.id
      );
      setRetailersList(
        retailers.map((e) => {
          if (idsRetailesServices.includes(e.id)) {
            e.selected = true;
          }
          return e;
        })
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    getRetailerServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container id="popup-component" className={props.className}>
        <div className="elements-container">
          {props.categories && (
            <div className="categories-container">
              <p>{props.categories}</p>
              <button>
                <img src={editIcon} alt="Edit icon" />
              </button>
            </div>
          )}
          <div className="retailers-container">
            {loading ? (
              <Loading />
            ) : (
              retailersList.map((retailer, index) => (
                <div key={index} className="retailer-container">
                  <input
                    type="checkbox"
                    id={"chk" + index}
                    name={"chk" + index}
                    defaultChecked={retailer.selected}
                    onChange={(evt) => {
                      const temp = retailersList.slice();
                      temp[index].selected = evt.target.checked;
                      setRetailersList(temp);
                    }}
                  />
                  <label htmlFor={"chk" + index}>{retailer.name}</label>
                </div>
              ))
            )}
          </div>
        </div>
        <SaveButton onClick={() => updateRetailers()}>
          <img src={saveIcon} alt="Guardar" />
        </SaveButton>
      </Container>
      {modalStates.isOpen && (
        <GenericModal
          close={() => setModalStates((prev) => ({ ...prev, isOpen: false }))}
          message={modalStates.message}
          detail={modalStates.detail}
          img={modalStates.img}
        />
      )}
    </>
  );
};

export default PopUpRetailers;
