export const Headers = {
  textSpecialist: [
    "Nombre del colaborador",
    "No. de productos trabajados",
    "No. de rechazos coordinador",
    "No. de rechazos auditor",
  ],
  imageSpecialist: [
    "Nombre del colaborador",
    "No. de productos trabajados",
    "No. de rechazos coordinador",
    "No. de rechazos auditor",
  ],
  auditor: [
    "Nombre del colaborador",
    "No. de productos trabajados",
    "No. de rechazos proveedor",
    "No. de rechazos cadena",
  ],
};

export const productsMock = [
  {
    datasheetSpecialist: 57,
    descriptionSpecialist: 57,
    imagesSpecialist: 55,
    datasheetCoord: 52,
    descriptionCoord: 52,
    imagesCoord: 53,
    qa: 30,
    OT: {
      productName: "Radiador Repuesto",
      orderId: 237,
    },
    progressReport: [
      { status: "RECEPTION", user: 30, movementDate: "13 de Junio 2022" },
      { status: "IN_PROGRESS", user: 57, movementDate: "13 de Junio 2022" },
      { status: "QF", user: 57, movementDate: "14 de Junio 2022" },
      { status: "AF", user: 52, movementDate: "14 de Junio 2022" },
      { status: "AA", user: 30, movementDate: "18 de Junio 2022" },
    ],
  },
  {
    datasheetSpecialist: 57,
    descriptionSpecialist: 57,
    imagesSpecialist: 55,
    datasheetCoord: 52,
    descriptionCoord: 52,
    imagesCoord: 53,
    qa: 30,
    OT: {
      productName: "Aspiradores Koblenz triple potencia",
      orderId: 237,
    },
    progressReport: [
      { status: "RECEPTION", user: 30, movementDate: "13 de Junio 2022" },
      { status: "IN_PROGRESS", user: 57, movementDate: "13 de Junio 2022" },
      { status: "QF", user: 57, movementDate: "14 de Junio 2022" },
      { status: "AF", user: 52, movementDate: "14 de Junio 2022" },
      { status: "AA", user: 30, movementDate: "18 de Junio 2022" },
      { status: "RP", user: 57, movementDate: "14 de Junio 2022" },
      { status: "QF", user: 52, movementDate: "14 de Junio 2022" },
      { status: "AF", user: 30, movementDate: "18 de Junio 2022" },
      { status: "AA", user: 52, movementDate: "14 de Junio 2022" },
      { status: "AP", user: 30, movementDate: "18 de Junio 2022" },
    ],
  },
  {
    datasheetSpecialist: 57,
    descriptionSpecialist: 57,
    imagesSpecialist: 55,
    datasheetCoord: 52,
    descriptionCoord: 52,
    imagesCoord: 53,
    qa: 30,
    OT: {
      productName: "Donitas bimbo 12 pz.",
      orderId: 237,
    },
    progressReport: [
      { status: "RECEPTION", user: 30, movementDate: "13 de Junio 2022" },
      { status: "IN_PROGRESS", user: 57, movementDate: "13 de Junio 2022" },
      { status: "QF", user: 57, movementDate: "14 de Junio 2022" },
      { status: "RF", user: 52, movementDate: "14 de Junio 2022" },
      { status: "QF", user: 30, movementDate: "18 de Junio 2022" },
      { status: "AF", user: 30, movementDate: "18 de Junio 2022" },
      { status: "RA", user: 30, movementDate: "18 de Junio 2022" },
    ],
  },
  {
    datasheetSpecialist: 57,
    descriptionSpecialist: 57,
    imagesSpecialist: 55,
    datasheetCoord: 52,
    descriptionCoord: 52,
    imagesCoord: 53,
    qa: 30,
    OT: {
      productName: "Radiador Repuesto",
      orderId: 237,
    },
    progressReport: [
      { status: "RECEPTION", user: 30, movementDate: "13 de Junio 2022" },
      { status: "IN_PROGRESS", user: 57, movementDate: "13 de Junio 2022" },
      { status: "QF", user: 57, movementDate: "14 de Junio 2022" },
      { status: "AF", user: 52, movementDate: "14 de Junio 2022" },
      { status: "AA", user: 30, movementDate: "18 de Junio 2022" },
    ],
  },
  {
    datasheetSpecialist: 57,
    descriptionSpecialist: 57,
    imagesSpecialist: 55,
    datasheetCoord: 52,
    descriptionCoord: 52,
    imagesCoord: 53,
    qa: 30,
    OT: {
      productName: "Aspiradores Koblenz triple potencia",
      orderId: 237,
    },
    progressReport: [
      { status: "RECEPTION", user: 30, movementDate: "13 de Junio 2022" },
      { status: "IN_PROGRESS", user: 57, movementDate: "13 de Junio 2022" },
      { status: "QF", user: 57, movementDate: "14 de Junio 2022" },
      { status: "AF", user: 52, movementDate: "14 de Junio 2022" },
      { status: "AA", user: 30, movementDate: "18 de Junio 2022" },
      { status: "RP", user: 57, movementDate: "14 de Junio 2022" },
      { status: "QF", user: 52, movementDate: "14 de Junio 2022" },
      { status: "AF", user: 30, movementDate: "18 de Junio 2022" },
      { status: "AA", user: 52, movementDate: "14 de Junio 2022" },
      { status: "AP", user: 30, movementDate: "18 de Junio 2022" },
    ],
  },
  {
    datasheetSpecialist: 57,
    descriptionSpecialist: 57,
    imagesSpecialist: 55,
    datasheetCoord: 52,
    descriptionCoord: 52,
    imagesCoord: 53,
    qa: 30,
    OT: {
      productName: "Donitas bimbo 12 pz.",
      orderId: 237,
    },
    progressReport: [
      { status: "RECEPTION", user: 30, movementDate: "13 de Junio 2022" },
      { status: "IN_PROGRESS", user: 57, movementDate: "13 de Junio 2022" },
      { status: "QF", user: 57, movementDate: "14 de Junio 2022" },
      { status: "RF", user: 52, movementDate: "14 de Junio 2022" },
      { status: "QF", user: 30, movementDate: "18 de Junio 2022" },
      { status: "AF", user: 30, movementDate: "18 de Junio 2022" },
      { status: "RA", user: 30, movementDate: "18 de Junio 2022" },
    ],
  },
];
