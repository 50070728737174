import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import {
  LongItem,
  Row,
  Button,
  ConfirmationRowButton,
  ConfirmationTitle,
  Terms,
} from "./styles";
const ConfirmAccount = (props) => {
  const [emptyCode, setEmptyCode] = useState(true);
  const [showErrors, setShowErrors] = useState(false);
  const [awsError, setAwsError] = useState("");
  const [resend, setResend] = useState(true);

  useEffect(() => {
    props.confirmationError !== "" && setEmptyCode(false);
  }, [props.confirmationError]);

  const checkCode = (e) => {
    let valid = true;
    e.preventDefault();
    let code = document.querySelector("#confirmationCodeInput").value;

    if (code.length > 0) {
      /* ( VALIDACIÓN SOLO NECESARIA PARA RESET PASSWORD PROCESS )
      Si el código de verificacion fue incorrecto, no dejar avanzar si el código es el mismo que el rechazado */
      if (sessionStorage.getItem("resetError")) {
        JSON.parse(sessionStorage.getItem("confirmationCode")) === code &&
          (valid = false);
      }
      sessionStorage.setItem("confirmationCode", JSON.stringify(code));
      setEmptyCode(false);
    } else {
      valid = false;
      setEmptyCode(true);
      setShowErrors(true);
    }
    /* Redirigir dependiendo de que proceso se esté realizando */
    valid &&
      !sessionStorage.getItem("resetPasswordProcess") &&
      props.setPaso(6);
    valid && sessionStorage.getItem("resetPasswordProcess") && props.setPaso(8);
  };
  const validateResend = async (e) => {
    e.preventDefault();
    const email = JSON.parse(sessionStorage.getItem("email"));
    try {
      await Auth.forgotPassword(email);
      sessionStorage.setItem("email", JSON.stringify(email));
      sessionStorage.setItem("resetPasswordProcess", JSON.stringify("true"));
      props.setPaso(5);
      setResend(true);
    } catch (err) {
      setAwsError(err.code);
      console.log(err.message);
    }
  };
  return (
    <>
      <ConfirmationTitle>
        {sessionStorage.getItem("email") && (
          <p>{`Ingrese código de verificación envíado a: ${JSON.parse(
            sessionStorage.getItem("email")
          )}`}</p>
        )}
        {!sessionStorage.getItem("email") && (
          <p>{`Ingrese código de verificación envíado a: ${
            JSON.parse(sessionStorage.getItem("nuevoRegistro")).email
          }`}</p>
        )}
      </ConfirmationTitle>
      <Row>
        <LongItem>
          <input
            id="confirmationCodeInput"
            type="text"
            defaultValue={
              sessionStorage.getItem("resetError")
                ? JSON.parse(sessionStorage.getItem("confirmationCode"))
                : ""
            }
          />
          {emptyCode && showErrors && <label>Este campo es requerido</label>}
          {sessionStorage.getItem("resetError") && (
            <label>Código de verifiación incorrecto</label>
          )}
          {props.confirmationError === "LimitExceededException" &&
            !emptyCode && (
              <label>
                Haz realizado demasiados intentos, intentalo más tarde
              </label>
            )}
          {props.confirmationError === "CodeMismatchException" &&
            !emptyCode && <label>Código de verificación incorrecto</label>}
          {props.confirmationError === "ExpiredCodeException" && !emptyCode && (
            <label>El código ingresado está expirado</label>
          )}
          {props.confirmationError === "InternalErrorException" &&
            !emptyCode && (
              <label>Algo salió mal, porfavor vuelva a intentarlo</label>
            )}
          {awsError === "LimitExceededException" && (
            <label>
              Haz realizado demasiados intentos, intentalo más tarde
            </label>
          )}
          {awsError === "InternalErrorException" && (
            <label>Algo salió mal, porfavor vuelva a intentarlo</label>
          )}
          {resend && awsError === "" && (
            <label className="resendTrue">
              Se reenvió el código de verificación correctamente
            </label>
          )}
        </LongItem>
      </Row>
      <Terms>
        <p onClick={(e) => validateResend(e)} className="verificationCode">
          Reenviar código de verificación
        </p>
      </Terms>
      {sessionStorage.getItem("resetPasswordProcess") && (
        <Row>
          <label onClick={() => props.setPaso(10)} className="changeEmail">
            Regresar...
          </label>
        </Row>
      )}
      <ConfirmationRowButton>
        <Button type="submit" onClick={(e) => checkCode(e)}>
          Envíar
        </Button>
      </ConfirmationRowButton>
    </>
  );
};

export default ConfirmAccount;
