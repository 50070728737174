import styled from "styled-components";

export const MainContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .imgFallback {
    width: ${(props) => (props.width ? `${props.width}%` : "20px")};
    height: ${(props) => (props.height ? `${props.height}%` : "20px")};
  }
`;

export const OptionContainer = styled.div`
  box-sizing: border-box;
  height: auto;
  width: 210px;
  position: absolute;
  z-index: 30;
  top: -6px;
  right: 70%;
  background-color: #f0eef2;
  display: inline-flex;
  border-radius: 3px;
  //height: ${(props) => (props.show ? "50px" : "0px")};
  transition: height 0.15s ease-in;
  border: ${(props) => (props.show ? "1px solid black" : "none")};
`;

export const Option = styled.div`
  box-sizing: border-box;
  padding: 3px 6px;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 3px;
  overflow-y: auto;
  padding: 2px 5px;
  height: 30px;
  :hover {
    background-color: #e5e5ea;
  }
  img {
    width: 20px;
  }
`;

export const OptionTextContainer = styled.div`
  box-sizing: border-box;
  margin-left: 5%;
  display: inline;
`;
